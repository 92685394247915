import UserInformationDashboard from "app/components/userInformation/userInformationDashboard";
import HabacusIconMobile from "app/icons/Logo_Habacus_quadricromia_icona.png";
import HabacusIcon from "app/icons/Logo_new.svg";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import AllStudents from "app/components/allStudents";
import ThankYou from "app/components/common/thankYou";
import "app/styles";
import React, { useEffect } from "react";
import { FormattedMessage, IntlProvider } from 'react-intl';
// import UserInformation from "app/components/userInformation/userInformation";
import StepperHeader from "app/components/common/stepperHeader";
import CookieConsent from "react-cookie-consent";
import { LANGUAGE } from "./context/studentInfoProvider";
import { getQueryParam } from "./utils/utils";
import "/node_modules/flag-icons/css/flag-icons.min.css";


// Translated messages in Italian with matching IDs to what you declared
const messagesInItalian = {

  //stud-taxCodeInput GENERAL
  "studentTaxCodeInput.title": "Richiesta di Certificazione",
  "studentTaxCodeInput.subtitle": "Modifica la tua RICHIESTA di CERTIFICAZIONE HABACUS",
  "studentTaxCodeInput.lable": "Codice Fiscale",
  "studentTaxCodeInput.text1": "Bentornato nel processo di Certificazione Habacus dove potrai aggiornare  e modificare i tuoi dati e caricare i documenti necessari per il processo di certificazione per il tuo finanziamento.",
  "studentTaxCodeInput.text2": "Una compilazione attenta e completa permetterà di velocizzare i tempi!",
  "studentTaxCodeInput.text3": "Inserisci il tuo codice fiscale per continuare.",

  //stud-taxCodeInput POSTE
  "studentTaxCodeInput.poste.title": "Richiesta di Certificazione",
  "studentTaxCodeInput.poste.subtitle": "Completa la CERTIFICAZIONE HABACUS per Promo Studi Prestito BP",
  "studentTaxCodeInput.poste.lable": "Codice Fiscale",
  "studentTaxCodeInput.poste.text1": "Bentornato! Questo è l'ultimo step per completare la richiesta di certificazione, dove dovrai caricare le informazioni relative al tuo corso di studi e caricare i documenti accademici indicati.",
  "studentTaxCodeInput.poste.text2": "Una volta compilata questa sezione la tua domanda di certificazione verrà presa in carico.",
  "studentTaxCodeInput.poste.text3": "Inserisci il tuo codice fiscale per continuare.",

  //stud-taxCodeInput ZOPPAS
  "studentTaxCodeInput.zoppas.title": "Richiesta di Certificazione",
  "studentTaxCodeInput.zoppas.subtitle": "Completa la CERTIFICAZIONE HABACUS per il bando ZOPPAS INDUSTRIES",
  "studentTaxCodeInput.zoppas.lable": "Codice Fiscale",
  "studentTaxCodeInput.zoppas.text1": "Bentornato! Questo è l'ultimo step per completare la richiesta di certificazione, dove dovrai caricare le informazioni relative al tuo corso di studi e caricare i documenti accademici indicati.",
  "studentTaxCodeInput.zoppas.text2": "Una volta compilata questa sezione la tua domanda di certificazione verrà presa in carico.",
  "studentTaxCodeInput.zoppas.text3": "Inserisci il tuo codice fiscale per continuare.",

  //stud-taxCodeInput CARIPLO
  "studentTaxCodeInput.cariplo.title": "Richiesta di Certificazione",
  "studentTaxCodeInput.cariplo.subtitle": "Completa la CERTIFICAZIONE HABACUS per il bando FONDAZIONE CARIPLO",
  "studentTaxCodeInput.cariplo.lable": "Codice Fiscale",
  "studentTaxCodeInput.cariplo.text1": "Bentornato! Questo è l'ultimo step per completare la richiesta di certificazione, dove dovrai caricare le informazioni relative al tuo corso di studi e caricare i documenti accademici indicati.",
  "studentTaxCodeInput.cariplo.text2": "Una volta compilata questa sezione la tua domanda di certificazione verrà presa in carico.",
  "studentTaxCodeInput.cariplo.text3": "Inserisci il tuo codice fiscale per continuare.",

  //stud-taxCodeInput Ambassador
  "studentTaxCodeInput.ambassador.title": "Richiesta di Certificazione",
  "studentTaxCodeInput.ambassador.subtitle": "Modifica la tua RICHIESTA di CERTIFICAZIONE HABACUS fatta tramite il codice Ambassador",
  "studentTaxCodeInput.ambassador.lable": "Codice Fiscale",
  "studentTaxCodeInput.ambassador.text1": "Bentornato nel processo di Certificazione Habacus dove potrai aggiornare  e modificare i tuoi dati e caricare i documenti necessari per il processo di certificazione per il tuo finanziamento.",
  "studentTaxCodeInput.ambassador.text2": "Una compilazione attenta e completa permetterà di velocizzare i tempi!",
  "studentTaxCodeInput.ambassador.text3": "Inserisci il tuo codice fiscale per continuare.",

  //studentDisclaimer GENERAL
  "studentDisclaimer.title": "Richiesta di Certificazione",
  "studentDisclaimer.subtitle": "Richiedi la CERTIFICAZIONE HABACUS, parti da qui!",
  "studentDisclaimer.text1": "Richiedere la certificazione Habacus è semplice e veloce, ci vogliono solo 10 minuti.",
  "studentDisclaimer.text2": "Compila il form inserendo le tue informazioni angrafiche e accademiche, relative al corso di studi che stai frequentando. ",
  "studentDisclaimer.text3": "Ti guideremo passo dopo passo per facilitare il tuo accesso ad un prestito studentesco sostenibile.",
  "studentDisclaimer.text4": "Hai già una pratica aperta con Habacus?",
  "studentDisclaimer.text5": "Non compilarne una nuova, contattaci a ",
  "studentDisclaimer.email": "info@habacus.com",

  //studentDisclaimer AMBASSADOR
  "studentDisclaimer.ambassador.title": "Richiesta di Certificazione",
  "studentDisclaimer.ambassador.subtitle": "Richiedi la CERTIFICAZIONE HABACUS tramite il codice AMBASSADOR, parti da qui!",
  "studentDisclaimer.ambassador.text1": "Richiedere la certificazione Habacus è semplice e veloce, ci vogliono solo 10 minuti.",
  "studentDisclaimer.ambassador.text2": "Compila il form inserendo le tue informazioni angrafiche e accademiche, relative al corso di studi che stai frequentando.",
  "studentDisclaimer.ambassador.text3": "Ti guideremo passo dopo passo per facilitare il tuo accesso ad un prestito studentesco sostenibile.",
  "studentDisclaimer.ambassador.text4": "Hai già una pratica aperta con Habacus?",
  "studentDisclaimer.ambassador.text5": "Non compilarne una nuova, contattaci a ",
  "studentDisclaimer.ambassador.email": "info@habacus.com",

  //studentDisclaimer POSTE
  "studentDisclaimer.poste.title": "Richiesta di Certificazione",
  "studentDisclaimer.poste.subtitle": "Richiedi la CERTIFICAZIONE HABACUS per Promo Studi Prestito BP, parti da qui!",
  "studentDisclaimer.poste.text1": "Richiedere la certificazione Habacus per Poste Italiane è semplice e veloce, ci vogliono solo 10 minuti. ",
  "studentDisclaimer.poste.text2": "Scegli se fare il test Motiva, compila il form inserendo le tue informazioni angrafiche e carica i documenti personali che ti vengono richiesti.",
  "studentDisclaimer.poste.text3": "Ti guideremo passo dopo passo per facilitare il tuo accesso al Prestito BancoPosta.",
  "studentDisclaimer.poste.text4": "Hai già una pratica aperta con Habacus?",
  "studentDisclaimer.poste.text5": "Per qualsiasi problema durante il processo scrivi una mail a ",
  "studentDisclaimer.poste.email": "promostudiprestitobp@habacus.com",


  //studentDisclaimer ZOPPAS
  "studentDisclaimer.zoppas.title": "Richiesta di Certificazione",
  "studentDisclaimer.zoppas.subtitle": "Richiedi la CERTIFICAZIONE HABACUS per il bando ZOPPAS INDUSTRIES, parti da qui!",
  "studentDisclaimer.zoppas.text1": "Richiedere la certificazione Habacus per il bando ZOPPAS INDUSTRIES è semplice e veloce, ci vogliono solo 10 minuti.",
  "studentDisclaimer.zoppas.text2": "Compila il form inserendo le tue informazioni angrafiche e carica i documenti personali che ti vengono richiesti.",
  "studentDisclaimer.zoppas.text3": "Ti guideremo passo dopo passo per facilitare il tuo accesso al bando ZOPPAS INDUSTRIES.",
  "studentDisclaimer.zoppas.text4": "Hai già una pratica aperta con Habacus?",
  "studentDisclaimer.zoppas.text5": "Non compilarne una nuova, contattaci a ",
  "studentDisclaimer.zoppas.email": "info@habacus.com",

  //studentDisclaimer CARIPLO
  "studentDisclaimer.cariplo.title": "Richiesta di Certificazione",
  "studentDisclaimer.cariplo.subtitle": "Richiedi la CERTIFICAZIONE HABACUS per accedere alla Borsa di Studio sostenuta da Fondazione Cariplo",
  "studentDisclaimer.cariplo.text1": "Richiedere la certificazione Habacus per accedere alla Borsa di Studio è semplice e veloce, ci vogliono solo 10 minuti.",
  "studentDisclaimer.cariplo.text2": "Compila il form inserendo le tue informazioni anagrafiche e carica i documenti personali che ti vengono richiesti.",
  "studentDisclaimer.cariplo.text3": "Ti guideremo passo dopo passo per facilitare il tuo accesso al Bando del Concorso per l’ottenimento della Borsa.",
  "studentDisclaimer.cariplo.text4": "Hai già una pratica aperta con Habacus?",
  "studentDisclaimer.cariplo.text5": "Non compilarne una nuova, contattaci a: ",
  "studentDisclaimer.cariplo.email": "progetti@fondazionehabacus.com",


  //altro da controllare
  "privacy.notifications": "Consenso per la Privacy",
  "noUserInfo": "Non sono state trovate informazioni per lo studente!",
  "allStudents.title": "Seleziona uno studente",
  "allStudents.select.lable": "Studente",









  //----userInformation.tsx----
  "horizontalStepper.step1": "TEST DI ORIENTAMENTO",//inattivo
  "horizontalStepper.step2": "DATI ANAGRAFICI",
  "horizontalStepper.step2b": "DATI ACCADEMICI",

  "horizontalStepper.step3": "UPLOAD DOCUMENTI",
  "horizontalStepper.step4": "RIEPILOGO DATI",
  "horizontalStepper.step5": "ESITO RICEVUTO",//inattivo
  //------------

  "horizontalStepper.subtitle.step1": "Esegui il test Motiva",
  "horizontalStepper.subtitle.step2": "Inserimento dati",
  "horizontalStepper.subtitle.step3": "Upload documenti",
  //common
  "input.select.placeholder": "Seleziona un'opzione",

  "button.verifyAmbassador": "Verifica Codice Ambassador",
  "button.continue": "CONTINUA",
  "button.cancel": "ANNULLA",
  "button.save&continue": "Salva & Continua",
  "button.finish": "Finito!",
  "button.saveAndGoOn1": "SALVA",
  "button.saveAndGoOn2": "E CONTINUA",
  "button.goOn": "CONTINUA",
  "button.yesTest": "FAI IL TEST",
  "button.noTest": "SALTA IL TEST",


  "indietro": "INDIETRO",
  //---
  //step1-----
  //orientation page for bpi
  "orientation.bpi.title": "Test di Orientamento Motiva",
  "orientation.bpi.text1a": "Il test di orientamento Motiva non è obbligatorio per ottenere la certificazione.",
  "orientation.bpi.text1b": "Puoi scegliere se fare il test adesso o in secondo momento. Ricorda che una volta iniziato, devi necessariamente portarlo a termine prima della richiesta di certificazione. Se decidi di saltare il test, riceverai una mail per farlo quando sei più comodo entro 30 giorni.",
  "orientation.bpi.text1c": "Se decidi di farlo ADESSO, dovrai obbligatoriamente portarlo a termine per poter effettuare la certificazione.",
  "orientation.bpi.confirmNoTestModal.title": "Salta il test e prosegui",
  "orientation.bpi.confirmNoTestModal.text": "Riceverai una mail con il link per effettuare il Test",
  "orientation.bpi.confirmYesTestModal.title": "Inizia il Test",
  "orientation.bpi.confirmYesTestModal.text1": "Il Test si aprirà in un'altra scheda",
  "orientation.bpi.confirmYesTestModal.text2": "Ricorda che una volta iniziato, il test deve essere necessariamente concluso prima di effettuare la richiesta di certificazione. Se hai problemi di accesso al test inviaci una mail a promostudiprestibp@habacus.com",
  "orientation.bpi.confirmYesTestModal.text3": "Torna su questa pagina per confermare la fine del test e proseguire con il processo, oppure per recuperare il link al test.",
  "orientation.bpi.testVisible.text1": "Se vuoi riaprire la pagina del test clicca su: Link Test Motiva",
  "orientation.bpi.testVisible.text2": "Se invece hai terminato il Test, clicca su Continua e prosegui con il processo di certificazione",
  "orientation.bpi.testVisible.link": "Link Test Motiva",
  "orientation.bpi.confirmTestDone.title": "Test Motiva Concluso",
  "orientation.bpi.confirmTestDone.text": "Conferma di aver concluso il test e prosegui con il processo di certificazione",


  //step2------
  //info1 - DATI ANAGRAFICI
  "personalDataPage.ambassadorNotFound": "Referral Ambassador errato. Se sei in possesso di un Codice Ambassador inseriscilo e continua.",
  "personalDataPage.ambassadorDisclaimer": "Se sei in possesso di un Codice Ambassador inseriscilo e continua.",
  "personalDataPage.title.create": "Inserisci i tuoi dati anagrafici",
  "personalDataPage.subtitle1.create": "Inserisci qui sotto i tuoi dati anagrafici e leggi l'informativa per il trattamento dei dati personali.",
  "personalDataPage.subtitle2.create": "Una volta compilata questa sezione clicca su 'Salva e Continua' per passare alla pagina successiva.",
  "personalDataPage.title.update": "Modifica i tuoi dati anagrafici",
  "personalDataPage.subtitle1.update": "Modifica qui sotto i tuoi dati anagrafici e leggi l'informativa per il trattamento dei dati personali.",
  "personalDataPage.subtitle2.update": "Una volta compilata questa sezione clicca su 'Salva e Continua' per passare alla pagina successiva.",



  "personalDataPage.ambassador1": "Sei stato invitato da ",
  "personalDataPage.ambassador2": "per questa pratica sarà il tuo contatto con Habacus.",
  "personalDataPage.ambassador3": "Ambassador non trovato.",
  "personalDataPage.ambassador4": "Benvenuto nel processo di selezione studenti habacus",


  "personalDataPage.block1.title": "Informazioni anagrafiche",
  "personalDataPage.block1.name": "Nome",
  "personalDataPage.block1.surname": "Cognome",
  "personalDataPage.block1.gender": "Genere",

  "personalDataPage.block1.genderOptions.male": "Uomo",
  "personalDataPage.block1.genderOptions.female": "Donna",
  "personalDataPage.block1.genderOptions.other": "Altro",


  "personalDataPage.block1.date": "Data di nascita",
  "personalDataPage.block1.born_city": "Città di nascita",
  "personalDataPage.block1.born_province": "Provincia di nascita",
  "personalDataPage.block1.born_state": "Regione di nascita",
  "personalDataPage.block1.born_country": "Nazione di nascita",
  "personalDataPage.block1.citizenships": "Elenco Cittadinanze",
  // "personalDataPage.block1.citizenship_ue": "Cittadinanza UE",
  "personalDataPage.block1.italian_residence": "Residenza in Italia o iscritto all'AIRE",
  "personalDataPage.block1.tax_code": "Codice fiscale",

  "personalDataPage.block2.title": "Residenza",
  "personalDataPage.block2.citizen_country": "Stato",
  "personalDataPage.block2.citizen_province": "Provincia",
  "personalDataPage.block2.citizen_city": "Città",
  "personalDataPage.block2.citizen_zip": "CAP",
  "personalDataPage.block2.citizen_address": "Indirizzo di residenza",
  "personalDataPage.block2.citizen_address_number": "Numero Civico di residenza",
  "personalDataPage.block2.phone_country_code": "Codice telefonico del paese",
  "personalDataPage.block2.phone": "Numero telefono",
  "personalDataPage.block2.email": "E-mail",
  "personalDataPage.block2.confirm_email": "Conferma E-mail",
  "personalDataPage.block2.checkboxDomicile": "Domicilio se diverso dalla Residenza",

  "personalDataPage.block3.domicile_country": "Stato",
  "personalDataPage.block3.domicile_province": "Provincia",
  "personalDataPage.block3.domicile_city": "Città",
  "personalDataPage.block3.domicile_zip": "CAP",
  "personalDataPage.block3.domicile_address": "Indirizzo di domicilio",
  "personalDataPage.block3.domicile_address_number": "Numero civico di domicilio",

  //opzioni nei dati anagrafici
  "personalDataPage.option.ue": "UE",
  "personalDataPage.option.xue": "Extra UE",
  "personalDataPage.option.no": "No",
  "personalDataPage.option.yes": "Si",
  "personalDataPage.option.male": "Uomo",
  "personalDataPage.option.female": "Donna",
  "personalDataPage.option.other": "Preferisco non dichiararlo",

  "personalDataPage.error_message.1": "Impossibile proseguire. Codice Fiscale o Email già in uso.",
  "personalDataPage.error_message.2": "Contattaci per poter continuare.",
  "personalDataPage.error_message.3": "Ci risulta che questo codice fiscale ed email siano in uso per un altro studente.",
  "personalDataPage.error_message.4": "Hai già una pratica in lavorazione quindi non puoi iniziarne altre.",


  "personalDataPage.error_required": "Necessario",

  //--------fine info1------
  //inizio info2
  "academicDataPage.block1.title": "Inserisci le tue informazioni accademiche",
  "academicDataPage.degree_class_id": "Classi di Laurea",
  "academicDataPage.course_type": "Tipologia di Corso di Studi",
  "academicDataPage.school_course_id": "Corso di Studi",
  "academicDataPage.school_id": "Scuola, Istituto, Ente o Università",
  "academicDataPage.number": "Matricola",

  "academicDataPage.text": "Inserisci in questa pagina le informazioni relative al corso di studi che stai frequentando e per cui richiedi la certificazione.",

  "academicDataPage.text2": "Clicca poi 'Salva e Continua' per accedere al prossimo step.",


  //fine info2

  //ALERT MODAL
  "alertModal.1": "ATTENZIONE",
  "alertModal.2a": "Il ",
  "alertModal.2b": "Codice Fiscale",
  "alertModal.2c": " è corretto",
  "alertModal.3a": "L",
  "alertModal.3a2": "'",
  "alertModal.3b": "età",
  "alertModal.3c": " è corretta",


  //FINE ALERT MODAL

  //ALERT AR MODAL
  "alertModalAR.title": "BENTORNATO",
  "alertModalAR.text1": "I tuoi dati anagrafici erano già stati salvati in precedenza nel nostro Database.",
  "alertModalAR.text2": "Se vuoi modificarli inviaci una email a ",
  "alertModalAR.text3": "Continua con il processo di onboarding per creare una nuova pratica.",


  //FINE ALERT AR MODAL



  //inizio info3
  //r17 privacy options da fare, prestare o non prestare
  // set errpor intorno a r 40 anche da fare
  "step2.info3.privacy.title": "Privacy",
  "step2.info3.privacy.title1": "1. Marketing di terze parti:",
  "step2.info3.privacy.title2": "2. Realizzazione e diffusione di materiale promozionale:",
  "step2.info3.privacy.text1": "Con riferimento al trattamento dei Miei dati personali da parte di Habacus S.r.l. per le finalità di trattamento descritte all'art. 4 dell'Informativa Privacy sub Allegato A, relative a fini di marketing, informazione commerciale, offerte dirette, indagini di mercato o di customer satisfaction relative a prodotti e servizi offerti dalle imprese ed enti promotori con cui HABACUS S.r.l. collabora.",
  "step2.info3.privacy.text3": "Inoltre, con riferimento al trattamento dei Miei dati personali da parte di Habacus S.r.l. per le finalità di trattamento descritte all'art. 4, punto c) dell'informativa sub Allegato A, relative all'utilizzo, alla riproduzione e alla pubblicazione, unitamente al Mio nome e cognome, di informazioni personali a Me riferite (incusi fotografie e/o video che mi ritraggono, informazioni in relazione ai servizi di mentorship ricevuti nonché estremi dei Miei account social), nel contesto dello svolgimento di iniziative di comunicazione, anche a carattere informativo - promozionale, delle attività di HABACUS e della mia partecipazione a fiere, open-day e altri eventi promozionali.",
  "step2.info3.checkbox.lable1": "Confermo di aver preso visione dell'",
  "step2.info3.checkbox.lable2": "Informativa sulla privacy",
  "step2.info3.checkbox.lable3": " sul trattamento dei dati personali e dei contatti per la revoca dei consensi.",

  "step2.info3.radio.yes": "Presto il consenso",
  "step2.info3.radio.no": "Nego il consenso",
  "step2.info3.radio.error": "Si prega di scegliere sì / no per la politica di privacy",

  "step2.info3.attachedFile": "Scarica Allegato A",


  //fine info 3
  //FINE step2

  //INIZIO----step3
  //up1
  "step3.up1.title": "Hai un codice ambassador?",
  "step3.up1.text1": "Complimenti!",
  "step3.up1.text2": "La registrazione dei dati è andata a buon fine.",
  "step3.up1.text3": "Se sei stato invitato da ",
  "step3.up1.text4": ", uno dei nostri Ambassador Habacus, inserisci il codice che ti è stato indicato. Altrimenti procedi tranquillamente.",

  "ambassadorPage.empty.title": "Hai un codice ambassador?",
  "ambassadorPage.empty.text1": "Se un Ambassador Habacus ti ha fornito un codice, inseriscilo qui sotto.",
  "ambassadorPage.empty.text2": "In caso contrario lascia il campo vuoto e prosegui con la compilazione.",
  "ambassadorPage.filled.title": "Controlla il codice ambassador",
  "ambassadorPage.filled.text1": "Se sei stato invitato da ",
  "ambassadorPage.filled.text2": ", uno dei nostri Ambassador Habacus, controlla che il codice inserito sia quello che ti è stato indicato, altrimenti modificalo o seleziona -no- e continua con il processo di onboarding.",


  // "step3.up1.text1": "Complimenti ",
  // "step3.up1.text2": " la registrazione dei dati è andata a buon fine. Prima di procedere con il download della delega, se sei stato invitato da uno dei nostri Ambassador Habacus, inserisci il codice che ti è stato indicato. Altrimenti procedi tranquillamente seguendo le indicazioni per il caricamento della Delega.",

  "step3.up1.inputLable": "Codice ambassador",
  "step3.up1.yes": "SI",
  "step3.up1.no": "NO",
  //fine up1
  //inizio up2
  "uploadProxy.title": "Scarica, Firma e Carica la Delega",

  "uploadProxy.text1": "Qui sotto trovi la delega compilata con i dati che hai inserito fin qui.",
  "uploadProxy.text2": "Scaricala e compila i campi contrassegnati (data, luogo e firma autografa) e poi procedi con l'upload.",
  "uploadProxy.text3": "Ci sono delle informazioni errate all'interno della delega? Non preoccuparti, puoi tornare agli step precedenti e correggere i campi.",
  "uploadProxy.privacy1": "Il trattamento dei tuoi dati personali relativi alla delega avverrà secondo quanto indicato nell’",
  "uploadProxy.privacy2": "informativa privacy",

  "uploadProxy.download": "Download",
  "uploadDefaultProxy.download": "Problemi con la delega compilata? Clicca qui per scaricarne una da compilare.",

  //fine up2
  //inizio up3
  "step3.up3.title": "Carica i tuoi Documenti Personali",

  "uploadPersonalDocuments.text1": "Per completare la tua richiesta di certificazione, devi caricare:",
  "uploadPersonalDocuments.text2": "- la tua carta d'identità",
  "uploadPersonalDocuments.text3": "- la tua tessera sanitaria.",
  "uploadPersonalDocuments.text4": "Assicurati che i documenti siano fronte / retro, leggibili e non scaduti.", "uploadPersonalDocuments.text5": "Cerca di evitare sfondi / flash / dita...",
  "uploadPersonalDocuments.text6": "N.B: Se non hai la carta di identità condividici il certificato di residenza; se non hai la tessera sanitaria puoi richiederne una copia provvisoria online.",


  "CI.title": "Per i possessori della carta d'identità:",
  "CI.ci": "Documento 1 - caricamento fronte/retro della carta d'identità",
  "CI.ts": "Documento 2 - caricamento fronte/retro della tessera sanitaria",
  "CI.del": "Documento 3 - caricamento delega",
  "PSP.title": "Per i possessori del passaporto:",
  "PSP.psp": "Documento 1 - caricamento fronte/retro del passaporto",
  "PSP.res": "Documento 2 - caricamento certificato residenza",
  "PSP.ts": "Documento 3 - caricamento fronte/retro della tessera sanitaria",
  "PSP.del": "Documento 4 - caricamento delega",

  "NewConsolidatedDocumentUploadPath.title": "I documenti necessari per il perfezionamento dell’iscrizione sono:",
  "NewConsolidatedDocumentUploadPath.1": "Carta d’Identità, di cui è necessaria la scansione fronte/retro",
  "NewConsolidatedDocumentUploadPath.2": "Tessera Sanitaria, di cui è necessaria la scansione fronte/retro",
  "NewConsolidatedDocumentUploadPath.3": "Delega",
  "NewConsolidatedDocumentUploadPath.end": "Chi non è in possesso della Carta d'Identità, deve caricare la scansione fronte/retro del Passaporto e del Permesso di Soggiorno.",


  "uploadDocuments.ID": "Carta d'Identità",
  "uploadDocuments.SC": "Tessera Sanitaria",
  "uploadDocuments.DE": "Delega",
  "uploadDocuments.concern_payments": "Ricevuta di Pagamento Iscrizione",
  "uploadDocuments.learning_agreement": "Contratto Erasmus",
  "uploadDocuments.final_grade": "Certificato di Diploma/Laurea con Voto",
  "uploadDocuments.attended_frequency": "Certificato di Presenza",
  "uploadDocuments.at_least_n_credits": "Libretto Universitario",
  "uploadDocuments.studies_plan": "Piano di Studi",
  "uploadDocuments.singleFile": "File Unico",
  "uploadDocuments.singleFileFrontBack": "File Unico - FRONTE/RETRO",
  "uploadDocuments.singleFileAllPages": "File Unico - TUTTE LE PAGINE",
  "uploadDocuments.multipleFiles": "File Multipli",
  "uploadDocuments.front": "Fronte",
  "uploadDocuments.back": "Retro",
  "uploadDocuments.errorMex": "Errori trovati nel documento caricato precedentemente",
  "uploadDocuments.errorMexGeneric": "Sono stati trovati errori nel documento caricato precedentemente. Ricaricalo.",


  "upload.doc.x": "Upload Documento ",
  "remove.doc": "ELIMINA X",
  "add.doc.label": "Nome documento",//non va
  "add.doc.placeholder": "Seleziona documento da caricare",//non va
  "add.doc": "AGGIUNGI DOCUMENTO +",
  "added.docs": "Documenti già caricati",
  "uploaded.docs": "Documenti in caricamento",


  "alertProxyModal.1": "Sei sicuro di non caricare la Delega?",
  "alertProxyModal.2": "Continuando senza caricare la delega la tua pratica non potrà essere processata. Ricordati di caricarla successivamente.",



  "accessReq.title": "Carica i tuoi documenti accademici",
  "accessReq.text1": "In questa pagina puoi caricare i tuoi documenti accademici.",
  "accessReq.text2": "Seleziona il semestre accademico che stai frequentanto, ti indicheremo quali documenti devi caricare in base all'ente e al corso che hai già indicato.",
  "accessReq.text3": "Non hai a disposizione i documenti accademici? Puoi proseguire comunque con la richiesta, saremmo noi a chiederli al tuo ente formativo.",
  "accessReq.text4": "Considera che solitamente gli enti Italiani rispondono in 15/20 giorni, gli enti esteri in 30/40 giorni.",

  "accessReq.select": "Anno e Semestre",

  "accessReq.modal.title": "Non hai caricato tutti i documenti!",
  "accessReq.modal.text": "Puoi tornare a caricare i tuoi documenti o aspettare che ce li invii l'ente accademico per il quale stai richiedendo la certificazione.",
  //fine up3

  //fine step3
  //inizio step 4

  "recap.title": "Hai quasi finito!",
  "recap.text1": "Ecco un riepilogo di tutti i dati che hai inserito negli step precedenti.",
  "recap.text2": "Qualcosa non ti torna? Clicca su uno degli step precedenti nella barra sopra e correggi i tuoi dati.",
  "recap.text3": "Dovrai anche ri-scaricare e ri-firmare la delega, che si sarà aggiornata con i nuovi dati.",
  "recap.text4": "Se è tutto ok, non ti resta che cliccare 'Finito!'",




  "step4.title1": "INFORMAZIONI PERSONALI",
  "step4.title2": "INFORMAZIONI ACCADEMICHE",
  "step4.title3": "DOCUMENTI CARICATI",
  "step4.success": "Salvato con successo...",

  ///Users/cavicchioli/Documents/habacus_students_editor/src/app/utils/documents.ts
  //documents names ^
  //fine step 4

  //dashboard
  "dashboard.newPractice": "CREA NUOVA PRATICA",
  "dashboard.startButton": "INIZIA",
  //---------









  //RECAP FINALE    ----------     INIZIO

  "recap.name": "Nome",
  "recap.surname": "Cognome",
  "recap.gender": "Genere",
  "recap.born_at": "Data di nascita (gg-mm-aaaa)",
  "recap.born_city": "Città di nascita",
  "recap.born_province": "Provicia di nascita",
  "recap.born_country": "Nazione di nascita",
  // "recap.citizenship_ue": "Cittadinanza UE o X-UE",
  "recap.citizenships": "Lista Cittadinanze",
  "recap.tax_code": "Codice fiscale",
  "recap.italian_residence": "Residente in Italia o iscritto all'AIRE",
  "recap.citizen_country": "Stato di residenza",
  "recap.citizen_province": "Provincia di residenza",
  "recap.citizen_city": "Città di residenza",
  "recap.citizen_zip": "CAP di residenza",
  "recap.citizen_address": "Indirizzo residenza",
  "recap.citizen_address_number": "N° civico di residenza",
  "recap.phone_country_code": "Prefisso telefonico (Esempio per Italia: +39)",
  "recap.phone": "Telefono/Cellulare",
  "recap.email": "E-mail",
  "recap.domicile_country": "Stato di domicilio",
  "recap.domicile_province": "Provincia di domicilio",
  "recap.domicile_city": "Città di domicilio",
  "recap.domicile_zip": "CAP di domicilio",
  "recap.domicile_address": "Indirizzo di domicilio",
  "recap.domicile_address_number": "N° civico di domicilio",
  "recap.course_type": "Tipo Corso di studi",
  "recap.school_id": "Ente Formativo",
  "recap.degree_class_id": "Corso di Laurea",
  "recap.school_course_id": "Corso di Studi",
  "recap.number": "Matricola",
  "recap.invited_by_student_ambassador_code": "Codice dell'Ambassador",
  "recap.privacy_notifications": "Accettazione Privacy",

  "recap.notInvited": "Nessun invito",



  //RECAP FINALE    ----------     FINE


  //THANK YOU
  "thankYou.1": "Hai concluso la tua richiesta di certificazione!",
  "thankYou.2": "Ora la palla passa a noi.",
  "thankYou.3": "Abbiamo ricevuto i tuoi documenti, faremo un check delle informazioni che hai inserito.",
  "thankYou.4": "Contatteremo poi il tuo ente formativo per poter verificare che tu sia al passo con il tuo piano di studi.",
  "thankYou.5": "Ci sentirai presto, ti aggiorneremo via email sullo status della tua richiesta.",
  "thankYou.6": "Hai qualche dubbio? ",
  "thankYou.7": "Visita le nostre ",
  "thankYou.8": " o scrivici a ",
  "select.loading": "Caricamento",

  "banner.gdpr": "Questo sito o gli strumenti di terze parti in esso integrati trattano dati personali (es. dati di navigazione o indirizzi IP) e fanno uso di cookie o altri identificatori necessari per il funzionamento e per il raggiungimento delle finalità descritte nella cookie policy. Dichiari di accettare l’utilizzo di cookie o altri identificatori chiudendo o nascondendo questa informativa, proseguendo la navigazione di questa pagina, cliccando un link o un pulsante o continuando a navigare in altro modo.",
  "banner.accept": "Accetta",
  "banner.discover": "Scopri di più",
}

const messagesInEnglish = {

  "privacy.notifications": "privacy.notifications",
  "noUserInfo": "No user info found for the student!",

  //all-students 
  "allStudents.title": "Select a student",
  "allStudents.select.lable": "Student",

  //stud-taxCodeInput general
  "studentTaxCodeInput.title": "Certification Request",
  "studentTaxCodeInput.subtitle": "Change your Habacus Certification Request here",
  "studentTaxCodeInput.lable": "Tax Code",
  "studentTaxCodeInput.text1": "Welcome to the Habacus Certification process, where you can update and change your data and upload the documents necessary for the certification process for your loan.",
  "studentTaxCodeInput.text2": "A careful and complete compilation will speed up the process!",
  "studentTaxCodeInput.text3": "Enter your tax code to continue.",

  //stud-taxCodeInput poste
  "studentTaxCodeInput.poste.title": "Habacus Certification",
  "studentTaxCodeInput.poste.subtitle": "Complete Habacus Certification for Promo Studi Prestito BP",
  "studentTaxCodeInput.poste.lable": "Tax Code",
  "studentTaxCodeInput.poste.text1": "Welcome back! This is the last section to complete your certification request, were you can update your academic information and upload the necessary documents.",
  "studentTaxCodeInput.poste.text2": "Once you have completed this section we will process your request.",
  "studentTaxCodeInput.poste.text3": "Enter your tax code to continue.",

  //stud-taxCodeInput Ambassador
  "studentTaxCodeInput.ambassador.title": "Certification Request",
  "studentTaxCodeInput.ambassador.subtitle": "Change your Habacus Certification Request made through the Ambassador code",
  "studentTaxCodeInput.ambassador.lable": "Tax Code",
  "studentTaxCodeInput.ambassador.text1": "Welcome to the Habacus Certification process, where you can update and change your data and upload the documents necessary for the certification process for your loan.",
  "studentTaxCodeInput.ambassador.text2": "A careful and complete compilation will speed up the process!",
  "studentTaxCodeInput.ambassador.text3": "Enter your tax code to continue.",

  //studentDisclaimer GENERAL
  "studentDisclaimer.title": "Habacus Certification",
  "studentDisclaimer.subtitle": "Habacus Certification, start your application here!",
  "studentDisclaimer.text1": "Applying is quick and easy, it takes just 10 minutes.",
  "studentDisclaimer.text2": "Insert your personal and academic information in the following form.",
  "studentDisclaimer.text3": "We will guide you during your application and make your access to a sustainable student loan a lot easier.",
  "studentDisclaimer.text4": "Do you already have a pending practice with Habacus?",
  "studentDisclaimer.text5": "Do not start a new form, contact us at ",
  "studentDisclaimer.email": "info@habacus.com",


  //studentDisclaimer AMBASSADOR
  "studentDisclaimer.ambassador.title": "Habacus Certification",
  "studentDisclaimer.ambassador.subtitle": "Habacus Certification through Ambassador code, start your application here!",
  "studentDisclaimer.ambassador.text1": "Applying is quick and easy, it takes just 10 minutes.",
  "studentDisclaimer.ambassador.text2": "Insert your personal and academic information in the following form.",
  "studentDisclaimer.ambassador.text3": "We will guide you during your application and make your access to a sustainable student loan a lot easier.",
  "studentDisclaimer.ambassador.text4": "Do you already have a pending practice with Habacus?",
  "studentDisclaimer.ambassador.text5": "Do not start a new form, contact us at ",
  "studentDisclaimer.ambassador.email": "info@habacus.com",

  //studentDisclaimer POSTE
  "studentDisclaimer.poste.title": "Habacus Certification",
  "studentDisclaimer.poste.subtitle": "Habacus Certification for Promo Studi Prestito BP, start your application here!",
  "studentDisclaimer.poste.text1": "Applying for Habacus Certification for Promo Studi Prestito BP is quick and easy, it takes just 10 minutes.",
  "studentDisclaimer.poste.text2": "Choose if taking Test Motiva, insert your personal information in the following form and upload your personal documents.",
  "studentDisclaimer.poste.text3": "We will guide you during your application and make your access to Prestito BancoPosta.",
  "studentDisclaimer.poste.text4": "Any doubts?",
  "studentDisclaimer.poste.text5": "For any problem during the process write an email to ",
  "studentDisclaimer.poste.email": "promostudiprestitobp@habacus.com",


  //studentDisclaimer ZOPPAS
  "studentDisclaimer.zoppas.title": "Habacus Certification",
  "studentDisclaimer.zoppas.subtitle": "Habacus Certification for tender ZOPPAS INDUSTRIES, start your application here!",
  "studentDisclaimer.zoppas.text1": "Applying for Habacus Certification for tender ZOPPAS INDUSTRIES is quick and easy, it takes just 10 minutes.",
  "studentDisclaimer.zoppas.text2": "Insert your personal information in the following form and upload your personal documents.",
  "studentDisclaimer.zoppas.text3": "We will guide you during your application and make your access to tender ZOPPAS INDUSTRIES",
  "studentDisclaimer.zoppas.text4": "Any doubts?",
  "studentDisclaimer.zoppas.text5": "Contact us at ",
  "studentDisclaimer.zoppas.email": "info@habacus.com",

  //studentDisclaimer CARIPLO
  "studentDisclaimer.cariplo.title": "Habacus Certification",
  "studentDisclaimer.cariplo.subtitle": "Apply for Habacus Certification for the scolarship supported by Fondazione Cariplo, start your application here!",
  "studentDisclaimer.cariplo.text1": "Applying for Habacus Certification to obtain the scolarship is quick and easy, it takes just 10 minutes.",
  "studentDisclaimer.cariplo.text2": "Insert your personal information in the following form and upload your personal documents.",
  "studentDisclaimer.cariplo.text3": "We will guide you during your application and make your access to tender to get the Scolarship.",
  "studentDisclaimer.cariplo.text4": "Any doubts?",
  "studentDisclaimer.cariplo.text5": "Contact us at ",
  "studentDisclaimer.cariplo.email": "progetti@fondazionehabacus.com",


  //----userInformation.tsx----
  "horizontalStepper.step1": "ORIENTATION TEST",
  "horizontalStepper.step2": "PERSONAL DATA",
  "horizontalStepper.step2b": "ACADEMIC DATA",
  "horizontalStepper.step3": "UPLOAD DOCUMENTS",
  "horizontalStepper.step4": "DATA SUMMARY",
  "horizontalStepper.step5": "RESULT RECEIVED",
  //------------

  "horizontalStepper.subtitle.step1": "Start Motiva Test",
  "horizontalStepper.subtitle.step2": "Data entry",
  "horizontalStepper.subtitle.step3": "Upload documents",
  //common
  "input.select.placeholder": "Select an option",

  "button.verifyAmbassador": "Verify Ambassador Code",
  "button.continue": "Continue",
  "button.cancel": "Cancel",
  "button.save&continue": "Save & Continue",
  "button.finish": "Done!",
  "button.saveAndGoOn1": "SAVE",
  "button.saveAndGoOn2": "AND CONTINUE",
  "button.goOn": "CONTINUE",
  "button.yesTest": "TAKE THE TEST",
  "button.noTest": "SKIP THE TEST",



  "indietro": "BACK",
  //---

  //step1-----
  //orientation page for bpi
  "orientation.bpi.title": "Career Test Motiva",
  "orientation.bpi.text1a": "The Motiva career test is not mandatory to obtain the certification.",
  "orientation.bpi.text1b": "You can chose whether to take the test now or later. Remember that once started, you must necessarily complete it before applying for certification. If you decide to skip the test, you will receive an email to do so when you you prefer, within 30 days.",
  "orientation.bpi.text1c": "Se decidi di farlo ADESSO, dovrai obbligatoriamente portarlo a termine per poter effettuare la certificazione.",
  "orientation.bpi.confirmNoTestModal.title": "Skip the test and apply for Habacus certification",
  "orientation.bpi.confirmNoTestModal.text": "Riceverai una mail con il link per effettuare il Test",
  "orientation.bpi.confirmYesTestModal.title": "Start the test",
  "orientation.bpi.confirmYesTestModal.text1": "The test will open in another tab.",
  "orientation.bpi.confirmYesTestModal.text2": "Remember that once started, the test must necessarily be concluded before making the certification request. If you have any problems, send an email to promostudiprestitobp@habacus.com",
  "orientation.bpi.confirmYesTestModal.text3": "Return to this page to confirm the end of the test and continue with the process, or to retrieve the link to the test.",
  "orientation.bpi.testVisible.text1": "If you want to re-open the test page click on Test Motiva",
  "orientation.bpi.testVisible.text2": "If you have finished the test, click on Continue and continue with the certification process",
  "orientation.bpi.testVisible.link": "Link Test Motiva",
  "orientation.bpi.confirmTestDone.title": "Test Motiva Concluso",
  "orientation.bpi.confirmTestDone.text": "Confirm that you have completed the test and continue with the certification process",
  //step2------
  //info1
  "personalDataPage.ambassadorNotFound": "Referral Ambassador not found. If you have an Ambassador Code enter it and continue.",
  "personalDataPage.ambassadorDisclaimer": "If you have an Ambassador Code enter it and continue.",
  "personalDataPage.title.create": "Enter your Personal Data",
  "personalDataPage.subtitle1.create": "Enter here your personal data and read the Information notice on processing of personal data.",
  "personalDataPage.subtitle2.create": "Once you complete this step, click on 'Save and Continue' to go to the next page.",
  "personalDataPage.title.update": "Edit your Personal Data",
  "personalDataPage.subtitle1.update": "Edit here your personal data and read the Information notice on processing of personal data.",
  "personalDataPage.subtitle2.update": "Once you complete this step, click on 'Save and continue' to go to the next page.",



  "personalDataPage.ambassador1": "You have been invited by ",
  "personalDataPage.ambassador2": "for this application will be your Habacus contact.",
  "personalDataPage.ambassador3": "Ambassador data not found.",
  "personalDataPage.ambassador4": "Welcome on Habacus Onboarding!",

  "personalDataPage.block1.title": "Personal information",
  "personalDataPage.block1.name": "Name",
  "personalDataPage.block1.surname": "Surname",
  "personalDataPage.block1.gender": "Gender",

  "personalDataPage.block1.genderOptions.male": "Male",
  "personalDataPage.block1.genderOptions.female": "Female",
  "personalDataPage.block1.genderOptions.other": "I prefer not to declare it",


  "personalDataPage.block1.date": "Date of birth",
  "personalDataPage.block1.born_city": "City of birth",
  "personalDataPage.block1.born_province": "Province of birth",

  "personalDataPage.block1.born_country": "Country of birth",
  "personalDataPage.block1.citizenships": "Citizinship List",
  // "personalDataPage.block1.citizenship_ue": "UE citizinship",
  "personalDataPage.block1.italian_residence": "Italian Residence or AIRE enrolled",
  "personalDataPage.block1.tax_code": "Tax Code",

  "personalDataPage.block2.title": "Residence",
  "personalDataPage.block2.citizen_country": "Country",
  "personalDataPage.block2.citizen_province": "Province",
  "personalDataPage.block2.citizen_city": "City",
  "personalDataPage.block2.citizen_zip": "Zip Code",
  "personalDataPage.block2.citizen_address": "Residence address",
  "personalDataPage.block2.citizen_address_number": "Residence street number",
  "personalDataPage.block2.phone_country_code": "Phone country code",
  "personalDataPage.block2.phone": "Phone number",
  "personalDataPage.block2.email": "E-mail",
  "personalDataPage.block2.confirm_email": "Confirm E-mail",
  "personalDataPage.block2.checkboxDomicile": "Domicile if different from residence",

  "personalDataPage.block3.domicile_country": "Country",
  "personalDataPage.block3.domicile_province": "Province",
  "personalDataPage.block3.domicile_city": "City",
  "personalDataPage.block3.domicile_zip": "Zip Code",
  "personalDataPage.block3.domicile_address": "Domicile address",
  "personalDataPage.block3.domicile_address_number": "Domicile street number",

  //opzioni nei dati anagrafici
  "personalDataPage.option.ue": "UE",
  "personalDataPage.option.xue": "Extra UE",
  "personalDataPage.option.no": "No",
  "personalDataPage.option.yes": "Yes",
  "personalDataPage.option.male": "Male",
  "personalDataPage.option.female": "Female",
  "personalDataPage.option.other": "I prefer not to declar it",

  "personalDataPage.error_message.1": "Impossible to continue. Tax Code or Email already in use.",
  "personalDataPage.error_message.2": "Contact us to be able to continue.",
  "personalDataPage.error_message.3": "We understand that this Tax Code and Email are in use for another student.",
  "personalDataPage.error_message.4": "You already have an application in progress so you can't start another one.",

  "personalDataPage.error_required": "Required",

  //--------fine info1------
  //inizio info2
  "academicDataPage.block1.title": "Academic information",
  "academicDataPage.degree_class_id": "Degree Class",
  "academicDataPage.course_type": "Course Typology",
  "academicDataPage.school_course_id": "Faculty",
  "academicDataPage.school_id": "School or University",
  "academicDataPage.number": "Student Code",

  "academicDataPage.text": "Fill out this page with the academic information of the course of study you are attending and for which you want to obtain the Habacus certification.",

  "academicDataPage.text2": "Then click 'Save and Continue' and go to the last step.",


  //fine info2



  //ALERT MODAL
  "alertModal.1": "WARNING",
  "alertModal.2a": "Is the ",
  "alertModal.2b": "Tax Code",
  "alertModal.2c": " correct",
  "alertModal.3a": "Is the",
  "alertModal.3a2": " ",
  "alertModal.3b": "age",
  "alertModal.3c": " correct",



  //FINE ALERT MODAL


  //ALERT AR MODAL
  "alertModalAR.title": "WELCOME",
  "alertModalAR.text1": "Your personal data had already been previously saved in our database.",
  "alertModalAR.text2": "If you want to change them send us an email to ",
  "alertModalAR.text3": "Continue with the onboarding process to create a new application.",


  //FINE ALERT AR MODAL


  //-----------------------------------NOTE LEGALI DA TRADURRE-------------------
  //inizio info3
  //r17 privacy options da fare, prestare o non prestare
  // set errpor intorno a r 40 anche da fare
  "step2.info3.privacy.title": "Privacy",
  "step2.info3.privacy.title1": "1. Third party marketing activities:",
  "step2.info3.privacy.title2": "2. Production and broadcasting of promotional materials:",
  "step2.info3.privacy.text1": "1. With reference to the processing of My personal data by Habacus S.r.l. for the processing purposes described in art. 4 of the Privacy Notice sub Annex A, relating to marketing purposes, commercial information, direct offers, market surveys or customer satisfaction relating to products and services offered by the companies and promoters with which HABACUS S.r.l. collaborates.",
  "step2.info3.privacy.text3": "2. Furthermore, with reference to the processing of My personal data by Habacus S.r.l. for the processing purposes described in art. 4, point c) of the information under Annex A, relating to the use, reproduction and publication, together with my name and surname, of personal information referred to me (including photographs and / or videos portraying me, information in relation to to the mentorship services received as well as details of My social accounts), in the context of carrying out communication initiatives, also of an informative - promotional nature, of HABACUS activities and my participation in fairs, open-days and other promotional events.",
  "step2.info3.checkbox.lable1": "I confirm that I have read the ",
  "step2.info3.checkbox.lable2": "privacy policy",
  "step2.info3.checkbox.lable3": " on the processing of personal data and contacts for withdrawal of consents.",

  "step2.info3.radio.yes": "I give consent",
  "step2.info3.radio.no": "I deny consent",
  "step2.info3.radio.error": "Choose yes / no in the privacy policies",

  "step2.info3.attachedFile": "Download Annex A",


  //fine info 3
  //FINE step2

  //INIZIO----step3
  //up1
  "step3.up1.title": "Do you have an ambassador code?",
  "step3.up1.text1": "Welle done!",
  "step3.up1.text2": "The data registration was successful.",
  "step3.up1.text3": "If you have been invited by ",
  "step3.up1.text4": ", one of our Habacus Ambassadors, enter the code sent to you. Otherwise proceed safely by following the instructions for uploading the proxy.",


  "step3.up1.inputLable": "Ambassador Code",
  "step3.up1.yes": "YES",
  "step3.up1.no": "NO",




  "ambassadorPage.empty.title": "Do you have an ambassador code?",
  "ambassadorPage.empty.text1": "If you have a code provided by one of our ambassadors, enter it below.",
  "ambassadorPage.empty.text2": "Otherwise, leave the field blank and continue on.",
  "ambassadorPage.filled.title": "Check the ambassador code",
  "ambassadorPage.filled.text1": "If you have been invited by ",
  "ambassadorPage.filled.text2": ", one of our Habacus Ambassadors, check that the code entered is the one you were given, otherwise change it or select -no- and continue with the onboarding process.",
  "ambassadorPage.linkToAmbassadorProgramm": "if you are interested in the ambassador program visit ",

  //fine up1
  //inizio up2
  "uploadProxy.title": "Download, sign and re-upload your proxy",

  "uploadProxy.text1": "Below you can find a proxy document with the data you entered of the previous steps already filled in.",
  "uploadProxy.text2": "Download it and fill in the marked fields: date, place and handwritten signature. Then upload it here.",
  "uploadProxy.text3": "Did you make a mistake? Don't worry, you can go back to the previous steps and correct it.",
  "uploadProxy.privacy1": "The processing of your personal data related to the proxy will be carried out as indicated in the ",
  "uploadProxy.privacy2": "privacy policy",

  "uploadProxy.download": "Download",
  "uploadDefaultProxy.download": "Problems with the completed proxy? Click here to download one to fill out.",

  //fine up2
  //inizio up3
  "step3.up3.title": "Upload your Personal Documents",

  "uploadPersonalDocuments.text1": "To complete your certification request you have to upload the following documents:",
  "uploadPersonalDocuments.text2": "- Italian identity card;",
  "uploadPersonalDocuments.text3": "- Italian national health insurance card (tessera sanitaria).",
  "uploadPersonalDocuments.text4": "Make sure the documents are front / back, legible and not expired.", "uploadPersonalDocuments.text5": "Try to avoid any backgrounds / flashes / fingers...",
  "uploadPersonalDocuments.text6": "N.B: If you do not have an Italian Identity card, share your residence certificate for Italy; if you do not have an Italian health insurance card, you can request a provisional copy online.",


  "CI.title": "For ID card holders:",
  "CI.ci": "Document 1 - front / back loading of the identity card",
  "CI.ts": "Document 2 - front / back loading of the health card",
  "CI.del": "Document 3 - upload proxy",
  "PSP.title": "For passport holders:",
  "PSP.psp": "Document 1 - front / back loading of passport",
  "PSP.res": "Document 2 - residence certificate upload",
  "PSP.ts": "Document 3 - front / back loading of the health card",
  "PSP.del": "Document 4 - upload proxy",

  "NewConsolidatedDocumentUploadPath.title": "The documents required for the completion of the registration are:",
  "NewConsolidatedDocumentUploadPath.1": "Identity card, for which front / back scanning is required",
  "NewConsolidatedDocumentUploadPath.2": "Health Card, which is required to be scanned on both sides",
  "NewConsolidatedDocumentUploadPath.3": "proxy",
  "NewConsolidatedDocumentUploadPath.end": "Anyone who is NOT in possession of the Identity Card must upload the front / back scan of the Passport and Residence Permit.",
  "uploadDocuments.ID": "Identity Card",
  "uploadDocuments.SC": "Sanitary Card",
  "uploadDocuments.DE": "Proxy",
  "uploadDocuments.concern_payments": "Registration Payment Receipt",
  "uploadDocuments.learning_agreement": "Erasmus Learning Agreement",
  "uploadDocuments.final_grade": "High School Diploma / Degree Certificate with Grade",
  "uploadDocuments.attended_frequency": "Attended Frequency",
  "uploadDocuments.at_least_n_credits": "University Booklet",
  "uploadDocuments.studies_plan": "Studies Plan",
  "uploadDocuments.singleFile": "Single File",
  "uploadDocuments.singleFileFrontBack": "Single File - FRONT/BACK",
  "uploadDocuments.singleFileAllPages": "File Unico - ALL PAGES",
  "uploadDocuments.multipleFiles": "Multiple Files",
  "uploadDocuments.front": "Front",
  "uploadDocuments.back": "Back",
  "uploadDocuments.errorMex": "Errors found in the previously uploaded document",
  "uploadDocuments.errorMexGeneric": "Errors found in the previously uploaded document. Upload it again.",


  "upload.doc.x": "Upload Document ",
  "remove.doc": "DELETE X",
  "add.doc.label": "Document Name",//non va
  "add.doc.placeholder": "Select document to upload",//non va
  "add.doc": "ADD DOCUMENT +",
  "added.docs": "Already uploaded documents",
  "uploaded.docs": "Uploading documents",

  "alertProxyModal.1": "Are you sure to avoid Proxy upload?",
  "alertProxyModal.2": "Without uploading the proxy document your application will not be processed. Please remember to upload it later.",


  "accessReq.title": "Upload your academic documents",
  "accessReq.text1": "Upload here your academic documents.",
  "accessReq.text2": "After you select the academic semester you are attending, we will indicate which documents you have to upload, basing our requirements on the educational institution and course you have entered in the previous steps.",
  "accessReq.text3": "In case you don't have the necessary academic documents, you can continue filling in the application and we will ask your educational institution for the documents.",
  "accessReq.text4": "Please take into consideration that the Italian schools answer us in about 15/20 days, while foreign schools in 30/40 days.",

  "accessReq.select": "Year and Semester",

  "accessReq.modal.title": "You have not uploaded all the documents!",
  "accessReq.modal.text": "You can go back later to upload your documents or wait for the academic institution for which you are applying for certification to send them to us.",


  //fine up3

  //fine step3
  //inizio step 4
  "step4.title1": "PERSONAL INFORMATION",
  "step4.title2": "ACADEMIC INFORMATION",
  "step4.title3": "UPLOADED DOCUMENTS",
  "step4.success": "Successfully saved...",




  "recap.title": "You are almost done!",
  "recap.text1": "Here is a summary of all the data you entered in the previous steps.",
  "recap.text2": "Is everything correct? If not, click on one of the previous steps above and correct your data.",
  "recap.text3": "Remember to re-download, re-sign and re-upload the proxy document, as it will automatically update with any changes you make.",
  "recap.text4": "If everything is ok, just click on 'Done!'",

  ///Users/cavicchioli/Documents/habacus_students_editor/src/app/utils/documents.ts
  //documents names ^
  //fine step 4

  //dashboard
  "dashboard.newPractice": "CREATE NEW PRACTICE",
  "dashboard.startButton": "START",
  //---------







  //RECAP FINALE    ----------     INIZIO
  "recap.name": "Name",
  "recap.surname": "Surname",
  "recap.gender": "Gender",
  "recap.born_at": "Date of birth (gg-mm-aaaa)",
  "recap.born_city": "City of birth",
  "recap.born_province": "Province of birth",
  "recap.born_country": "Country of birth",
  "recap.citizenships": "Citizinship List",
  // "recap.citizenship_ue": "Citizenship UE or X-UE",
  "recap.tax_code": "Tax code",
  "recap.italian_residence": "Italian residence or enrolled into AIRE",
  "recap.citizen_country": "Country of residence",
  "recap.citizen_province": "Province of residence",
  "recap.citizen_city": "City of residence",
  "recap.citizen_zip": "Tax code of residence",
  "recap.citizen_address": "Address of residence",
  "recap.citizen_address_number": "Street number of residence",
  "recap.phone_country_code": "Phone country code (Ex. for Italy: +39)",
  "recap.phone": "Phone Number",
  "recap.email": "E-Mail",
  "recap.domicile_country": "Country of domicile",
  "recap.domicile_province": "Province of domicile",
  "recap.domicile_city": "City of domicile",
  "recap.domicile_zip": "Tax code of domicile",
  "recap.domicile_address": "Address of domicile",
  "recap.domicile_address_number": "Street number of domicile",
  "recap.degree_class_id": "Faculty",
  "recap.school_course_id": "Degree class",
  "recap.course_type": "Course typology",
  "recap.school_id": "Schools",
  "recap.number": "Student number",
  "recap.invited_by_student_ambassador_code": "Ambassador's code",
  // "recap.invited_by_student_id": "Invited by (ID)",
  "recap.privacy_notifications": "Privacy Acceptance",

  "recap.notInvited": "No invitation",

  //RECAP FINALE    ----------     FINE


  //THANK YOU
  "thankYou.1": "You completed your certification request!",
  "thankYou.2": "Now it's our turn!",
  "thankYou.3": "We received your documents, we will now proceed with checking your application.",
  "thankYou.4": "We are going to contact your educational institution to verify that you are up to date with your study plan.",
  "thankYou.5": "You will hear from us soon, we will update you via email on the status of your request.",
  "thankYou.6": "Do you have any doubts?",
  "thankYou.7": "Visit our ",
  "thankYou.8": " or send an email to ",
  "select.loading": "Loading",

  "banner.gdpr": "This website or its third-party tools process personal data (e.g. browsing data or IP addresses) and use cookies or other identifiers, which are necessary for its functioning and required to achieve the purposes illustrated in the cookie policy.You accept the use of cookies or other identifiers by closing or dismissing this notice, by scrolling this page, by clicking a link or button or by continuing to browse otherwise.",
  "banner.accept": "Accept",
  "banner.discover": "Discover more",
}




function App() {

  const [language, setLanguage] = React.useState<string>('IT');

  const [isStepperVisible, setStepperVisible] = React.useState<boolean>(true);

  const [stepNumber, setStepNumber] = React.useState<number>(4);
  const [currentStepNumber, setCurrentStepNumber] = React.useState<number>(2);


  const onChangelanguage = (value: string) => {
    setLanguage(value);
  }
  const getLanguageSelectedClass = (lang: string) => {
    if (lang === language) {
      return "language languageSelected";
    }
    return "language";
  }

  const headerStepperClass = (): string => {
    if (isStepperVisible) {
      return 'stepperHeaderWrapper hidden';
    }
    return 'stepperHeaderWrapper';
  }

  const headerLogoMobileStepperClass = (): string => {
    if (window.screen.width > 1024) {
      if (isStepperVisible) {
        return 'hidden';
      }

      return 'posAbsLeft';
    }

    if (isStepperVisible) {
      return 'hidden';
    }
    return 'habacusMobileLogo posAbsLeft';
  }

  const headerLogoDesktopStepperClass = (): string => {
    if (window.screen.width > 1024) {
      if (!isStepperVisible) {
        return 'hidden';
      }

      return ' habacusDesktopLogo';
    }

    if (!isStepperVisible) {
      return 'hidden ';
    }
    return 'habacusDesktopLogo ';
  }
  const languageFromUrl = getQueryParam(LANGUAGE);

  useEffect(() => {
    if (languageFromUrl === 'it' || languageFromUrl === 'IT' || languageFromUrl === 'en' || languageFromUrl === 'EN') {
      onChangelanguage(languageFromUrl.toUpperCase());
      console.log(languageFromUrl);
    }
  }, []);




  const consent = <FormattedMessage id="banner.accept" />;
  const discoverMore = <FormattedMessage id="banner.discover" />;

  return (
    <IntlProvider messages={language === 'IT' ? messagesInItalian : messagesInEnglish} locale={language} defaultLocale="EN">
      <BrowserRouter basename="/">
        <div>
          {/* <div className="sticky-top center-y mb-10 px-16 py-10 bg-white flexForHeader"> */}
          <div className="flexForHeader sticky-top bg-white mx-auto lg:w-4/5 md:w-full sm:w-full t-body1 px-16 py-1 mb-15">
            <div className="imgWrapper">

              <img
                alt="Habacus Icon"
                height={50}
                // width={auto}
                src={HabacusIcon}
                className={headerLogoDesktopStepperClass()}
              />

            </div>
            <img
              alt="Habacus Icon"
              height={50}
              // width={auto}
              src={HabacusIconMobile}
              className={headerLogoMobileStepperClass()}
            />

            <div className={headerStepperClass()}>
              <StepperHeader
                stepNumber={stepNumber}
                currentStepNumber={currentStepNumber}
              />
            </div>

            <div className="ITorEN">
              <span className={getLanguageSelectedClass('IT')} onClick={() => onChangelanguage('IT')}>IT</span>
              <span> / </span>
              <span className={getLanguageSelectedClass('EN')} onClick={() => onChangelanguage('EN')}>EN</span>
            </div>


          </div>
          <div className="mx-auto lg:w-4/5 md:w-full sm:w-full t-body1 px-16 py-1">
            <Routes>
              {/* <Route exact path="/all-students" component={AllStudents} /> */}

              {/* <Route exact path="/anagraphic" component={ThankYou} /> */}
              {/* <Route exact path="/accademics" component={ThankYou} /> */}
              {/* <Route exact path="/documents" component={ThankYou} /> */}
              {/* <Route exact path="/recap" component={ThankYou} /> */}
              <Route path="/thank-you"
                element={<ThankYou
                  onSetStepperVisible={setStepperVisible} />}


              />

              <Route

                path="/user-information"
                element={
                  <UserInformationDashboard

                    onSetStepNumber={setStepNumber}
                    onSetCurrentStepNumber={setCurrentStepNumber}
                    onSetStepperVisible={setStepperVisible}
                    onChangelanguage={onChangelanguage}
                  />
                }
              />
              {/* <Route
                exact
                path="/user-information"
                render={
                  (props) =>
                    useEffect(() => {
                      <UserInformationDashboard
                        {...props}
                        onSetStepNumber={setStepNumber}
                        onSetCurrentStepNumber={setCurrentStepNumber}
                        onSetStepperVisible={setStepperVisible}
                        onChangelanguage={onChangelanguage}
                      />
                    }, [])
                }
              /> */}

              <Route
                path="/"
                element={<Navigate to="/user-information" />}
              />


            </Routes>
          </div>
        </div>
      </BrowserRouter>

      <CookieConsent
        overlay={true}
        location="bottom"
        buttonText={consent}
        declineButtonText={discoverMore}
        cookieName="welcome.habacus.com"
        // style={{ background: "#2B373B" }}
        // buttonStyle={{ backgroundColor: "#003da6", color: "#fff", fontSize: "13px" }}
        style={{ background: "#003da6" }}
        buttonStyle={{ backgroundColor: "#ffff", color: "#003da6", fontSize: "13px" }}
        expires={150}
        // enableDeclineButton
        // onDecline={() => {
        //   // document.location.href = 'https://habacus.com';
        //   window.open("https://www.habacus.com/it/privacy", "_blank");
        // }}
        onAccept={() => {
          console.log('accepted cookie');
          window.location.reload();
        }}
      >
        <span style={{ fontSize: "10px" }}>
          <FormattedMessage id="banner.gdpr" />
        </span>
        <span style={{ paddingLeft: "1rem" }}><a href="https://www.habacus.com/it/privacy" target="_blank" className="colorwhite" rel="noreferrer"><FormattedMessage id="banner.discover" /></a></span>
      </CookieConsent>
    </IntlProvider>
  );
}

export default App;
