import Loader from "app/components/common/loader/loader";
import {
  getAccessRequirementInformation, getAmbassadorInfo, getApplicationInformation, getCitizenships, getLastAccessRequirementInformation, getLastApplicationInformation, getNations, getPhoneCodeNations, getProvinces, getSchoolCourseTypes, getStudentInformation
} from "app/service/apiService";
import { IdLabel } from "app/types/common";
import { getQueryParam } from "app/utils/utils";
import React from "react";
import { FormattedMessage } from "react-intl";
import StudentInfoContext, { StudentInfoContextType } from "./studentContext";

interface Props { }

export const STUDENT_TOKEN_PARAM_NAME = "token";
export const APPLICATION_TOKEN_PARAM_NAME = "token_p";
export const ACCESS_REQUIREMENT_TOKEN_PARAM_NAME = "token_ar";
export const ORIGIN_TOKEN_PARAM_NAME = "token_or";
export const CONVERSION_CRM_LEAD_ID_TOKEN_PARAM_NAME = "crm_lead_id";
export const CONVERSION_CRM_LEAD_SOURCE_TOKEN_PARAM_NAME = "crm_lead_source";
export const CONVERSION_CRM_CAMPAIGN_SOURCE_TOKEN_PARAM_NAME = "crm_campaign_source";
export const CONVERSION_UTM_SOURCE_TOKEN_PARAM_NAME = "utm_source";
export const CONVERSION_UTM_CAMPAIGN_TOKEN_PARAM_NAME = "utm_campaign";
export const CONVERSION_UTM_TERM_TOKEN_PARAM_NAME = "utm_term";
export const CONVERSION_UTM_CONTENT_TOKEN_PARAM_NAME = "utm_content";
export const CONVERSION_UTM_MEDIUM_TOKEN_PARAM_NAME = "utm_medium";

export const LANGUAGE = "lang";

export const REFERRAL = "referral";
export const REFERRAL_CODE = "referral_code";

export const POSTE_REFERRAL = "BPI";
export const AMBASSADOR_REFERRAL = "ambassador";
export const ZOPPAS_REFERRAL = "zoppasindustries";
export const CARIPLO_REFERRAL = "cariplo";

export const TEST_MOTIVA_LINK = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";


type ComponentProps = Props & { children: any };

const StudentInfoProvider: React.FC<ComponentProps> = (
  props: ComponentProps
) => {
  const [loadingMetadata, setLoadingMetadata] = React.useState<boolean>(true);
  const [metadata, setMetadata] = React.useState<StudentInfoContextType>(
    {} as StudentInfoContextType
  );
  const [studentNotFound, setStudentNotFound] = React.useState<boolean>(false);
  const [applicationNotFound, setApplicationNotFound] = React.useState<boolean>(false); //if i remove the unused const, the apps breaks (we use the setxxxx and we need it in 2 place)
  const [accessRequirementNotFound, setAccessRequirementNotFound] = React.useState<boolean>(false); // //

  const [ambassadorNotFound, setAmbassadorNotFound] = React.useState<boolean>(false); // //



  const updateMetadata = (value: object) =>
    setMetadata({ ...metadata, ...value });

  React.useEffect(() => {
    const ac = new AbortController();

    let
      // schoolDegrees: Array<IdLabel>,
      courseTypes: Array<IdLabel>,
      // schools: Array<IdLabel>,
      citizenships: Array<any>,
      nations: Array<IdLabel>,
      phoneCodeNations: Array<IdLabel>,
      provinces: Array<IdLabel>,
      student: any = {},
      isCreationMode = true,
      studentApplication: any = {},
      accessRequirement: any = {},
      ambassador: any = {};

    const promiseList: Promise<any>[] = [

      // getSchoolDegrees().then((res) => (schoolDegrees = res.degree_classes)),
      getSchoolCourseTypes(getQueryParam(ORIGIN_TOKEN_PARAM_NAME), '').then(
        (res) =>
        (courseTypes = Object.keys(res.courses || {}).map((c) => ({
          id: res.courses[c].id,
          label: res.courses[c].label,
        })))
      ),
      // getSchools().then(
      //   (res) =>
      //     (schools = res.schools?.map((s: any) => ({ ...s, label: s.name })))
      // ),
      getCitizenships().then(
        (res) =>
        (citizenships = Object.keys(res.citizenships || {}).map((n) => ({
          id: res.citizenships[n].id,
          label: {
            name_en: res.citizenships[n].name_en,
            name_it: res.citizenships[n].name_it,
          }
        })))
      ),
      getNations().then(
        (res) =>
        (nations = Object.keys(res.nations || {}).map((n) => ({
          id: n,
          label: res.nations[n],
        })))
      ),
      getProvinces().then(
        (res) =>
        (provinces = Object.keys(res.provinces || {}).map((p) => ({
          id: p,
          label: res.provinces[p],
        })))
      ),
      getPhoneCodeNations().then(
        (res) =>
        (phoneCodeNations = res.phoneCodeNations.map((p: any) => ({
          id: p.dial_code,
          label: '<span class="fi fi-' + p.iso_code_2.toLowerCase() + '"></span>&nbsp;' + p.name_it + " (" + p.dial_code + ")",
        })))
      ),




    ];

    const studentToken = getQueryParam(STUDENT_TOKEN_PARAM_NAME);
    if (studentToken) {
      isCreationMode = false;
    } else {
      isCreationMode = true;
    }
    if (studentToken) {
      promiseList.push(
        getStudentInformation(studentToken)
          .then((res: any) => {
            if (res.status === "OK") {
              student = res?.student || null;
            }
            else {
              setStudentNotFound(true);
              student = null;
            }
          })
          .catch(() => setStudentNotFound(true))
      );
    }

    const applicationToken = getQueryParam(APPLICATION_TOKEN_PARAM_NAME);
    if (studentToken && applicationToken) {
      promiseList.push(
        getApplicationInformation(studentToken, applicationToken)
          .then((res: any) => {
            if (res.status === "OK") {
              studentApplication = res?.application || null;
            }
            else {
              setApplicationNotFound(true);
              studentApplication = null;
            }
          })
          .catch(() => setApplicationNotFound(true))
      );
    } else if (studentToken) {
      promiseList.push(
        getLastApplicationInformation(studentToken)
          .then((res: any) => {
            if (res.status === "OK") {
              studentApplication = res?.application || null;
            }
            else {
              setApplicationNotFound(true);
              studentApplication = null;
            }
          })
          .catch(() => setApplicationNotFound(true))
      );
    }

    const accessRequirementToken = getQueryParam(ACCESS_REQUIREMENT_TOKEN_PARAM_NAME);
    if (studentToken && applicationToken && accessRequirementToken) {
      promiseList.push(
        getAccessRequirementInformation(studentToken, applicationToken, accessRequirementToken)
          .then((res: any) => {
            if (res.status === "OK") {
              accessRequirement = res?.access_requirement || null;
            }
            else {
              setAccessRequirementNotFound(true);
              accessRequirement = null;
            }
          })
          .catch(() => setAccessRequirementNotFound(true))
      );
    } else if (studentToken && applicationToken) {
      promiseList.push(
        getLastAccessRequirementInformation(studentToken, applicationToken)
          .then((res: any) => {
            if (res.status === "OK") {
              accessRequirement = res?.access_requirement || null;
            }
            else {
              setAccessRequirementNotFound(true);
              accessRequirement = null;
            }
          })
          .catch(() => setAccessRequirementNotFound(true))
      );
    } else if (studentToken) {
      promiseList.push(
        getLastAccessRequirementInformation(studentToken)
          .then((res: any) => {
            if (res.status === "OK") {
              accessRequirement = res?.access_requirement || null;
            }
            else {
              setAccessRequirementNotFound(true);
              accessRequirement = null;
            }
          })
          .catch(() => setAccessRequirementNotFound(true))
      );
    }


    const referral = getQueryParam(REFERRAL);
    const referralCode = getQueryParam(REFERRAL_CODE);
    if (referral && referral === AMBASSADOR_REFERRAL && referralCode) {
      promiseList.push(
        getAmbassadorInfo(referralCode)
          .then((res: any) => {
            if (res.status === "OK") {
              ambassador = res?.ambassador || null;
            }
            else {
              setAmbassadorNotFound(true);
              ambassador = null;
            }
          })
          .catch(() => setAmbassadorNotFound(true))
      );
    }

    const getAmbassadorFromCode = async (code: string): Promise<boolean> => {
      // setLoadingMetadata(true);
      try {
        const res = await getAmbassadorInfo(code);

        if (res.status === "OK") {
          ambassador = res?.ambassador || null;
          if (res.ambassador) {
            setAmbassadorNotFound(false);
          } else {
            setAmbassadorNotFound(true);
          }
        }
        else {
          setAmbassadorNotFound(true);
          ambassador = null;
        }

      } catch (e) {
        setAmbassadorNotFound(true)
        console.log(e);

      }
      setMetadata({ student, /*schoolDegrees, schools,*/courseTypes, citizenships, nations, phoneCodeNations, provinces, studentApplication, isCreationMode, ambassador, accessRequirement, getAmbassadorFromCode });

      // setLoadingMetadata(false);

      console.log(ambassador);
      return ambassador;
    }

    Promise.all(promiseList)
      .then(() => {
        setMetadata({ student, /*schoolDegrees, schools,*/courseTypes, citizenships, nations, phoneCodeNations, provinces, studentApplication, isCreationMode, ambassador, accessRequirement, getAmbassadorFromCode });
        setLoadingMetadata(false);
      })
      .catch(() => setLoadingMetadata(false));

    return () => ac.abort(); // Abort fetches on unmount
  }, []);

  const renderStudentNotFound = () => (
    <div className="text-2xl text-center mt-30">
      <FormattedMessage id="noUserInfo" />
    </div>
  );

  return (
    <StudentInfoContext.Provider
      value={{ ...metadata, setMetadata: updateMetadata }}
    >
      {loadingMetadata ? (
        <Loader />
      ) : (metadata?.student && !studentNotFound ? (

        props.children
      ) : (
        renderStudentNotFound()
      ))}
    </StudentInfoContext.Provider>
  );
};

export default StudentInfoProvider;
