import ConditionalLoader from "app/components/common/loader/conditionalLoader";
import TextInput from "app/components/common/textInput";
import StudentInfoContext from "app/context/studentContext";
import { getLastAccessRequirementInformation, upsertSchool } from "app/service/apiService";
import { IdLabel } from "app/types/common";
import { getDataSubmitStateClass, getQueryParam } from "app/utils/utils";
import React, { useEffect } from "react";

import CourseTypologySelect from "app/components/common/courseTypologySelect";
import SchoolSelect from "app/components/common/schoolSelect";
import { FormattedMessage, useIntl } from "react-intl";

import ButtonSave from "app/components/common/buttonSave";
import CourseSelect from "app/components/common/courseSelect";
import DegreeSelect from "app/components/common/degreeSelect";
import { AMBASSADOR_REFERRAL, CARIPLO_REFERRAL, POSTE_REFERRAL, REFERRAL, REFERRAL_CODE, ZOPPAS_REFERRAL } from "app/context/studentInfoProvider";


interface Props {
  studentInfo: any;
  studentApplicationInfo: any;
  onStudentInfoChange(data: object): void;
  onStudentApplicationInfoChange(data: object): void;
  onAccessRequirementInfoChange(data: object): void;
  onSaveAndContinue(): void;
}
const REQUIRED_FIELDS = [
  "course_type",
  "school_id",
  // "degree_class_id",
  // "school_course_id",
];

// const CONDITIONALLY_REQUIRED_FIELDS = [
//   "number"
// ];

const AcademicDataPage: React.FC<Props> = (props: Props) => {
  const { studentInfo = {}, studentApplicationInfo = {} } = props;

  const [invalidFields, setInvalidFields] = React.useState<any>();

  const [courseType, setCourseType] = React.useState<string>();
  const [schoolId, setSchoolId] = React.useState<string>();
  const [degreeClassId, setDegreeClassId] = React.useState<string>();
  const [schoolCourseId, setSchoolCourseId] = React.useState<string>();

  const [origin, setOrigin] = React.useState<string>();


  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // const studentInfoFromApplication = studentApplicationInfo?.student;
  // const schoolData = studentInfoFromApplication?.schools?.[0]?.pivot;
  const studentSchool = studentApplicationInfo?.student_school;

  const metadata = React.useContext(StudentInfoContext);

  const { ambassador } = React.useContext(StudentInfoContext);

  const referralFromUrl = getQueryParam(REFERRAL);
  const referralCodeFromUrl = getQueryParam(REFERRAL_CODE);

  useEffect(() => {
    // Update the document title using the browser API

    if (studentSchool?.course_type) {
      setCourseType(studentSchool?.course_type);
    }
    if (studentSchool?.school_id) {
      setSchoolId(studentSchool?.school_id);
    }
    if (studentSchool?.degree_class_id) {
      setDegreeClassId(studentSchool?.degree_class_id);
    }
    if (studentSchool?.school_course_id) {
      setSchoolCourseId(studentSchool?.school_course_id);
    }

    if (ambassador?.name) {
      setOrigin("REFERRAL_AMBASSADOR");
    } else {
      setOrigin("ONBOARDING");
    }

  }, [studentSchool, ambassador]);

  const updateData = (key: string, id: any, label: string | undefined, isNew: boolean | undefined) => {


    let studentSchoolToChange = studentApplicationInfo?.student_school ?? {};
    if (key === 'course_type') {
      setCourseType(id);
      setSchoolId('');
      setDegreeClassId('');
      setSchoolCourseId('');
      studentSchoolToChange = {
        ...studentSchoolToChange, [key]: id, 'degree_class_id': '', 'school_course_id': ''
      };
    } else if (key === 'school_id') {
      setSchoolId(id);
      setDegreeClassId('');
      setSchoolCourseId('');
      if (!isNew) {
        studentSchoolToChange = { ...studentSchoolToChange, [key]: id, 'is_new': false, 'name': null, 'degree_class_id': '', 'school_course_id': '' };
      } else {
        studentSchoolToChange = { ...studentSchoolToChange, [key]: label, 'is_new': true, 'name': label, 'degree_class_id': '', 'school_course_id': '' };
      }
    } else if (key === 'degree_class_id') {
      setDegreeClassId(id);
      setSchoolCourseId('');
      studentSchoolToChange = { ...studentSchoolToChange, [key]: id, 'school_course_id': '' };

    } else if (key === 'school_course_id') {
      setSchoolCourseId(id);
      studentSchoolToChange = { ...studentSchoolToChange, [key]: id };
    } else {
      studentSchoolToChange = { ...studentSchoolToChange, [key]: id };

    }


    props.onStudentApplicationInfoChange({
      ...studentApplicationInfo,
      student_school: studentSchoolToChange,
    });

    if (id && invalidFields?.[key]) {
      const errors = { ...invalidFields };
      delete errors[key];
      setInvalidFields(errors);
    }
  };

  const onSaveAndContinueClick = async () => {

    setIsLoading(true);
    try {
      let isAllOk = true;
      const errors = {};
      REQUIRED_FIELDS.forEach((field: string) => {
        if (!studentSchool?.[field]) {
          isAllOk = false;
          errors[field] = "Required";
        }
      });

      if (isToShowDegreeClass()) {
        if (!studentSchool?.["degree_class_id"]) {
          isAllOk = false;
          errors["degree_class_id"] = "Required";
        }
      }

      if (isToShowSchoolCourse()) {
        if (!studentSchool?.["school_course_id"]) {
          isAllOk = false;
          errors["school_course_id"] = "Required";
        }
      }

      if (isToShowMatricola()) {
        if (!studentSchool?.["number"]) {
          isAllOk = false;
          errors["number"] = "Required";
        }
      }

      if (!isAllOk) {
        setInvalidFields(errors);
        setIsLoading(false);
        return;
      }

      let referral = undefined;
      if (ambassador) {
        referral = {
          person: ambassador,
          type: AMBASSADOR_REFERRAL,
          code: ambassador?.ambassador_code,
        }
      } else if (referralFromUrl === POSTE_REFERRAL) {
        referral = {
          type: POSTE_REFERRAL,
          code: referralCodeFromUrl,
        }
      } else if (referralFromUrl === ZOPPAS_REFERRAL) {
        referral = {
          type: ZOPPAS_REFERRAL,
          code: referralCodeFromUrl,
        }
      } else if (referralFromUrl === CARIPLO_REFERRAL) {
        referral = {
          type: CARIPLO_REFERRAL,
          code: referralCodeFromUrl,
        }
      }


      const res = await upsertSchool({ student_id: studentInfo?.id, application_id: studentApplicationInfo?.id, origin: origin, ...studentApplicationInfo.student_school }, referral);
      if (res.status === "OK") {


        // let schools = studentInfoFromApplication?.schools || [];
        // let schoolResponse = res?.student_school;
        // let gotIt = false;
        // schools = schools.map((s: any) => {

        //   const isSameSchool =
        //     (s.id || s.school_id) === schoolResponse?.["school_id"]; // will not check dataType here
        //   if (isSameSchool) {
        //     gotIt = true;
        //   }
        //   return isSameSchool ? { ...s, pivot: schoolResponse } : s;
        // });
        // if (!gotIt) {
        //   schools.unshift({ pivot: schoolResponse });
        // }

        // if (res.student_application !== undefined) {//it's a creation
        //   res.student_application.student.schools = schools;
        //   props.onStudentApplicationInfoChange(res.student_application);


        //   //download new access requirement
        //   const res2 = await getLastAccessRequirementInformation(studentInfo?.uuid, res.student_application?.id)
        //   props.onAccessRequirementInfoChange(res2.access_requirement);

        // } else {
        //   props.onStudentApplicationInfoChange({
        //     ...studentApplicationInfo,
        //     student: { ...studentApplicationInfo.student, 'schools': schools },
        //   });

        //   //download new access requirement
        //   const res2 = await getLastAccessRequirementInformation(studentInfo?.uuid, studentApplicationInfo?.id)
        //   props.onAccessRequirementInfoChange(res2.access_requirement);

        // }

        props.onStudentApplicationInfoChange(res.student_application);


        //download new access requirement
        const res2 = await getLastAccessRequirementInformation(studentInfo?.uuid, res.student_application?.uuid)
        props.onAccessRequirementInfoChange(res2.access_requirement);

      } else {
        console.log("Something went wrong...", res);
        console.log('courseType', courseType);
        console.log('schoolId', schoolId);
        console.log('degreeClassId', degreeClassId);
        console.log('schoolCourseId', schoolCourseId);
      }

      props.onSaveAndContinue();
      setTimeout(function () {
        setIsLoading(false);
      }, 1500);

    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }



    // setIsLoading(true);


  };

  const onFieldValueChange = (key: string) => (id: string) =>
    updateData(key, id, undefined, undefined);

  const onPossibleNewFieldValueChange = (key: string) => (id: string, label: string | undefined, isNew: boolean) =>
    updateData(key, id, label, isNew);

  const COMMON_ROW_CLASS = "lg:flex mb-1 lg:flex-wrap grey";



  const renderBlock = (component: React.ReactNode, isFirst?: boolean) => (
    <div
      className={`lg:w-1/2 md:w-full sm:w-full ${isFirst ? "" : "lg:pl-16"}`}
    >
      {component}
    </div>
  );

  const renderSchoolSelect = (
    key: string,
    // options: IdLabel[],
    label?: string
  ) => (
    <SchoolSelect
      value={studentSchool?.[key]}
      label={label}
      // options={options}
      onChange={onPossibleNewFieldValueChange(key)}
      errorMessage={invalidFields?.[key]}
      course_type={schoolId !== null && schoolId !== undefined ? courseType : undefined}
      isDisabled={false}
    />
  );

  const renderCourseTypologySelect = (
    key: string,
    options: IdLabel[],
    label?: string
  ) => (
    <CourseTypologySelect
      value={studentSchool?.[key]}
      label={label}
      errorMessage={invalidFields?.[key]}
      onChange={onFieldValueChange(key)}
      schoolId={schoolId}
      isDisabled={false}
    />
  );

  const renderDegreeSelect = (
    key: string,
    // options: IdLabel[],
    label?: string
  ) => (
    <DegreeSelect
      value={studentSchool?.[key]}
      label={label}
      // options={options}
      onChange={onPossibleNewFieldValueChange(key)}
      errorMessage={invalidFields?.[key]}
      course_type={courseType}
      schoolId={schoolId}
      isDisabled={courseType === null || courseType === undefined || courseType.trim() === '' || schoolId === null || schoolId === undefined || schoolId.toString().trim() === ''}
    />
  );

  const renderCourseSelect = (
    key: string,
    // options: IdLabel[],
    label?: string
  ) => (
    <CourseSelect
      value={studentSchool?.[key]}
      label={label}
      // options={options}
      onChange={onPossibleNewFieldValueChange(key)}
      errorMessage={invalidFields?.[key]}
      course_type={courseType}
      schoolId={schoolId}
      degreeClassId={degreeClassId}
      isDisabled={courseType === null || courseType === undefined || courseType.trim() === '' || degreeClassId === null || degreeClassId === undefined || degreeClassId.toString().trim() === '' || schoolId === null || schoolId === undefined || schoolId.toString().trim() === ''}

    />


  );


  // const renderAdvancedSingleSelector = (
  //   key: string,
  //   options: IdLabel[],
  //   label?: string
  // ) => (
  //   <AdvancedSingleSelect
  //     value={schoolData?.[key]}
  //     label={label}
  //     options={options}
  //     errorMessage={invalidFields?.[key]}
  //     onChange={onFieldValueChange(key)}
  //   />
  // );


  const renderTextField = (key: string, label?: string) => (
    <TextInput
      label={label}
      value={studentSchool?.[key]}
      invalid={Boolean(invalidFields?.[key])}
      invalidMessage={invalidFields?.[key]}
      onChange={onFieldValueChange(key)}
    />
  );

  const isToShowDegreeClass = (): Boolean => {
    return courseType !== undefined && courseType !== null
      && (
        courseType === 'LT' ||
        courseType === 'LM' ||
        courseType === 'NEW-LM' ||
        courseType === 'LMCU' ||
        // courseType === 'Master-I-Livello' ||
        // courseType === 'Master-II-Livello' ||
        // courseType === 'PhD' ||
        // courseType === 'Master Estero' ||
        // courseType === 'Scuola-Specializzazione' ||
        courseType === 'Erasmus' //||
        // courseType === 'Laurea Estero'
      );
  }

  const isToShowSchoolCourse = (): Boolean => {
    return courseType !== undefined && courseType !== null
      && (
        courseType === 'LT' ||
        courseType === 'LM' ||
        courseType === 'NEW-LM' ||
        courseType === 'LMCU' ||
        // courseType === 'Master-I-Livello' ||
        // courseType === 'Master-II-Livello' ||
        // courseType === 'PhD' ||
        // courseType === 'Master Estero' ||
        // courseType === 'Scuola-Specializzazione' ||
        courseType === 'Erasmus' //||
        // courseType === 'Laurea Estero'
      );
  }

  const isToShowMatricola = (): Boolean => {
    return courseType !== undefined && courseType !== null
      && (
        courseType === 'LT' ||
        courseType === 'LM' ||
        courseType === 'NEW-LM' ||
        courseType === 'LMCU' ||
        courseType === 'Master-I-Livello' ||
        courseType === 'Master-II-Livello' ||
        courseType === 'PhD' ||
        courseType === 'Master Estero' ||
        courseType === 'Scuola-Specializzazione' ||
        courseType === 'Erasmus' ||
        courseType === 'Corso SSML' ||
        courseType === 'Laurea Estero'
      );
  }

  // const styleNumber = "text-align: center;";

  const numberLabel = useIntl().formatMessage({ id: "academicDataPage.number" });

  const degreeClassLabel = useIntl().formatMessage({ id: "academicDataPage.degree_class_id" });
  const schoolCourseLabel = useIntl().formatMessage({ id: "academicDataPage.school_course_id" });

  return (
    <div className="relative">
      <ConditionalLoader isLoading={isLoading} />
      <div className={getDataSubmitStateClass(isLoading)}>

        <div className="text-4xl font-600 mb-15 mt-20">
          <FormattedMessage id="academicDataPage.block1.title" />
        </div>

        <div className="text-2xl mb-15">
          <FormattedMessage id="academicDataPage.text" />
        </div>
        <div className="text-2xl mb-15">
          <FormattedMessage id="academicDataPage.text2" />
        </div> <br />




        <div className={COMMON_ROW_CLASS}>
          {renderBlock(
            renderSchoolSelect(
              "school_id",
              // metadata?.schools as IdLabel[],
              useIntl().formatMessage({ id: "academicDataPage.school_id" })
            ), true
          )}
          {renderBlock(
            renderCourseTypologySelect(
              "course_type",
              metadata?.courseTypes as IdLabel[],
              useIntl().formatMessage({ id: "academicDataPage.course_type" })
            )
          )}
        </div>
        {/* <div className="invisible_div"></div> */}

        <div className={COMMON_ROW_CLASS}>
          {
            !isToShowDegreeClass() ? null :
              renderBlock(
                renderDegreeSelect(
                  "degree_class_id",
                  degreeClassLabel,
                ),
                true)
          }

          {
            !isToShowSchoolCourse() ? null :
              renderBlock(
                renderCourseSelect(
                  "school_course_id",
                  schoolCourseLabel,
                )
              )
          }

        </div>
        <div className={COMMON_ROW_CLASS}>
          {
            !isToShowMatricola() ? null :
              renderBlock(
                renderTextField("number", numberLabel), true
              )
          }
        </div>


        <div className="mt-30 buttonsGoToTheRight">
          {/* <Button
            disabled={isLoading}
            label={useIntl().formatMessage({ id: "button.save&continue" })}
            variant="habacus"
            onClick={onSaveAndContinueClick}
          /> */}
          <ButtonSave
            disabled={isLoading}
            label1={useIntl().formatMessage({ id: "button.saveAndGoOn1" })}
            label2={useIntl().formatMessage({ id: "button.saveAndGoOn2" })}
            variant="flexButtSave"
            onClick={onSaveAndContinueClick}
          />
        </div>
      </div>
    </div >
  );


};

export default AcademicDataPage;
