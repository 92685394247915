import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AlertProxyModal from "app/components/common/alertProxyModal";
import ButtonSave from 'app/components/common/buttonSave';
import GroupFileUpload from "app/components/common/groupFileUpload";
import ConditionalLoader from "app/components/common/loader/conditionalLoader";
import { getFileDownload, uploadStudentApplicationDocumentsRequest } from "app/service/apiService";
import { DOCUMENTS_OPTIONS_LABEL } from "app/utils/documents";
import { MyFile } from "app/utils/imagesToPdf";
import { DUMMY_LOADER_TIME, getDataSubmitStateClass } from "app/utils/utils";
import clsx from "clsx";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";



interface Props {
  studentInfo: any;
  studentApplicationInfo: any;
  onStudentInfoChange(data: object): void;
  onStudentApplicationInfoChange(data: object): void;
  onSaveAndContinue(): void;
}

const OPTIONS_GROUP_OPTIONS_IDS = {
  main: [
    // "identity_document",
    // "sanitary_card",
    "proxy"
  ],
};

// const OPTIONS_GROUP = {
//   main: [
//     // {
//     //   id: "identity_document",
//     //   label: "caricamento fronte/retro della carta d'identità",
//     // },
//     // {
//     //   id: "sanitary_card",
//     //   label: "caricamento fronte/retro della tessera sanitaria",
//     // },
//     {
//       id: "proxy",
//       label: "caricamento delega"
//     },
//   ],
// };

// const OPTIONS_GROUP_LABEL = {
//   main: "Per i possessori della carta d'identità",
// };

interface DocumentDictionary {
  [type: string]: MyFile;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      // border: '5px solid #036ED9',
      border: '3px inset #036ED9',
      width: '90%',
      borderRadius: '5px',
      //backgroundImage: 'linear-gradient(135deg, #036ED9, #00FFC6)',   
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: 'scroll',
    },

    button: {
      borderRadius: '20px',
      border: '1px solid #003da6',
      background: '#fff',
    },
    linkToFile: {
      color: '#003da6',
      textDecoration: 'none',
      background: '#fff'


    }
  }),
);

const UploadProxyComponent: React.FC<Props> = (props: Props) => {

  const { studentInfo, studentApplicationInfo } = props;

  const alreadyUploadedStudentDocuments = studentInfo?.media?.filter((el: any) => el.status != 'REJ');
  const alreadyUploadedStudentApplicationDocuments = studentApplicationInfo?.media?.filter((el: any) => el.status != 'REJ');


  const [isDownloadEnabled, setIsDownloadEnabled] = React.useState<boolean>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [documents, setDocuments] = React.useState<DocumentDictionary>({});
  const [errorMap, setError] = React.useState<any>();

  const [isAlertProxyModalOpen, setIsAlertProxyModalOpen] = React.useState<boolean>(false);

  // const [isProxyUploaded, setIsProxyUploaded] = React.useState<boolean>(true);

  const classes = useStyles();

  React.useEffect(() => {
    if (!studentInfo?.id) return;
    setIsDownloadEnabled(true);
  }, [studentInfo?.id]);

  // const onSaveAndContinue = () => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     props.onSaveAndContinue();
  //   }, DUMMY_LOADER_TIME);
  // };

  const renderSection = (label: string, value?: string, onClick?: () => void) => {
    return (
      <div key={`${label}_${value}`} onClick={onClick} className="center-y pr-5 mb-1">
        <div className="text-md font-500">{label}:</div>
        <div className="ml-10">{value || "-"}</div>
      </div>
    );
  };

  const removeDocument = (type: string) => {
    const { [type]: deleted, ...objectWithoutDeletedProp } = documents;
    setDocuments(objectWithoutDeletedProp);
  }

  const addDocument = (file: MyFile, type: string) => {
    setDocuments(Object.assign({ [type]: file }, documents));
  }

  // const renderBlock = (component: React.ReactNode, isFirst?: boolean) => (
  //   <div
  //     className={`xl:w-full lg:w-full md:w-full sm:w-full selectDocToUpload ${isFirst ? "" : "lg:pl-16"
  //       } sm:border-bottom-1 lg:flex items-center`}
  //   >
  //     {component}
  //   </div>
  // );

  const uploadDocuments = () => {
    setIsLoading(true);
    const documentDataMapByType = {} as any;
    Object.entries(documents).forEach(([type, document]) => {
      if (!type) return;
      documentDataMapByType[type] = document.file;
    });

    uploadStudentApplicationDocumentsRequest({

      student_id: props.studentInfo?.id,
      application_id: props.studentApplicationInfo?.id,

      ...documentDataMapByType,
    })
      .then((res: any) => {
        if (res.status === "OK") {
          const updatedMedia = res.student_application?.media || alreadyUploadedStudentApplicationDocuments; // if not received then fallback to alreadyUploadedDocuments
          props.onStudentApplicationInfoChange({
            ...props.studentApplicationInfo,
            media: updatedMedia,
          });
          setTimeout(() => {
            setIsLoading(false);
            props.onSaveAndContinue();
          }, DUMMY_LOADER_TIME);
        } else {
          const messages = res.messages;

          if (Array.isArray(messages)) {
            setError({ other: messages[0] });
          } else {
            const errorMapByType = {};
            Object.keys(messages || {}).forEach((key: string) => {
              const errors = messages[key];
              errorMapByType[key] = errors[0];
            });

            // Object.values(documents).forEach((data) => {
            //   if (!data.documentData && !errorMapByType[data.type]) {
            //     errorMapByType[data.type] = "Please upload";
            //   }
            // });

            setError(
              Object.keys(errorMapByType).length ? errorMapByType : undefined
            );
          }

          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError({ other: "Couldn't upload documents" });
      });
  };

  const checkForProxy = () => {
    setIsLoading(true);

    let hasRequiredDocuments = false;

    let requiredDocuments = 0;

    alreadyUploadedStudentApplicationDocuments?.forEach((d: any) => {
      if (OPTIONS_GROUP_OPTIONS_IDS.main.includes(d["collection_name"])) {
        requiredDocuments++;
      }
    });

    Object.keys(documents ?? {}).forEach((doc) => {
      if (OPTIONS_GROUP_OPTIONS_IDS.main.includes(doc)) {
        requiredDocuments++;
      }
    });


    if (requiredDocuments === OPTIONS_GROUP_OPTIONS_IDS.main.length) {
      hasRequiredDocuments = true;
    }

    if (hasRequiredDocuments) {
      uploadDocuments();
    } else {
      setIsAlertProxyModalOpen(true);
    }

    setTimeout(function () {
      setIsLoading(false);
    }, 1500);


  }



  const handleClose = (result: boolean) => {

    setIsAlertProxyModalOpen(false);

    if (result === true) {
      uploadDocuments();
    }
  };


  const renderAlreadyUploadedDocuments = () => {
    if (alreadyUploadedStudentApplicationDocuments?.length === 0) return null;
    return (
      <div
        className="my-1 border-1 br-4 border-solid p-2 mb-15"
        style={{ borderColor: "var(--grey-dark)" }}
      >
        <div className="pb-2 px-0 text-lg font-600">
          <FormattedMessage id="added.docs" />
        </div>
        <div>
          {alreadyUploadedStudentApplicationDocuments?.map((doc: any) =>
            renderSection(
              DOCUMENTS_OPTIONS_LABEL[doc["collection_name"]],
              doc["file_name"]
            )
          )}
        </div>
      </div>
    );
  };

  const renderUploadingDocuments = () => {
    if (!Object.keys(documents ?? {})?.length) return null;
    return (
      <div
        className="mt-3 my-1 border-1 br-4 border-solid p-2 mb-15"
        style={{ borderColor: "var(--grey-dark)" }}
      >
        <div className="pb-2 px-0 text-lg font-600">
          <FormattedMessage id="uploaded.docs" />

        </div>
        <div>
          {Object.entries(documents ?? {}).map(([key, value]) =>
            renderSection(
              DOCUMENTS_OPTIONS_LABEL[key],
              value.file.name,
              () => window.open(value.dataURL, "_blank"),
            )
          )}
        </div>
      </div>
    )
  };

  const getDownloadProxyName = (pre: string) => {

    const nameBeautified = studentInfo.name.substring(0, 1);
    const surnameBeautified = studentInfo.surname.substring(0, 1);
    const timestampBeautified = new Date().toISOString().split('T')[0];

    return pre + '_' + nameBeautified + surnameBeautified + "_" + timestampBeautified + ".pdf";


  }

  const downloadProxy = async () => {

    const link = document.createElement("a");
    link.target = "_blank";
    link.rel = "noreferrer";


    link.download = getDownloadProxyName("proxy_a");

    link.href = URL.createObjectURL(await getFileDownload(studentInfo.id, studentApplicationInfo.id));
    link.click();
    link.remove();
  }

  const downloadDefaultProxy = async () => {

    let isThisSchoolItalian = 1;
    if (studentApplicationInfo?.student_school?.school?.is_italian) {
      isThisSchoolItalian = studentApplicationInfo?.student_school?.school?.is_italian;
    }

    const link = document.createElement("a");
    link.target = "_blank";
    link.rel = "noreferrer";

    if (isThisSchoolItalian === 1) {
      link.download = getDownloadProxyName("delega_habacus");
      link.href = 'default_proxies/20211123_h_ita.pdf';
    } else if (isThisSchoolItalian === 0) {
      link.download = getDownloadProxyName("proxy_habacus");
      link.href = 'default_proxies/20211123_h_eng.pdf';
    }

    link.click();
    link.remove();
  }


  const DE_STRING = useIntl().formatMessage({ id: "uploadDocuments.DE" });
  const locale = useIntl().locale === 'IT' ? 'IT' : 'EN';


  return (
    <div className="relative">
      <div className="proxyPopUp">
        <AlertProxyModal
          isAlertProxyOpen={isAlertProxyModalOpen}
          handleClose={handleClose}
        // isProxyUploaded={isProxyUploaded}
        ></AlertProxyModal>
      </div>

      <ConditionalLoader isLoading={isLoading} />
      <div className={getDataSubmitStateClass(isLoading)}>

        {/* start Download Proxy */}
        <div className="mb-30 mt-30">
          <div className="text-4xl font-600 mb-15 mt-20">
            <FormattedMessage id="uploadProxy.title" />
          </div>
          <div className="text-2xl">
            <FormattedMessage id="uploadProxy.text1" />
          </div>
          <div className="text-2xl">
            <FormattedMessage id="uploadProxy.text2" />
          </div><br />
          <div className="text-2xl">
            <FormattedMessage id="uploadProxy.text3" />
          </div><br />

          <div className="text-2xl">
            <FormattedMessage id="uploadProxy.privacy1" />
            {/* <a href={"https://www.habacus.com/" + locale.toLocaleLowerCase() + "/privacy"} target="_blank" rel="noreferrer"> */}
            <a className={classes.linkToFile} href={"/attachments/AnnexA_" + locale + ".pdf"} download>
              <FormattedMessage id="uploadProxy.privacy2" />
            </a>.

          </div>
          <br />
          <div className="buttonProxyContainer row">
            <div className="buttonProxyFrame col-12 col-lg-6 offset-lg-3">
              <button
                className={clsx(
                  isDownloadEnabled ? "" : "disable-actions",
                  "btn btn-primary buttonProxy"
                )}
                onClick={downloadProxy}
              >
                <FormattedMessage id="uploadProxy.download" />
              </button>
            </div>
            {
              (studentApplicationInfo.origin === 'INTESA_PERMERITO' || studentApplicationInfo.origin === 'INTESA_STUDIOSI')
                ? null
                : <p className="col-6 offset-3 defaultProxy" id="downloadDefaultProxy" onClick={downloadDefaultProxy}>
                  <FormattedMessage id="uploadDefaultProxy.download" />
                </p>
            }
          </div>
        </div>

        {/* end Download Proxy */}

        {/* end List of what to upload */}

        {renderAlreadyUploadedDocuments()}

        {renderUploadingDocuments()}

        {/* Render Proxy if not already uploaded */}
        {
          alreadyUploadedStudentApplicationDocuments?.filter((doc: any) => doc["collection_name"] === 'proxy').length > 0
            ? null
            : <div className="mt-30">
              <GroupFileUpload
                name={DE_STRING}
                type="proxy"
                statusRej={studentApplicationInfo?.media?.find((doc: any) => doc["collection_name"] === 'proxy')?.status == 'REJ'}
                prevError={studentApplicationInfo?.media?.find((doc: any) => doc["collection_name"] === 'proxy')?.status_reason}
                onRemoveDocument={removeDocument}
                onAddDocument={addDocument}
                fileCount={studentApplicationInfo.origin === 'INTESA_PERMERITO' || studentApplicationInfo.origin === 'INTESA_STUDIOSI' ? 1 : 3}
              />
              <div className="borderUpload"></div>


            </div>
        }

        {errorMap?.other && (
          <div className="text-danger font-500 mt-1">{errorMap?.other}</div>
        )}
        {
          Array.isArray(errorMap)
            ? <div className="text-danger font-500 mt-1">{errorMap[0]}</div>
            : null
        }
        <div className="mt-30 buttonsGoToTheRight">
          {/* <Button
            label={useIntl().formatMessage({ id: "button.save&continue" })}
            variant="primary"
            onClick={checkForProxy}//onSaveAndContinue
          /> */}

          <ButtonSave
            disabled={isLoading}
            label1={useIntl().formatMessage({ id: "button.saveAndGoOn1" })}
            label2={useIntl().formatMessage({ id: "button.saveAndGoOn2" })}
            variant="flexButtSave"
            onClick={checkForProxy}
          />

        </div>
      </div>
    </div>
  );
};

export default UploadProxyComponent;
