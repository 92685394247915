import jsPDF from "jspdf";

export class CustomImage extends Image {
    constructor(public mimeType: string) {
        super();
    }

    get imageType(): string {
        return this.mimeType.split("/")[1];
    }
}

// The dimensions are in millimeters.
const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 297,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

interface ImageDimension {
    width: number;
    height: number;
}

export const imageDimensionsOnA4 = (dimensions: ImageDimension) => {
    const isLandscapeImage = dimensions.width >= dimensions.height;

    if (isLandscapeImage) {
        return {
            width: A4_PAPER_DIMENSIONS.width,
            height:
                A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
        };
    }

    const imageRatio = dimensions.width / dimensions.height;
    if (imageRatio > A4_PAPER_RATIO) {
        const imageScaleFactor =
            (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

        const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

        return {
            height: scaledImageHeight,
            width: scaledImageHeight * imageRatio,
        };
    }

    return {
        width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
        height: A4_PAPER_DIMENSIONS.height,
    };
};

export const fileToImageURL = (file: File): Promise<CustomImage> => {
    return new Promise((resolve, reject) => {
        const image = new CustomImage(file.type);

        image.onload = () => {
            resolve(image);
        };

        image.onerror = () => {
            reject(new Error("Failed to convert File to Image"));
        };

        image.src = URL.createObjectURL(file);
    });
};

export const fileToDataURL = (file: File): Promise<string> => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = (e: ProgressEvent<FileReader>) => {
            var dataURL = e.target?.result;
            if (typeof dataURL === 'string') {
                dataURL = dataURL?.replace(";base64", ';name=' + file.name + ';base64');
            }
            resolve(dataURL as string);
        };
        reader.readAsDataURL(file);
    });
}

export interface MyFile {
    file: File;
    dataURL: string;
}

export const blobToFile = (blob: Blob, fileName: string): File => {
    var b: any = blob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName + ".pdf";

    //Cast to a File() type
    return blob as File;
}

export const generatePdfFromImages = (images: CustomImage[], fileName: string): File => {
    const doc = new jsPDF();
    doc.deletePage(1);

    images.forEach((image) => {
        const imageDimensions = imageDimensionsOnA4({
            width: image.width,
            height: image.height,
        });

        doc.addPage();
        doc.addImage(
            image.src,
            image.imageType,
            (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
            (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
            imageDimensions.width,
            imageDimensions.height,
        );
    });

    //return doc.output("dataurlstring", { filename: ( name +'.pdf') });
    return blobToFile(doc.output('blob'), fileName);
    // const pdfURL = doc.output("bloburl");
    // window.open(pdfURL as any, "_blank");
};

export const fileCreationFromURL = (inputURI: string, fileName: string): File => {
    const blob = blobCreationFromURL(inputURI);

    return blobToFile(blob, fileName);

}


export const blobCreationFromURL = (inputURI: string): Blob => {

    let binaryVal;

    // mime extension extraction
    const inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0];

    // Extract remaining part of URL and convert it to binary value
    if (inputURI.split(',')[0].indexOf('base64') >= 0)
        binaryVal = atob(inputURI.split(',')[1]);

    // Decoding of base64 encoded string
    else
        binaryVal = unescape(inputURI.split(',')[1]);

    // Computation of new string in which hexadecimal
    // escape sequences are replaced by the character 
    // it represents

    // Store the bytes of the string to a typed array
    const blobArray: BlobPart[] = [];
    for (var index = 0; index < binaryVal.length; index++) {
        blobArray.push(binaryVal.charCodeAt(index) + '');
    }

    return new Blob(blobArray, {
        type: inputMIME
    });
}
