import StepperComponent from "app/components/common/stepperComponent";
import { OrientationPage } from "app/components/userInformation/stepsComponent/step1";
import { PersonalDataPage } from "app/components/userInformation/stepsComponent/step2";
import { AcademicDataPage } from "app/components/userInformation/stepsComponent/step2b";
import StudentInfoContext from "app/context/studentContext";
import { ORIGIN_TOKEN_PARAM_NAME, POSTE_REFERRAL } from "app/context/studentInfoProvider";
import CheckedIcon from "app/icons/checked.svg";
import { StepObject } from "app/types/stepper";
import {
  getQueryParam
} from "app/utils/utils";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RecapComponent from "./stepsComponent/recapComponent";
import { UploadAccessRequirementDocumentsComponent, UploadDocumentsComponent, UploadProxyComponent } from "./stepsComponent/step3";
import StepComponent from "./stepsComponent/stepComponent";


interface Props {
  onSetStepNumber(stepNumber: number): void;
  onSetCurrentStepNumber(currentStepNumber: number): void;
  onSetStepperVisible(isVisible: boolean): void;
}


const UserInformation: React.FC<Props> = (props: Props) => {

  const { student, studentApplication, isCreationMode, accessRequirement } = React.useContext(StudentInfoContext);
  const { onSetCurrentStepNumber, onSetStepNumber } = props;
  const tokenOriginfromUrl = getQueryParam(ORIGIN_TOKEN_PARAM_NAME);

  let ALL_STEPS: string | any[];

  switch (tokenOriginfromUrl) {
    case POSTE_REFERRAL:
      ALL_STEPS = [

        //ORIENTATION STEP - TEST MOTIVA
        {
          label: "horizontalStepper.step1",
          sutitle: "horizontalStepper.subtitle.step1",
          isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
            return student && student.typology !== 'actual';
          },
          children: [
            {
              component: OrientationPage,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
            },
          ],
          allowBackward: false,
          allowForward: false,
        },
        //PESONAL STEP - 1.user info 2.ambassador
        {
          label: "horizontalStepper.step2",
          subtitle: "horizontalStepper.subtitle.step2",
          isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
            return student && student.typology !== 'actual';
          },
          children: [
            {
              component: PersonalDataPage,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
            },
          ],
          allowBackward: true,
          allowForward: false,
        },
        //ACADEMIC PAGE
        {
          label: "horizontalStepper.step2b",
          isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
            console.log(studentApplication);

            return studentApplication && studentApplication?.typology !== 'actual';
          },
          children: [
            {
              component: AcademicDataPage,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
            }
          ],
          allowBackward: true,
          allowForward: false,
        },

        //DOCUMENTS PAGE 
        {
          label: "horizontalStepper.step3",
          subtitle: "horizontalStepper.subtitle.step3",
          isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
            return (student && student?.typology !== 'actual')
              || (studentApplication && studentApplication?.typology !== 'actual')
              || accessRequirement?.completed_at === null;
          },
          children: [
            {
              component: UploadDocumentsComponent,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
                return student && student?.typology !== 'actual';
              }
            },
            {
              component: UploadAccessRequirementDocumentsComponent,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
                return studentApplication &&
                  (
                    (accessRequirement?.is_first === 1)
                      ? (
                        // studentApplication?.origin === 'WELFARE_POSTEITALIANE' ||
                        // studentApplication?.origin === 'INTESA_STUDIOSI' ||
                        // studentApplication?.origin === 'ONBOARDING' ||
                        // studentApplication?.origin === 'ONBOARDING_LIGHT' ||
                        // studentApplication?.origin === 'ZOPPAS_BORSE_ITS' ||
                        // studentApplication?.origin === 'CARIPLO_BORSE_ITS' ||
                        // studentApplication?.origin === 'REFERRAL_AMBASSADOR' ||
                        // (
                        //   studentApplication?.origin === 'INTESA_PERMERITO' &&
                        //   moment.duration((moment(new Date())).diff(moment(studentApplication?.created_at))).asDays() > 30 &&
                        //   (studentApplication?.cod_status === 'PN' || studentApplication?.cod_status === 'PWD')
                        // )
                        true
                      )
                      : (studentApplication?.origin === 'INTESA_STUDIOSI' || studentApplication?.origin === 'INTESA_PERMERITO')
                  );
              }
            }
          ],
          allowBackward: true,
          allowForward: false,
        },
        {
          label: "horizontalStepper.step4",
          isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
            return true;
          },
          children: [
            {
              component: RecapComponent,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
            }
          ],
          allowBackward: true,
          allowForward: false,
        },
      ];

      break;

    default:

      ALL_STEPS = [
        //PESONAL STEP - 1.user info 2.ambassador
        {
          label: "horizontalStepper.step2",
          subtitle: "horizontalStepper.subtitle.step2",
          isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
            return student && student.typology !== 'actual';
          },
          children: [
            {
              component: PersonalDataPage,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
            },
            // {
            //   component: AmbassadorPage,
            //   isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return isCreationMode === true; }
            // },
          ],
          allowBackward: true,
          allowForward: false,
        },
        //ACADEMIC PAGE
        {
          label: "horizontalStepper.step2b",
          isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
            return studentApplication && studentApplication?.typology !== 'actual';
          },
          children: [
            {
              component: AcademicDataPage,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
            }
          ],
          allowBackward: true,
          allowForward: false,
        },
        //DOCUMENTS PAGE 
        {
          label: "horizontalStepper.step3",
          subtitle: "horizontalStepper.subtitle.step3",
          isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
            return (student && student?.typology !== 'actual')
              || (studentApplication && studentApplication?.typology !== 'actual')
              || accessRequirement?.completed_at === null;
          },
          children: [
            {
              component: UploadDocumentsComponent,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
                return student && student?.typology !== 'actual';
              }
            },
            {
              component: UploadAccessRequirementDocumentsComponent,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
                return studentApplication &&
                  ((accessRequirement?.is_first === 1) ? (
                    // studentApplication?.origin === 'WELFARE_POSTEITALIANE' ||
                    // studentApplication?.origin === 'INTESA_STUDIOSI' ||
                    // studentApplication?.origin === 'ONBOARDING' ||
                    // studentApplication?.origin === 'ONBOARDING_LIGHT' ||
                    // studentApplication?.origin === 'ZOPPAS_BORSE_ITS' ||
                    // studentApplication?.origin === 'CARIPLO_BORSE_ITS' ||
                    // studentApplication?.origin === 'REFERRAL_AMBASSADOR' ||
                    // (
                    //   studentApplication?.origin === 'INTESA_PERMERITO' &&
                    //   moment.duration((moment(new Date())).diff(moment(studentApplication?.created_at))).asDays() > 30 &&
                    //   (studentApplication?.cod_status === 'PN' || studentApplication?.cod_status === 'PWD')
                    // )
                    true
                  ) : (studentApplication?.origin === 'INTESA_STUDIOSI' || studentApplication?.origin === 'INTESA_PERMERITO'));
              }
            },
            {
              component: UploadProxyComponent,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
                return studentApplication && studentApplication?.typology !== 'actual';
              }
            },
          ],
          allowBackward: true,
          allowForward: false,
        },
        {
          label: "horizontalStepper.step4",
          isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
            return true;
          },
          children: [
            {
              component: RecapComponent,
              isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
            }
          ],
          allowBackward: true,
          allowForward: false,
        },
      ];

      break;
  }

  // if (referralFromUrl === POSTE_REFERRAL) {
  //   ALL_STEPS = [

  //     //ORIENTATION STEP - TEST MOTIVA
  //     {
  //       label: "horizontalStepper.step1",
  //       sutitle: "horizontalStepper.subtitle.step1",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return student && student.typology !== 'actual';
  //       },
  //       children: [
  //         {
  //           component: OrientationPage,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
  //         },
  //       ]

  //     },
  //     //PESONAL STEP - 1.user info 2.ambassador
  //     {
  //       label: "horizontalStepper.step2",
  //       subtitle: "horizontalStepper.subtitle.step2",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return student && student.typology !== 'actual';
  //       },
  //       children: [
  //         {
  //           component: PersonalDataPage,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
  //         },
  //       ]
  //     },
  //     //DOCUMENTS PAGE 
  //     {
  //       label: "horizontalStepper.step3",
  //       subtitle: "horizontalStepper.subtitle.step3",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return (student && student?.typology !== 'actual')
  //           || (studentApplication && studentApplication?.typology !== 'actual')
  //           || accessRequirement?.completed_at === null;
  //       },
  //       children: [
  //         {
  //           component: UploadDocumentsComponent,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //             return student && student?.typology !== 'actual';
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       label: "horizontalStepper.step4",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return true;
  //       },
  //       children: [
  //         {
  //           component: RecapComponent,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
  //         }
  //       ]
  //     },
  //   ];

  // } else if (tokenPfromUrl === POSTE_REFERRAL) {
  //   ALL_STEPS = [
  //     //ACADEMIC PAGE
  //     {
  //       label: "horizontalStepper.step2b",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return studentApplication && studentApplication?.typology !== 'actual';
  //       },
  //       children: [
  //         {
  //           component: AcademicDataPage,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
  //         }
  //       ]
  //     },
  //     //DOCUMENTS PAGE 
  //     {
  //       label: "horizontalStepper.step3",
  //       subtitle: "horizontalStepper.subtitle.step3",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return (student && student?.typology !== 'actual')
  //           || (studentApplication && studentApplication?.typology !== 'actual')
  //           || accessRequirement?.completed_at === null;
  //       },
  //       children: [
  //         {
  //           component: UploadAccessRequirementDocumentsComponent,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //             return studentApplication &&
  //               ((accessRequirement?.is_first === 1) ? (
  //                 studentApplication?.origin === 'WELFARE_POSTEITALIANE' ||
  //                 studentApplication?.origin === 'INTESA_STUDIOSI' ||
  //                 studentApplication?.origin === 'ONBOARDING' ||
  //                 studentApplication?.origin === 'ONBOARDING_LIGHT' ||
  //                 studentApplication?.origin === 'REFERRAL_AMBASSADOR' ||
  //                 (
  //                   studentApplication?.origin === 'INTESA_PERMERITO' &&
  //                   moment.duration((moment(new Date())).diff(moment(studentApplication?.created_at))).asDays() > 30 &&
  //                   (studentApplication?.cod_status === 'PN' || studentApplication?.cod_status === 'PWD')
  //                 )
  //               ) : (studentApplication?.origin === 'INTESA_STUDIOSI' || studentApplication?.origin === 'INTESA_PERMERITO'));
  //           }
  //         },
  //       ]
  //     },
  //     {
  //       label: "horizontalStepper.step4",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return true;
  //       },
  //       children: [
  //         {
  //           component: RecapComponent,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
  //         }
  //       ]
  //     },
  //   ];

  // } else {
  //   ALL_STEPS = [
  //     //PESONAL STEP - 1.user info 2.ambassador
  //     {
  //       label: "horizontalStepper.step2",
  //       subtitle: "horizontalStepper.subtitle.step2",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return student && student.typology !== 'actual';
  //       },
  //       children: [
  //         {
  //           component: PersonalDataPage,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
  //         },
  //         // {
  //         //   component: AmbassadorPage,
  //         //   isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return isCreationMode === true; }
  //         // },
  //       ]
  //     },
  //     //ACADEMIC PAGE
  //     {
  //       label: "horizontalStepper.step2b",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return studentApplication && studentApplication?.typology !== 'actual';
  //       },
  //       children: [
  //         {
  //           component: AcademicDataPage,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
  //         }
  //       ]
  //     },
  //     //DOCUMENTS PAGE 
  //     {
  //       label: "horizontalStepper.step3",
  //       subtitle: "horizontalStepper.subtitle.step3",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return (student && student?.typology !== 'actual')
  //           || (studentApplication && studentApplication?.typology !== 'actual')
  //           || accessRequirement?.completed_at === null;
  //       },
  //       children: [
  //         {
  //           component: UploadDocumentsComponent,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //             return student && student?.typology !== 'actual';
  //           }
  //         },
  //         {
  //           component: UploadAccessRequirementDocumentsComponent,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //             return studentApplication &&
  //               ((accessRequirement?.is_first === 1) ? (
  //                 studentApplication?.origin === 'WELFARE_POSTEITALIANE' ||
  //                 studentApplication?.origin === 'INTESA_STUDIOSI' ||
  //                 studentApplication?.origin === 'ONBOARDING' ||
  //                 studentApplication?.origin === 'ONBOARDING_LIGHT' ||
  //                 studentApplication?.origin === 'ZOPPAS_BORSE_ITS' ||
  //                 studentApplication?.origin === 'REFERRAL_AMBASSADOR' ||
  //                 (
  //                   studentApplication?.origin === 'INTESA_PERMERITO' &&
  //                   moment.duration((moment(new Date())).diff(moment(studentApplication?.created_at))).asDays() > 30 &&
  //                   (studentApplication?.cod_status === 'PN' || studentApplication?.cod_status === 'PWD')
  //                 )
  //               ) : (studentApplication?.origin === 'INTESA_STUDIOSI' || studentApplication?.origin === 'INTESA_PERMERITO'));
  //           }
  //         },
  //         {
  //           component: UploadProxyComponent,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //             return studentApplication && studentApplication?.typology !== 'actual';
  //           }
  //         },
  //       ]
  //     },
  //     {
  //       label: "horizontalStepper.step4",
  //       isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => {
  //         return true;
  //       },
  //       children: [
  //         {
  //           component: RecapComponent,
  //           isToShow: (student: any, studentApplication: any, accessRequirement: any, isCreationMode: any): boolean => { return true; }
  //         }
  //       ]
  //     },
  //   ];
  // }

  const [studentInfo, setStudentInfo] = React.useState<any>(student);

  const [studentApplicationInfo, setStudentApplicationInfo] = React.useState<any>(studentApplication);

  const [accessRequirementInfo, setAccessRequirementInfo] = React.useState<any>(accessRequirement);


  const [activeStep, setActiveStep] = React.useState<number>(0);

  useEffect(() => {
    onSetCurrentStepNumber(activeStep);

  }, [onSetCurrentStepNumber, activeStep]);


  const onStudentInfoChange = (data: object) => setStudentInfo(data);
  const onStudentApplicationInfoChange = (data: object) => setStudentApplicationInfo(data);

  const onAccessRequirementInfoChange = (data: object) => setAccessRequirementInfo(data);

  const navigate = useNavigate();

  const onThankYou = () => {
    navigate("/thank-you");
  };

  const onUpdateActiveStep = (nextStep: number) => () => {

    if (nextStep < (STEPS.length)) {
      setActiveStep(nextStep);
      props.onSetCurrentStepNumber(nextStep);

    } else {
      onThankYou();
    }

  };

  const STEPS: StepObject[] = React.useMemo(() => {

    const returnValue: StepObject[] = [];

    let cont = 0;
    for (let i = 0; i < ALL_STEPS.length; i++) {
      const step = ALL_STEPS[i];
      if (step.isToShow(studentInfo, studentApplicationInfo, accessRequirementInfo, isCreationMode)) {
        returnValue.push({
          ...step,
          id: cont,
          icon: cont < activeStep ? CheckedIcon : null,

          // icon: cont <= activeStep ? CircleIcon : null,
        });
        cont++;
      }
    }

    onSetStepNumber(returnValue.length);

    return returnValue;
  }, [activeStep, onSetStepNumber, studentInfo, studentApplicationInfo, isCreationMode, ALL_STEPS,
    accessRequirementInfo]);

  const renderStepComponent = () => {


    return (
      <StepComponent
        step={STEPS[activeStep]}
        studentInfo={studentInfo}
        studentApplicationInfo={studentApplicationInfo}
        accessRequirementInfo={accessRequirementInfo}

        onStudentInfoChange={onStudentInfoChange}
        onStudentApplicationInfoChange={onStudentApplicationInfoChange}
        onAccessRequirementInfoChange={onAccessRequirementInfoChange}
        onUpdateStep={onUpdateActiveStep(activeStep + 1)}
        isCreationMode={isCreationMode}

      />
    );

    // switch (activeStep) {
    //   case 0:
    //   case 1:
    //   case 2:

    // return (
    //   <StepComponent2
    //     studentInfo={studentInfo}
    //     onStudentInfoChange={onStudentInfoChange}
    //     onUpdateStep={onUpdateActiveStep(2)}

    //   />);
    // return (
    //   <StepComponent
    //     id={STEPS[activeStep].id}
    //     subtitle={STEPS[activeStep].subtitle}
    //     children={STEPS[activeStep].children}

    //     studentInfo={studentInfo}
    //     studentApplicationInfo={studentApplicationInfo}

    //     onStudentInfoChange={onStudentInfoChange}
    //     onStudentApplicationInfoChange={onStudentApplicationInfoChange}
    //     onUpdateStep={onUpdateActiveStep(activeStep + 1)}

    //   />);

    // case 1:
    //   return (
    //     <StepComponent2b
    //       studentInfo={studentInfo}
    //       studentApplicationInfo={studentApplicationInfo}
    //       onStudentInfoChange={onStudentInfoChange}
    //       onStudentApplicationInfoChange={onStudentApplicationInfoChange}
    //       onUpdateStep={onUpdateActiveStep(2)}
    //     />
    //   );
    // case 2:
    //   return (
    //     <DocumentsPage
    //       studentInfo={studentInfo}
    //       studentApplicationInfo={studentApplicationInfo}
    //       onStudentInfoChange={onStudentInfoChange}
    //       onStudentApplicationInfoChange={onStudentApplicationInfoChange}
    //       onUpdateStep={onUpdateActiveStep(3)}
    //     />
    //   );
    //   case 3:
    //     return <RecapComponent
    //       studentInfo={studentInfo}
    //       studentApplicationInfo={studentApplicationInfo}
    //     />;
    //   default:
    //     return null;
    // }
  };

  return (
    <div>
      <div className="mb-20 overflow-auto stepperBodyWrapper">
        <StepperComponent
          onStepClick={setActiveStep}
          activeStep={activeStep}
          steps={STEPS}
          onSetStepperVisible={props.onSetStepperVisible}
        />
      </div>
      {renderStepComponent()}
    </div>
  );
};

export default UserInformation;
