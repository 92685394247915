import { getCookie, STUDENT_UUID } from "app/utils/cookies";
import axios from "axios";

const { BASE_API } = window.env;


// const isLocalhost = document.location.href.includes("localhost:");
// const isStaging = document.location.href.includes("staging-");
// const isDev = document.location.href.includes("dev-");
// export BASE_API = isLocalhost
//   ? ""
//   : (isStaging
//     ? "https://staging-hpp.habacus.com"
//     : (isDev
//       ? "https://dev-hpp.habacus.com"
//       : "https://hpp.habacus.com")
//   );


const ALLOWED_FIELDS = [
  // common
  "id",

  // information fields
  "student_id",
  "name",
  "surname",
  "gender",
  "born_at",
  "born_city",
  "born_province",
  "born_country",
  // "citizenship_ue",
  "citizenships",
  "tax_code",
  "italian_residence",
  "citizen_country",
  "citizen_province",
  "citizen_city",
  "citizen_zip",
  "citizen_address",
  "citizen_address_number",
  "phone_country_code",
  "phone",
  "email",
  "is_domicile_eq_citizenship",
  "domicile_country",
  "domicile_province",
  "domicile_city",
  "domicile_zip",
  "domicile_address",
  "domicile_address_number",
  "privacy_notifications",
  "privacy1",
  "privacy2",
  "has_ambassador_code",
  "ambassador_code",

  // school
  "degree_class_id",
  "school_course_id",
  "course_type",
  "school_id",
  "number",
  "is_new",
  "origin",

  // extra
  "uuid",
  "fiscale_code",
  "application_id",

  //conversion
  "crm_lead_id",
  "crm_lead_source",
  "crm_campaign_source",
  "utm_source",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "utm_medium",

  //referral
  "referral_code",
  "referral_type",
];

export const getToken = () => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_API}/authentication/get_token`, {
      method: "GET",
      withCredentials: true
    })
      .then((data) => {
        console.log('data', data)
        resolve(data)
      })
      .catch((err) => {

      })
  })
}

export const ajaxPost = (api: string, payload: any, config?: any) =>
  axios(`${BASE_API}/${api}`, {
    method: "POST",
    withCredentials: true,
    data: payload,
    redirect: "follow",
    ...config,
  }).then((res) => {
    if (res.status === 200) {
      console.log('ajaxGet', res.data)
      return res.data;
    } else {
      console.log(Error)
      throw Error;
    }

  }).catch((error) => {
    console.error(error)
  });

export const ajaxGet = (api: string) =>
  axios(`${BASE_API}/${api}`, {
    method: "GET",
    withCredentials: true,
  })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw Error;
      }
    })
    .catch((error) => {
      console.error(error)
    });


export const ajaxGetFile = (api: string) =>
  axios(`${BASE_API}/${api}`, {
    method: "GET",
    withCredentials: true,
    responseType: 'blob'
  }).then((res) => {
    if (res.status === 200) {
      return res.data;//blob
    } else {
      throw Error;
    }
  });

export const getSchoolDegrees = () => ajaxGet("schools/degree_classes");
export const getSchoolCourseTypes = (origin: string | null, inputValue: string, schoolId?: string) => ajaxGet("schools/types?q=" + inputValue + (origin !== null && origin !== undefined ? ("&origin=" + origin) : '') + (schoolId !== null && schoolId !== undefined ? ("&school_id=" + schoolId) : ''));
export const getSchools = () => ajaxGet("schools?get_all=0&page=1&per_page=15000");
//lasciando solo schools ne prende 20    -    ?get_all=0&page=1&per_page=15000

export const getSchoolDegreesClasses = (inputValue: string, page: number, perPage: number, schoolId?: string, course_type?: string, alreadySelectedDegreeClass?: string) => ajaxGet("schools/degree_classes?q=" + inputValue + '&page=' + page + '&per_page=' + perPage + (course_type !== null && course_type !== undefined ? ("&course_type=" + course_type) : '') + (schoolId !== null && schoolId !== undefined ? ("&school_id=" + schoolId) : '') + '&alreadySelectedDegreeClass=' + (alreadySelectedDegreeClass !== null && alreadySelectedDegreeClass !== undefined ? alreadySelectedDegreeClass : '-1'));

// export const getSchoolCourse = (schoolId?: string) => ajaxGet("schools/courses" + (schoolId !== null && schoolId !== undefined ? '?school_id=' + schoolId : ''));

export const getSchoolCourses = (inputValue: string, page: number, perPage: number, schoolId?: string, degreeClassId?: string, course_type?: string, alreadySelectedSchoolCourse?: string) => ajaxGet("schools/courses?q=" + inputValue + '&page=' + page + '&per_page=' + perPage + (course_type !== null && course_type !== undefined ? ("&course_type=" + course_type) : '') + (schoolId !== null && schoolId !== undefined ? ("&school_id=" + schoolId) : '') + (degreeClassId !== null && degreeClassId !== undefined ? ("&degree_class_id=" + degreeClassId) : '') + '&alreadySelectedSchoolCourse=' + (alreadySelectedSchoolCourse !== null && alreadySelectedSchoolCourse !== undefined ? alreadySelectedSchoolCourse : '-1'));


export const getFilteredSchools = (origin: string | null, inputValue: string, page: number, perPage: number, course_type?: string, alreadySelectedSchool?: string) => ajaxGet("schools?q=" + inputValue + '&page=' + page + '&per_page=' + perPage + (origin !== null && origin !== undefined ? ("&origin=" + origin) : '') + (course_type !== null && course_type !== undefined ? ("&course_type=" + course_type) : '') + '&alreadySelectedSchool=' + (alreadySelectedSchool !== null && alreadySelectedSchool !== undefined ? alreadySelectedSchool : '-1'));


export const getSchool = (id: string) => ajaxGet("school?id=" + id);

export const getDegreeClass = (id: string) => ajaxGet("degreeClass?id=" + id);

export const getSchoolCourse = (id: string) => ajaxGet("schoolCourse?id=" + id);

export const getStartCourse = (uuid: string, tag: string, target: string) => ajaxGet("course/startCourse?uuid=" + uuid + "&tag=" + tag + "&target=" + target);

export const getChoiceCourse = (uuid: string, tag: string, value: string) => ajaxGet("course/choiceCourse?uuid=" + uuid + "&tag=" + tag + "&value=" + value);

export const getEndCourse = (uuid: string, tag: string) => ajaxGet("course/endCourse?uuid=" + uuid + "&tag=" + tag);

export const getCitizenships = () => ajaxGet("citizenships");
export const getNations = () => ajaxGet("nations");

export const getPhoneCodeNations = () => ajaxGet("phoneCodeNations");


export const getProvinces = () => ajaxGet("provinces");

export const getStudentInformation = (token: string) =>
  ajaxGet(`student?token=${token}`);

export const getApplicationInformation = (studentToken: string, applicationToken: string) =>
  ajaxGet(`studentApplication?studentToken=${studentToken}&applicationToken=${applicationToken}`);


export const getLastApplicationInformation = (studentToken: string) =>
  ajaxGet(`studentApplication?studentToken=${studentToken}`);

export const getAccessRequirementInformation = (studentToken: string, applicationToken: string, accessRequirementToken: string) =>
  ajaxGet(`accessRequirement?studentToken=${studentToken}&applicationToken=${applicationToken}&accessRequirementToken=${accessRequirementToken}`);


export const getLastAccessRequirementInformation = (studentToken: string, applicationToken?: string) =>
  ajaxGet(`accessRequirement?studentToken=${studentToken}${(applicationToken !== null && applicationToken !== undefined ? ("&applicationToken=" + applicationToken) : '')}`);


export const getSemesters = (studentToken: string, applicationToken: string, accessRequirementToken?: string) =>
  ajaxGet(`application/semesters?studentToken=${studentToken}&applicationToken=${applicationToken}${(accessRequirementToken !== null && accessRequirementToken !== undefined ? ("&accessRequirementToken=" + accessRequirementToken) : '')}`);

export const getAccessRequirementDocs = (studentToken: string, applicationToken: string, accessRequirementToken?: string, semester?: string) => ajaxGet(`application/documents?studentToken=${studentToken}&applicationToken=${applicationToken}${(accessRequirementToken !== null && accessRequirementToken !== undefined ? ("&accessRequirementToken=" + accessRequirementToken) : '')}${(semester !== null && semester !== undefined ? ("&semester=" + semester) : '')}`);




export const getFileDownload = (studentId: string, applicationId: string) =>
  ajaxGetFile(`students/download-file?id=${studentId}&application_id=${applicationId}`);

export const getAmbassadorInfo = (ambassadorCode: string) => ajaxGet(`students/ambassador?ambassador_code=${ambassadorCode}`);

const _convertToFormData = (
  payload: any,
  objectKey?: string,
  outerField?: string[]
) => {
  const formData = new FormData();
  _addToFormData(formData, payload, objectKey, outerField);
  return formData;
};

const _addToFormData = (
  formData: FormData,
  payload: any,
  objectKey?: string,
  outerField?: string[]
) => {
  Object.keys(payload)?.forEach((key: string) => {
    if (!ALLOWED_FIELDS.includes(key)) return;
    if (outerField?.includes(key)) {
      formData.append(key, payload[key]);
    } else {
      if (objectKey) {
        formData.append(`${objectKey}[${key}]`, payload[key]);
      } else {
        formData.append(key, payload[key]);
      }
    }
  });
  return formData;
};


export const upsertStudentInfo = (payload: any, payloadConversion?: any, referral?: any) => {
  if (!payload) return Promise.resolve();

  const form = _convertToFormData(payload, "student");

  if (payloadConversion && !payload.id) {
    _addToFormData(form, payloadConversion, 'student_conversion');
  }
  if (referral) {
    _addToFormData(form, { referral_code: referral.code, referral_type: referral.type }, 'referral');
  }
  console.log('test' + JSON.stringify(referral));
  return ajaxPost(
    `students/${payload.id ? "update" : "store"}`,
    form
  );
};

export const upsertSchool = (payload: any, referral?: any) => {
  if (!payload) return Promise.resolve();

  const form = _convertToFormData(payload, "student_school");

  if (referral) {
    _addToFormData(form, { referral_code: referral.code, referral_type: referral.type }, 'referral');
  }

  return ajaxPost(
    `students/school/${payload.id ? "update" : "store"}`,
    form
  );
};

export const uploadStudentDocumentsRequest = (payload: any) => {
  if (!payload) return Promise.resolve();

  const formData = new FormData();
  Object.keys(payload)?.forEach((key: string) => {
    if (key === "student_id") {
      formData.append(key, payload[key]);
    } else {
      formData.append(key, payload[key], payload[key]?.name);
    }
  });

  return ajaxPost(`students/upload-student-documents`, formData);
};


export const uploadStudentApplicationDocumentsRequest = (payload: any) => {
  if (!payload) return Promise.resolve();

  const formData = new FormData();
  Object.keys(payload)?.forEach((key: string) => {
    if (key === "application_id" || key === "student_id") {
      formData.append(key, payload[key]);
    } else {
      formData.append(key, payload[key], payload[key]?.name);
    }
  });

  return ajaxPost(`students/upload-studentApplication-documents`, formData);
};

export const uploadTestResultDocumentsRequest = (payload: any) => {
  if (!payload) return Promise.resolve();

  const formData = new FormData();
  Object.keys(payload)?.forEach((key: string) => {
    if (key === "application_id" || key === "student_id") {
      formData.append(key, payload[key]);
    } else {
      formData.append(key, payload[key], payload[key]?.name);
    }
  });

  return ajaxPost(`students/upload-testResult-documents`, formData);
};

export const uploadAccessRequirementDocumentsRequest = (payload: any) => {
  if (!payload) return Promise.resolve();

  const formData = new FormData();
  Object.keys(payload)?.forEach((key: string) => {
    if (key === "access_requirement_id" || key === "application_id" || key === "student_id" || key === "semester") {
      formData.append(key, payload[key]);
    } else {
      formData.append(key, payload[key], payload[key]?.name);
    }
  });

  return ajaxPost(`applications/upload-accessRequirement-documents`, formData);
};

export const getAllStudents = () =>
  ajaxGet("students?get_all=0&page=1&per_page=100");

export const verifyStudentTokenRequest = (uuid: string, token: string) => {
  const formData = new FormData();
  formData.append('uuid', uuid);
  formData.append('tax_code', token);

  return ajaxPost(`student/checkvalid`, formData);
}

export const verifyStudentWelfareTokenRequest = (taxCode: string, originToken: string, referralFromUrl: string, referralCodeFromUrl: string) => {
  const formData = new FormData();
  formData.append('tax_code', taxCode);
  formData.append('token_or', originToken);
  formData.append('referral', referralFromUrl);
  formData.append('referral_code', referralCodeFromUrl);

  return ajaxPost(`welfare/checkvalid`, formData);
}



export const getAllStudentApplications = () =>
  ajaxGet("studentapplications?get_all=0&page=1&per_page=100");


//middleware WIP
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  const studentUuid = getCookie(STUDENT_UUID);
  if (config && config.headers && studentUuid) {
    config.headers.common['student-uuid'] = studentUuid ?? '';
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
