import { IdLabel } from "app/types/common";
import React from "react";
import Select, { ActionMeta } from 'react-select';



interface Props {
  value?: string;
  groupsLabel?: Map<string, string>;
  groups?: Map<string, Array<IdLabel>>;
  options?: IdLabel[];
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  onChange(selectedOption: string): void;
  isDisabled?: boolean;
  id?: string;
}
interface GroupedOption {
  readonly label: string,
  readonly options: Option[],
}

interface Option {
  readonly value: string,
  readonly label: string,
}


const AdvancedCcSingleSelect: React.FC<Props> = (props: Props) => {
  const { label, options, groups, groupsLabel } = props;


  const onChange = (option: any | null, actionMeta: ActionMeta<any>) => props.onChange(option?.value);


  const getValue = (val: string | undefined): Option | null => {
    for (let i = 0; i < (options ?? []).length; i++) {

      const option = options![i];
      if ((option.id + "") === (val + "")) {
        // const label = <span dangerouslySetInnerHTML={{ __html: option.label }} />;

        return { value: option.id + '', label: option.label };
      }
    } return null;


  }

  const styles = {

    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#003da6' : (state.isFocused ? '#ddebff' : '#fff'),
    }),
  }

  const getOptions = (): GroupedOption[] => {
    if (groups && groupsLabel) {
      const returnValue: GroupedOption[] = [];

      Object.keys(groups).forEach((groupId: string, index: number) => {


        const group: GroupedOption = {
          label: groupId,
          options: []

        };
        groups[groupId].forEach((option: IdLabel) => {
          // const label = <span dangerouslySetInnerHTML={{ __html: option.label }} />;
          group.options.push({ value: option.id + '', label: option.label });
        });
        returnValue.push(group);
      });

      return returnValue;

    } else {

      const returnValue: GroupedOption[] = [];
      const group: GroupedOption = {
        label: '',
        options: []

      };

      options?.forEach((option) => {
        // const label = <span dangerouslySetInnerHTML={{ __html: option.label }} />;
        //const label = option.label;
        group.options.push({ value: option.id + '', label: option.label });
      });
      returnValue.push(group);

      return returnValue;

    }
  };


  return (
    <div className="form-floating selectCompStyle">
      <Select
        tabIndex={0}
        options={getOptions()}


        formatOptionLabel={function (data) {
          return (
            <span dangerouslySetInnerHTML={{ __html: data.label }} />
          );
        }}

        value={getValue(props.value)}
        className={`cp form-floating ${props.errorMessage ? "is-invalid borderRed" : ""}`}
        id={props.id}
        placeholder={label}
        onChange={onChange}
        isClearable
        styles={styles}
        isDisabled={props.isDisabled}
        isSearchable={true}
      />

      <div
        style={{ marginTop: ".25rem", fontSize: ".875em", borderColor: "red" }}
        className={props.errorMessage ? "pl-5 text-danger" : "invisible"}
      >
        {props.errorMessage || '&nbsp;'}
      </div>
    </div>
  );
};

export default AdvancedCcSingleSelect;
