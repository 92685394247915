import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import Button from "app/components/common/button";
import CheckBox from "app/components/common/checkbox";
import ConditionalLoader from "app/components/common/loader/conditionalLoader";
import RadioInput from "app/components/common/radioInput";
import { FormattedMessage, useIntl } from "react-intl";

import { DUMMY_LOADER_TIME, getDataSubmitStateClass } from "app/utils/utils";


interface Props {
  isOpen: boolean;
  studentInfo: object;

  onStudentInfoChange(data: object): void;
  onSaveAndContinue(data: object): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      // border: '5px solid #036ED9',
      border: '3px inset #036ED9',
      width: '90%',
      borderRadius: '5px',
      //backgroundImage: 'linear-gradient(135deg, #036ED9, #00FFC6)',   
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: 'scroll',
    },

    button: {
      borderRadius: '5px',
      border: '1px solid #003da6',
      background: '#fff',
    },
    linkToFile: {
      color: '#003da6',
      textDecoration: 'none',
      background: '#fff',
      '&:hover': {
        fontWeight: '600'
      }


    },
    linkToAnnexA: {
      color: '#003da6',
      textDecoration: 'none',
      background: '#fff',
      '&:hover': {
        color: '#9d2085!important'
      }


    }
  }),
);



const PrivacyModal: React.FC<Props> = (props: Props) => {

  const GIVE_CONSENT = useIntl().formatMessage({ id: "step2.info3.radio.yes" });
  const DENY_CONSENT = useIntl().formatMessage({ id: "step2.info3.radio.no" });

  const PRIVACY_OPTIONS = [
    { id: 1, label: GIVE_CONSENT },
    { id: 0, label: DENY_CONSENT },
  ];


  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  const classes = useStyles();
  const { studentInfo = {} } = props;


  const [isPolicyChecked, setIsPolicyChecked] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  const onOptionChange = (key: string) => (value: string) => {
    if (error) setError("");
    props.onStudentInfoChange({ ...studentInfo, [key]: +value });
  };



  const onPrivacyPolicyCheck = (checked: boolean) => {
    if (checked && error) setError("");
    studentInfo["privacy_notifications"] = checked ? 1 : 0;
    setIsPolicyChecked(checked);
  };
  const ERROR_ON_SAVE_PRIVACY = useIntl().formatMessage({ id: "step2.info3.radio.error" });


  const onSaveAndContinue = () => {
    if (studentInfo["privacy1"] == null || studentInfo["privacy2"] == null) {
      setError(ERROR_ON_SAVE_PRIVACY);
    } else if (!isPolicyChecked) {
      setError("Si prega di accettare l'informativa sulla privacy");
    } else {
      setIsLoading(true);


      props.onStudentInfoChange(studentInfo);
      setTimeout(() => {
        setIsLoading(false);
        props.onSaveAndContinue(studentInfo);
      }, DUMMY_LOADER_TIME);
    }
  };
  const locale = useIntl().locale === 'IT' ? 'IT' : 'EN';


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.isOpen}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="mediaQuPrivacyMod">
        <div className={classes.paper}>


          <ConditionalLoader isLoading={isLoading} />

          <div className={getDataSubmitStateClass(isLoading)}>
            <div className="text-4xl font-700 mb-15">
              <FormattedMessage id="step2.info3.privacy.title" />
            </div>

            <div className="privacyCheckboxWrapper flex mb-30">
              <CheckBox
                label=""
                onChange={onPrivacyPolicyCheck}
                checked={studentInfo["privacy_notifications"] === 1}
              />
              <div>
                <FormattedMessage id="step2.info3.checkbox.lable1" />
                {/* <a href={"https://www.habacus.com/" + locale.toLocaleLowerCase() + "/privacy"} target="_blank" rel="noreferrer"> */}
                <a className={classes.linkToFile} href={"/attachments/AnnexA_" + locale + ".pdf"} download>
                  <FormattedMessage id="step2.info3.checkbox.lable2" />
                </a>
                <FormattedMessage id="step2.info3.checkbox.lable3" />

              </div>

            </div>

            <div className="mb-30">
              <h4><FormattedMessage id="step2.info3.privacy.title1" /></h4>
              <p className="mb-20">
                <FormattedMessage id="step2.info3.privacy.text1" />
              </p>
              <div className="boldy">
                <RadioInput
                  name="privacy1"
                  value={(studentInfo["privacy1"] !== undefined && studentInfo["privacy1"] !== null) ? Number(studentInfo["privacy1"]) : undefined}
                  options={PRIVACY_OPTIONS}
                  onChange={onOptionChange("privacy1")}
                />
              </div>
            </div>
            <div className="mb-20">
              <h4><FormattedMessage id="step2.info3.privacy.title2" /></h4>
              <p className="mb-20">
                <FormattedMessage id="step2.info3.privacy.text3" />
              </p>
              <div>
                <RadioInput
                  name="privacy2"
                  value={(studentInfo["privacy2"] !== undefined && studentInfo["privacy2"] !== null) ? Number(studentInfo["privacy2"]) : undefined}
                  options={PRIVACY_OPTIONS}
                  onChange={onOptionChange("privacy2")}
                />
              </div>
            </div>
            {error && <div className="text-danger mt-1">{error}</div>}
            <div className="mt-30 flexAround">


              <button className={classes.button}>
                <a className={classes.linkToAnnexA} href={"/attachments/AnnexA_" + locale + ".pdf"} download>
                  <FormattedMessage id="step2.info3.attachedFile" />
                </a>
              </button>


              <Button
                disabled={isLoading || !isPolicyChecked}
                label={useIntl().formatMessage({ id: "button.save&continue" })}
                variant="yesTest"
                onClick={onSaveAndContinue}

              />
            </div>
          </div>


        </div>
      </div>
    </Modal >
  );

}


export default PrivacyModal;
