import AlertAccessRequirementModal from "app/components/common/alertAccessRequirementModal";
import GroupFileUpload from "app/components/common/groupFileUpload";
import ConditionalLoader from "app/components/common/loader/conditionalLoader";
import { getAccessRequirementDocs, getSemesters, uploadAccessRequirementDocumentsRequest } from "app/service/apiService";
import { DOCUMENTS_OPTIONS_LABEL } from "app/utils/documents";
import { MyFile } from "app/utils/imagesToPdf";
import { DUMMY_LOADER_TIME, getDataSubmitStateClass } from "app/utils/utils";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import AdvancedSingleSelect from "app/components/common/advancedSingleSelect";
import ButtonSave from "app/components/common/buttonSave";


interface Props {
  studentInfo: any;
  studentApplicationInfo: any;
  accessRequirementInfo: any;
  onStudentInfoChange(data: object): void;
  onAccessRequirementInfoChange(data: object): void;
  onSaveAndContinue(): void;
}


interface DocumentDictionary {
  [type: string]: MyFile;
}

const UploadAccessRequirementDocumentsComponent: React.FC<Props> = (props: Props) => {

  const { studentInfo, studentApplicationInfo, accessRequirementInfo } = props;
  const alreadyUploadedAccessRequirementDocuments = accessRequirementInfo?.documents_uploaded?.filter((el: any) => el.status != 'REJ');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [documents, setDocuments] = React.useState<DocumentDictionary>({});

  const [documentsToUpload, setDocumentsToUpload] = React.useState<any[]>();
  const [documentsUploaded, setDocumentsUploaded] = React.useState<any[]>();

  const [errorMap, setError] = React.useState<any>();

  const [isAlertAccessRequirementModalOpen, setIsAlertAccessRequirementModalOpen] = React.useState<boolean>(false);

  const [semestersAvailable, setSemestersAvailable] = React.useState<any[]>();
  const [selectedSemester, setSelectedSemester] = React.useState<string>();

  // const [invalidFields, setInvalidFields] = React.useState<any>();


  const renderSection = (label: string, value?: string, onClick?: () => void) => {
    return (
      <div key={`${label}_${value}`} onClick={onClick} className="center-y pr-5 mb-1">
        <div className="text-md font-500">{label}:</div>
        <div className="ml-10">{value || "-"}</div>
      </div>
    );
  };

  const removeDocument = (type: string) => {
    const { [type]: deleted, ...objectWithoutDeletedProp } = documents;
    setDocuments(objectWithoutDeletedProp);
  }

  const addDocument = (file: MyFile, type: string) => {
    setDocuments(Object.assign({ [type]: file }, documents));
  }


  const uploadDocuments = () => {
    setIsLoading(true);
    const documentDataMapByType = {} as any;
    Object.entries(documents).forEach(([type, document]) => {
      if (!type) return;
      documentDataMapByType[type] = document.file;
    });

    uploadAccessRequirementDocumentsRequest({
      student_id: props.studentInfo?.id,
      application_id: props.studentApplicationInfo?.id,
      access_requirement_id: props.accessRequirementInfo?.id,
      semester: selectedSemester,
      ...documentDataMapByType,
    })
      .then((res: any) => {
        if (res.status === "OK") {
          const updatedMedia = res.accessRequirement?.documents_uploaded || alreadyUploadedAccessRequirementDocuments; // if not received then fallback to alreadyUploadedDocuments

          if (res.access_requirement !== undefined) {//it's a creation

            props.onAccessRequirementInfoChange(res.access_requirement);
          } else {
            props.onAccessRequirementInfoChange({
              ...props.accessRequirementInfo,
              documents_uploaded: updatedMedia,
            });
          }
          setTimeout(() => {
            setIsLoading(false);
            props.onSaveAndContinue();
          }, DUMMY_LOADER_TIME);
        } else {
          const messages = res.messages;

          if (Array.isArray(messages)) {
            setError({ other: messages[0] });
          } else {
            const errorMapByType = {};
            Object.keys(messages || {}).forEach((key: string) => {
              const errors = messages[key];
              errorMapByType[key] = errors[0];
            });


            setError(
              Object.keys(errorMapByType).length ? errorMapByType : undefined
            );
          }

          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError({ other: "Couldn't upload documents" });
      });
  };

  const checkForAccessRequirementDocuments = () => {
    setIsLoading(true);


    let hasRequiredDocuments = false;

    let requiredDocuments = (documentsUploaded?.length ?? 0);


    Object.keys(documents ?? {}).forEach((doc) => {
      requiredDocuments++;
    });


    if (requiredDocuments === (documentsUploaded?.length ?? 0) + (documentsToUpload?.length ?? 0)) {
      hasRequiredDocuments = true;
    }

    console.log('hasRequiredDocuments' + hasRequiredDocuments);
    console.log('requiredDocuments' + requiredDocuments);


    if (hasRequiredDocuments) {
      uploadDocuments();
    } else {
      setIsAlertAccessRequirementModalOpen(true);
    }

    setTimeout(function () {
      setIsLoading(false);
    }, 2000);

  };

  const handleClose = (result: boolean) => {

    setIsAlertAccessRequirementModalOpen(false);

    if (result === true) {
      uploadDocuments();
    }
  };



  const renderAlreadyUploadedDocuments = () => {
    if (alreadyUploadedAccessRequirementDocuments?.length === 0) return null;
    return (
      <div
        className="my-1 border-1 br-4 border-solid p-2 mb-15"
        style={{ borderColor: "var(--grey-dark)" }}
      >
        <div className="pb-2 px-0 text-lg font-600">
          <FormattedMessage id="added.docs" />

        </div>
        <div>
          {alreadyUploadedAccessRequirementDocuments?.map((doc: any) =>
            renderSection(
              DOCUMENTS_OPTIONS_LABEL[doc["collection_name"]],
              doc["file_name"]
            )
          )}


        </div>
      </div>
    );
  };

  const renderUploadingDocuments = () => {
    if (!Object.keys(documents ?? {})?.length) return null;
    return (
      <div
        className="mt-3 my-1 border-1 br-4 border-solid p-2 mb-15"
        style={{ borderColor: "var(--grey-dark)" }}
      >
        <div className="pb-2 px-0 text-lg font-600">
          <FormattedMessage id="uploaded.docs" />

        </div>
        <div>
          {Object.entries(documents ?? {}).map(([key, value]) =>
            renderSection(
              DOCUMENTS_OPTIONS_LABEL[key],
              value.file.name,
              () => window.open(value.dataURL, "_blank"),
            )
          )}
        </div>
      </div>
    );
  };

  const textDictionary = {
    'concern_payments_ok_student': useIntl().formatMessage({ id: "uploadDocuments.concern_payments" }),
    'learning_agreement_student': useIntl().formatMessage({ id: "uploadDocuments.learning_agreement" }),
    'final_grade_student': useIntl().formatMessage({ id: "uploadDocuments.final_grade" }),
    'attended_frequency_student': useIntl().formatMessage({ id: "uploadDocuments.attended_frequency" }),
    'at_least_n_credits_student': useIntl().formatMessage({ id: "uploadDocuments.at_least_n_credits" }),
    'studies_plan_student': useIntl().formatMessage({ id: "uploadDocuments.studies_plan" }),

  };
  // const TESTNAME = 'ciao';
  // const concern_payments_STRING = useIntl().formatMessage({ id: "uploadDocuments.concern_payments" });
  // const learning_agreement_STRING = useIntl().formatMessage({ id: "uploadDocuments.learning_agreement" });
  // const final_grade_STRING = useIntl().formatMessage({ id: "uploadDocuments.final_grade" });
  // const attended_frequency_STRING = useIntl().formatMessage({ id: "uploadDocuments.attended_frequency" });
  // const at_least_n_credits_STRING = useIntl().formatMessage({ id: "uploadDocuments.at_least_n_credits" });
  // const studies_plan_STRING = useIntl().formatMessage({ id: "uploadDocuments.studies_plan" });

  // const DOC = concern_payments_STRING ? doc = 'concern_payments_ok_student' : 'altro...';
  // sta roba qua sopra andrebbe messo tra 230 e 231 ma poi non va useintl

  const renderToUploadDocuments = () => {

    return documentsToUpload?.map((doc: any, i: number) => {
      return <div className="mt-30">
        <GroupFileUpload
          name={textDictionary[doc["collection_name"]]}
          type={doc["collection_name"]}
          statusRej={doc["status"] == 'REJ'}
          prevError={doc["status_reason"]}
          onRemoveDocument={removeDocument}
          onAddDocument={addDocument}
          fileCount={2}
        />
        <div className="borderUpload"></div>

      </div>
    })
  }

  const test = () => {
    console.log('accessRequirementInfo - ' + accessRequirementInfo);
  }

  useEffect(() => {
    loadSemesters();
    if (accessRequirementInfo?.semester) {
      setSelectedSemester(accessRequirementInfo?.semester);
    }
    if (accessRequirementInfo?.documents_to_upload) {
      setDocumentsToUpload(accessRequirementInfo?.documents_to_upload);
    }
    if (accessRequirementInfo?.documents_uploaded) {
      setDocumentsUploaded(accessRequirementInfo?.documents_uploaded);
    }

  }, [accessRequirementInfo]);

  const loadSemesters = async () => {
    const downloadedSemesters = await getSemesters(studentInfo.uuid, studentApplicationInfo.id, accessRequirementInfo?.id);

    setSemestersAvailable(downloadedSemesters.semesters);

  }

  const onChangeSemester = async (key?: string) => {
    setSelectedSemester(key);
    const response = await getAccessRequirementDocs(studentInfo?.uuid, studentApplicationInfo?.id, accessRequirementInfo?.id, key)

    if (response?.documents_to_upload) {
      setDocumentsToUpload(response?.documents_to_upload);
    }
    if (response?.documents_uploaded) {
      setDocumentsUploaded(response?.documents_uploaded);
    }
  }


  const labelSelect = useIntl().formatMessage({ id: "accessReq.select" });
  return (
    <div className="relative">
      <ConditionalLoader isLoading={isLoading} />

      <div className="proxyPopUp">
        <AlertAccessRequirementModal
          isAlertAccessRequirementOpen={isAlertAccessRequirementModalOpen}
          handleClose={handleClose}
        // isProxyUploaded={isProxyUploaded}
        ></AlertAccessRequirementModal>
      </div>


      <div className={getDataSubmitStateClass(isLoading)}>

        {/* start List of what to upload */}
        <div className="mb-30 mt-30">
          <div className="text-4xl font-600 mb-15 mt-20">
            <FormattedMessage id="accessReq.title" />
          </div>

          <div className="text-xl">
            <FormattedMessage id="accessReq.text1" />
          </div>
          <div className="text-xl mb-15">
            <FormattedMessage id="accessReq.text2" />
          </div>

          <div className="text-xl">
            <FormattedMessage id="accessReq.text3" />
          </div>
          <div className="text-xl mb-15">
            <FormattedMessage id="accessReq.text4" />
          </div>


          <div className="text-2xl">

            <AdvancedSingleSelect
              value={selectedSemester}
              label={labelSelect}
              options={semestersAvailable}
              errorMessage={''}
              onChange={onChangeSemester}
              isDisabled={accessRequirementInfo?.semester !== null}
              id="semesterSelect"
            />

          </div>
        </div>
        {/* end List of what to upload */}
        {test()}

        {renderAlreadyUploadedDocuments()}

        {renderUploadingDocuments()}



        {renderToUploadDocuments()}


        {errorMap?.other && (
          <div className="text-danger font-500 mt-1">{errorMap?.other}</div>
        )}
        {
          Array.isArray(errorMap)
            ? <div className="text-danger font-500 mt-1">{errorMap[0]}</div>
            : null
        }
        <div className="mt-30 buttonsGoToTheRight">
          {/* <Button
            label={useIntl().formatMessage({ id: "button.save&continue" })}
            variant="primary"
            onClick={checkForAccessRequirementDocuments}
          /> */}

          <ButtonSave
            disabled={isLoading}
            label1={useIntl().formatMessage({ id: "button.saveAndGoOn1" })}
            label2={useIntl().formatMessage({ id: "button.saveAndGoOn2" })}
            variant="flexButtSave"
            onClick={checkForAccessRequirementDocuments}
          />

        </div>
      </div>
    </div>
  );
};

export default UploadAccessRequirementDocumentsComponent;
