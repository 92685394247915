import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from "app/components/common/button";
import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";


interface Props {
    isOpen: boolean;
    handleClose(result: boolean): void;

    // isAgeCorrect: boolean;
    // isTaxCodeCorrect: boolean;

    // studentInfo: object;

    // onStudentInfoChange(data: object): void;
    // onSaveAndContinue(data: object): void;
}

// const onContinue = () => {

// };

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '3px inset #036ED9',
            borderRadius: '5px',
            // backgroundImage: 'linear-gradient(135deg, #036ED9, #00FFC6)',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',

        },



    }),
);




const AlertAlreadyRegisteredModal: React.FC<Props> = (props: Props) => {


    const classes = useStyles();

    return (
        <Modal

            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.isOpen}
            // onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}


        >
            <div className={classes.paper}>

                <h1 className="pulseAttentionModal">
                    <FormattedMessage id="alertModalAR.title" />
                </h1>

                <h4>
                    <FormattedMessage id="alertModalAR.text1" />
                </h4>
                <h4>
                    <FormattedMessage id="alertModalAR.text2" />
                    <a href="mailto:tua@email.com">info@habacus.com</a>.
                </h4>
                <h4>
                    <FormattedMessage id="alertModalAR.text3" />
                </h4>

                {/* 
                {
                    props.isTaxCodeCorrect ? null : (<h2><FormattedMessage id="alertModal.2a" /><b><FormattedMessage id="alertModal.2b" /></b><FormattedMessage id="alertModal.2c" />?</h2>)
                }
                <br />
                {
                    props.isAgeCorrect ? null : (<h2><FormattedMessage id="alertModal.3a" /><FormattedMessage id="alertModal.3a2" /><b><FormattedMessage id="alertModal.3b" /></b><FormattedMessage id="alertModal.3c" />?</h2>)
                } 
                */}

                <br />

                <div className="alertButtonsContainer">

                    {/* <Button
                        label={useIntl().formatMessage({ id: "button.cancel" })}
                        variant="secondary"
                        onClick={() => props.handleARClose(false)}
                    />
                    */}
                    <Button
                        label={useIntl().formatMessage({ id: "button.continue" })}
                        variant="primary"
                        onClick={() => props.handleClose(true)}
                    />


                </div>

            </div>
        </Modal >
    );

}


export default AlertAlreadyRegisteredModal;
