import ConditionalLoader from "app/components/common/loader/conditionalLoader";
import StudentDisclaimer from "app/components/userInformation/studentDisclaimer";
import StudentTaxCodeInput from "app/components/userInformation/studentTaxCodeInput";
import StudentWelfareDisclaimer from "app/components/userInformation/studentWelfareDisclaimer";
import UserInformation from "app/components/userInformation/userInformation";
import StudentInfoProvider, {
  ORIGIN_TOKEN_PARAM_NAME,
  POSTE_REFERRAL,
  REFERRAL,
  REFERRAL_CODE,
  STUDENT_TOKEN_PARAM_NAME
} from "app/context/studentInfoProvider";
import { verifyStudentTokenRequest, verifyStudentWelfareTokenRequest } from "app/service/apiService";
import { getCookie, removeCookie, setCookie, STUDENT_UUID } from "app/utils/cookies";
import { getDataSubmitStateClass, getQueryParam } from "app/utils/utils";
import React from "react";
import { useNavigate } from "react-router-dom";


interface Props {
  onSetStepNumber(stepNumber: number): void;
  onSetCurrentStepNumber(currentStepNumber: number): void;
  onSetStepperVisible(isVisible: boolean): void;
  onChangelanguage(lang: string): void;
}


const UserInformationDashboard: React.FC<Props> = (props: Props) => {

  let navigate = useNavigate();


  const studentToken = getQueryParam(STUDENT_TOKEN_PARAM_NAME) || "";
  const originToken = getQueryParam(ORIGIN_TOKEN_PARAM_NAME) || "";
  const referralFromUrl = getQueryParam(REFERRAL) || "";
  const referralCodeFromUrl = getQueryParam(REFERRAL_CODE) || "";


  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [
    studentTaxCodeVerified,
    setStudentTaxCodeVerified,
  ] = React.useState<boolean>(
    studentToken ? Boolean(getCookie(STUDENT_UUID) === studentToken) : false
  );

  const isInEditMode = () => {
    return studentToken !== undefined && studentToken !== null && studentToken.trim() !== '';
  }

  const isFromWelfare = () => {
    return originToken !== undefined && originToken !== null && originToken.trim() !== ''
      && (originToken === POSTE_REFERRAL);
  }

  const [disclaimerAccepted, setDisclaimerAccepted] = React.useState<boolean>(false);

  const verifyStudentToken = (taxCode: string) => {
    setLoading(true);
    verifyStudentTokenRequest(studentToken, taxCode)
      .then((res: any) => {
        setLoading(false);
        if (res.status === "OK") {
          setCookie(STUDENT_UUID, studentToken, 6);
          setStudentTaxCodeVerified(true);
        } else {
          setError("Si prega di inserire un codice fiscale valido");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const verifyStudentWelfareToken = (taxCode: string) => {
    setLoading(true);
    verifyStudentWelfareTokenRequest(taxCode, originToken, referralFromUrl, referralCodeFromUrl)
      .then((res: any) => {
        setLoading(false);
        if (res.status === "OK") {
          setCookie(STUDENT_UUID, res.student.uuid, 6);
          setStudentTaxCodeVerified(true);
          setError("");
          const link = window.location.pathname + window.location.search + '&token=' + res.student.uuid + '&token_p=' + res.student_application.uuid;
          navigate(link);
        } else {
          setError("Si prega di inserire un codice fiscale valido");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isInEditMode() && !studentTaxCodeVerified) {
    return (
      <div className="relative">
        <ConditionalLoader isLoading={isLoading} />
        <div className={getDataSubmitStateClass(isLoading)}>
          {/* LOGIN */}
          <StudentTaxCodeInput
            error={error}
            clearError={() => setError("")}
            onSubmit={verifyStudentToken}
            onChangelanguage={props.onChangelanguage}
          />
        </div>
      </div>
    );
  } else if (!isInEditMode() && isFromWelfare() && !studentTaxCodeVerified) {
    removeCookie(studentToken);

    return (
      <div className="relative">
        <ConditionalLoader isLoading={isLoading} />
        <div className={getDataSubmitStateClass(isLoading)}>
          {/* HELLO PAGE  */}
          <StudentWelfareDisclaimer
            error={error}
            clearError={() => setError("")}
            onSubmit={verifyStudentWelfareToken}
            onChangelanguage={props.onChangelanguage}
          />
        </div>
      </div>
    );
  } else if (!isInEditMode() && !isFromWelfare() && !disclaimerAccepted) {
    removeCookie(studentToken);

    return (
      <div className="relative">
        <ConditionalLoader isLoading={isLoading} />
        <div className={getDataSubmitStateClass(isLoading)}>
          {/* HELLO PAGE  */}
          <StudentDisclaimer
            onSubmit={setDisclaimerAccepted}
            onChangelanguage={props.onChangelanguage}
          />
        </div>
      </div>
    );
  } else {
    return (
      <StudentInfoProvider>
        {/* ONBOARDING PROCESS  */}
        <UserInformation onSetStepNumber={props.onSetStepNumber} onSetCurrentStepNumber={props.onSetCurrentStepNumber} onSetStepperVisible={props.onSetStepperVisible} />
      </StudentInfoProvider>
    );
  }
};

export default UserInformationDashboard;
