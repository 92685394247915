import { getFilteredSchools } from "app/service/apiService";
import { IdLabel } from "app/types/common";
import React, { useEffect } from "react";
import { ActionMeta } from "react-select";


import Creatable from "react-select/creatable";

import { ORIGIN_TOKEN_PARAM_NAME } from "app/context/studentInfoProvider";
import { getQueryParam } from "app/utils/utils";
import { useIntl } from "react-intl";
import { withAsyncPaginate } from "react-select-async-paginate";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);




interface Props {
  value?: string;
  groupsLabel?: Map<string, string>;
  groups?: Map<string, Array<IdLabel>>;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  onChange(selectedId: string, selectedLabel: string | undefined, isNew: boolean | undefined): void;
  course_type?: string;
  isDisabled: boolean;
}
// interface GroupedOption {
//   readonly label: string,
//   readonly options: Option[],
// }

interface Option {
  readonly value: string,
  readonly label: string,
}

const SchoolSelect: React.FC<Props> = (props: Props) => {
  const { label } = props;

  // const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
  // const [inputValue, setInputValue] = React.useState<string>('');
  const [options, setOptions] = React.useState<IdLabel[]>([]);

  const onChange = (option: any | null, actionMeta: ActionMeta<any>) => {
    if (!option) {
      props.onChange('', '', undefined);
      return;
    }

    if (option.id) {
      props.onChange(option.id, option.name, false);
    } else {
      props.onChange(option.value, option.label, true);
    }

  }

  useEffect(() => {
    setOptions([]);
    // props.onChange('', undefined, false);
  }, [props.course_type]);

  const loading = useIntl().formatMessage({ id: "select.loading" })

  const getValue = (val: string | undefined | null): Option | null => {

    if (val === undefined || val === null || (val + '').trim() === '') {
      return null;
    }

    if (options !== undefined && options !== null && options.length === 0) {
      return { value: val, label: loading };
    }

    for (let i = 0; i < (options ?? []).length; i++) {

      //qua va filtrato?

      const option = options![i];
      if ((option.id + '') === (val + '')) {
        return { value: option.id + '', label: option.label };
      }

    }


    // return null;
    // return new Option(val, '0');
    return { value: '0', label: val };
  }

  const styles = {



    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#003da6' : (state.isFocused ? '#ddebff' : '#fff'),
    }),
  }



  // const handleInputChange = (newValue: string) => {
  //   const inputValue = newValue.replace(/\W/g, '');
  //   setInputValue(inputValue);
  //   return inputValue;
  // };

  // const handleInputChange = (inputValue: string, actionMeta: any) => {
  //   setInputValue(inputValue);
  //   console.log(inputValue);

  // };


  const loadOptions = async (inputValue: string, options: any, additional: any) => {

    const per_page = 100;

    const response = await getFilteredSchools(getQueryParam(ORIGIN_TOKEN_PARAM_NAME), inputValue, additional?.page, per_page, props.course_type, props.value)
    const newOptions = response.schools?.map((s: any) => ({ ...s, label: s.name }))
    setOptions([...newOptions, ...options]);

    return {
      options: newOptions,
      hasMore: response.total_records > additional.page * per_page,
      additional: {
        page: additional.page + 1,
      },
    };

  };

  return (
    <div className="form-floating selectCompStyle">
      <CreatableAsyncPaginate
        key={`school-select-${props.course_type ?? ''}`}
        tabIndex={0}
        // cacheOptions
        defaultOptions={!props.isDisabled}
        loadOptions={loadOptions}
        // onInputChange={handleInputChange}
        onChange={onChange}
        value={getValue(props.value)}
        additional={{
          page: 1,
        }}
        styles={styles}
        isDisabled={props.isDisabled}


        // options={getOptions()}
        // defaultValue={props.value || "section-option"}

        className={`cp form-floating ${props.errorMessage ? "is-invalid" : ""}`}
        id="floatingSelect"
        placeholder={label}
        // onChange={onChange}
        isClearable
      />
      <div
        style={{ marginTop: ".25rem", fontSize: ".875em" }}
        className={props.errorMessage ? "pl-5 text-danger" : "invisible"}
      >
        {props.errorMessage || '&nbsp;'}
      </div>
    </div>
  );
};

export default SchoolSelect;
