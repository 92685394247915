import React, { useEffect } from 'react';



// const UseOnScreen: React.FC<Props> = (props: Props) => {




export default function useOnScreen(ref: React.RefObject<any>) {

    const [isIntersecting, setIntersecting] = React.useState(false);


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            { threshold: 0.8 }
        )

        observer.observe(ref?.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [ref])

    return isIntersecting
}