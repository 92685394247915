import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from "app/components/common/button";
import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";


interface Props {
    isConfirmYesTestVisible: boolean;
    handleCloseYesTestModalAndContinue(result: boolean): void;
    handleCloseYesTestModal(result: boolean): void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // maxWidth: '80%'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '3px inset #036ED9',
            borderRadius: '5px',
            // backgroundImage: 'linear-gradient(135deg, #036ED9, #00FFC6)',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },
    })
);


const ConfirmYesTestModal: React.FC<Props> = (props: Props) => {


    const classes = useStyles();
    const closeModalYesTestAndContinue = () => {

        props.handleCloseYesTestModalAndContinue(true);

    }

    const goBackToChoice = () => {
        props.handleCloseYesTestModal(true);
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.isConfirmYesTestVisible}
            // onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className={classes.paper}>

                <h1 className='my-4'><FormattedMessage id="orientation.bpi.confirmYesTestModal.title" /></h1>
                <h2><FormattedMessage id="orientation.bpi.confirmYesTestModal.text1" /></h2>
                <h2><FormattedMessage id="orientation.bpi.confirmYesTestModal.text2" /></h2>
                <h2><FormattedMessage id="orientation.bpi.confirmYesTestModal.text3" /></h2>
                <div className="alertButtonsContainer">

                    <Button
                        label={useIntl().formatMessage({ id: "button.cancel" })}
                        variant="noTest"
                        onClick={goBackToChoice}
                    />

                    <Button
                        label={useIntl().formatMessage({ id: "button.continue" })}
                        variant="yesTest"
                        onClick={closeModalYesTestAndContinue}
                    />


                </div>

            </div>
        </Modal >
    );

}


export default ConfirmYesTestModal;
