export const STUDENT_UUID = 'student_uuid';

export const getCookie = (name: string) => {
  const cookies = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
  return cookies ? cookies.pop() : "";
};

export const setCookie = (name: string, value: string, hours: number = 12) => {
  let expires = "";
  if (hours) {
    let date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const removeCookie = (name: string) => {
  document.cookie = "path=/";
};
