import ButtonSave from "app/components/common/buttonSave";
import GroupFileUpload from "app/components/common/groupFileUpload";
import ConditionalLoader from "app/components/common/loader/conditionalLoader";
import { uploadStudentDocumentsRequest } from "app/service/apiService";
import { DOCUMENTS_OPTIONS_LABEL } from "app/utils/documents";
import { MyFile } from "app/utils/imagesToPdf";
import { DUMMY_LOADER_TIME, getDataSubmitStateClass } from "app/utils/utils";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";


interface Props {
  studentInfo: any;
  studentApplicationInfo: any;
  onStudentInfoChange(data: object): void;
  onSaveAndContinue(): void;
}

const OPTIONS_GROUP_OPTIONS_IDS = {
  main: [
    "identity_document",
    "sanitary_card",
  ],
};


interface DocumentDictionary {
  [type: string]: MyFile;
}

const UploadDocumentsComponent: React.FC<Props> = (props: Props) => {

  const { studentInfo, studentApplicationInfo } = props;
  console.log(studentInfo?.media);

  const alreadyUploadedDocuments = studentInfo?.media?.filter((el: any) => el.status != 'REJ');
  const alreadyUploadedStudentApplicationDocuments = studentApplicationInfo?.media?.filter((el: any) => el.status != 'REJ');


  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [documents, setDocuments] = React.useState<DocumentDictionary>({});
  const [errorMap, setError] = React.useState<any>();

  const renderSection = (label: string, value?: string, onClick?: () => void) => {
    return (
      <div key={`${label}_${value}`} onClick={onClick} className="center-y pr-5 mb-1">
        <div className="text-md font-500">{label}:</div>
        <div className="ml-10">{value || "-"}</div>
      </div>
    );
  };

  const removeDocument = (type: string) => {
    const { [type]: deleted, ...objectWithoutDeletedProp } = documents;
    setDocuments(objectWithoutDeletedProp);
  }

  const addDocument = (file: MyFile, type: string) => {
    setDocuments(Object.assign({ [type]: file }, documents));
  }


  const uploadDocuments = () => {
    setIsLoading(true);
    const documentDataMapByType = {} as any;
    Object.entries(documents).forEach(([type, document]) => {
      if (!type) return;
      documentDataMapByType[type] = document.file;
    });

    uploadStudentDocumentsRequest({
      student_id: props.studentInfo?.id,
      ...documentDataMapByType,
    })
      .then((res: any) => {
        if (res.status === "OK") {
          const updatedMedia = res.student?.media || alreadyUploadedDocuments; // if not received then fallback to alreadyUploadedDocuments
          props.onStudentInfoChange({
            ...props.studentInfo,
            media: updatedMedia,
          });
          setTimeout(() => {
            setIsLoading(false);
            props.onSaveAndContinue();
          }, DUMMY_LOADER_TIME);
        } else {
          const messages = res.messages;

          if (Array.isArray(messages)) {
            setError({ other: messages[0] });
          } else {
            const errorMapByType = {};
            Object.keys(messages || {}).forEach((key: string) => {
              const errors = messages[key];
              errorMapByType[key] = errors[0];
            });


            setError(
              Object.keys(errorMapByType).length ? errorMapByType : undefined
            );
          }

          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError({ other: "Couldn't upload documents" });
      });
  };

  const onSaveAndContinue = () => {
    setIsLoading(true);

    let hasRequiredDocuments = false;

    let requiredDocuments = 0;


    alreadyUploadedDocuments?.forEach((d: any) => {
      if (OPTIONS_GROUP_OPTIONS_IDS.main.includes(d["collection_name"])) {
        requiredDocuments++;
      }
    });

    Object.keys(documents ?? {}).forEach((doc) => {
      if (OPTIONS_GROUP_OPTIONS_IDS.main.includes(doc)) {
        requiredDocuments++;
      }
    });

    if (requiredDocuments === OPTIONS_GROUP_OPTIONS_IDS.main.length) {
      hasRequiredDocuments = true;
    }

    if (hasRequiredDocuments) {
      uploadDocuments();
    } else {
      setError({ other: "Carica i documenti richiesti!" });
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 1500);

  };


  const renderAlreadyUploadedDocuments = () => {
    if (alreadyUploadedDocuments?.length === 0) return null;
    return (
      <div
        className="my-1 border-1 br-4 border-solid p-2 mb-15"
        style={{ borderColor: "var(--grey-dark)" }}
      >
        <div className="pb-2 px-0 text-lg font-600">
          <FormattedMessage id="added.docs" />

        </div>
        <div>
          {alreadyUploadedDocuments?.map((doc: any) =>
            renderSection(
              DOCUMENTS_OPTIONS_LABEL[doc["collection_name"]],
              doc["file_name"]
            )
          )}

        </div>
      </div>
    );
  };

  const renderUploadingDocuments = () => {
    if (!Object.keys(documents ?? {})?.length) return null;
    return (
      <div
        className="mt-3 my-1 border-1 br-4 border-solid p-2 mb-15"
        style={{ borderColor: "var(--grey-dark)" }}
      >
        <div className="pb-2 px-0 text-lg font-600">
          <FormattedMessage id="uploaded.docs" />

        </div>
        <div>
          {Object.entries(documents ?? {}).map(([key, value]) =>
            renderSection(
              DOCUMENTS_OPTIONS_LABEL[key],
              value.file.name,
              () => window.open(value.dataURL, "_blank"),
            )
          )}
        </div>
      </div>
    );
  };


  const ID_STRING = useIntl().formatMessage({ id: "uploadDocuments.ID" });
  const SC_STRING = useIntl().formatMessage({ id: "uploadDocuments.SC" });


  return (
    <div className="relative">
      <ConditionalLoader isLoading={isLoading} />
      <div className={getDataSubmitStateClass(isLoading)}>

        {/* start List of what to upload */}
        <div className="mb-30 mt-30">
          <div className="text-4xl font-600 mb-15 mt-20">
            <FormattedMessage id="step3.up3.title" />
          </div>
          <div className="text-2xl">
            <FormattedMessage id="uploadPersonalDocuments.text1" />
          </div>
          <div className="text-2xl">
            <FormattedMessage id="uploadPersonalDocuments.text2" />
          </div>
          <div className="text-2xl">
            <FormattedMessage id="uploadPersonalDocuments.text3" />
          </div><br />
          <div className="text-2xl">
            <FormattedMessage id="uploadPersonalDocuments.text4" />
          </div>
          <div className="text-2xl">
            <FormattedMessage id="uploadPersonalDocuments.text5" />
          </div><br />
          <div className="text-2xl">
            <FormattedMessage id="uploadPersonalDocuments.text6" />
          </div>
        </div>
        {/* end List of what to upload */}

        {renderAlreadyUploadedDocuments()}

        {renderUploadingDocuments()}

        {/* Render Identity Card if not already uploaded */}
        {
          alreadyUploadedDocuments?.filter((doc: any) => doc["collection_name"] === 'identity_document').length > 0
            ? null
            : <div className="mt-30">
              <GroupFileUpload
                name={ID_STRING}
                type="identity_document"
                statusRej={studentInfo?.media?.find((doc: any) => doc["collection_name"] === 'identity_document')?.status == 'REJ'}
                prevError={studentInfo?.media?.find((doc: any) => doc["collection_name"] === 'identity_document')?.status_reason}
                onRemoveDocument={removeDocument}
                onAddDocument={addDocument}
                fileCount={2}
              />
              <div className="borderUpload"></div>

            </div>
        }

        {/* Render Sanity Card if not already uploaded */}
        {
          alreadyUploadedDocuments?.filter((doc: any) => doc["collection_name"] === 'sanitary_card').length > 0
            ? null
            : <div className="mt-30">
              <GroupFileUpload
                name={SC_STRING}
                type="sanitary_card"
                statusRej={studentInfo?.media?.find((doc: any) => doc["collection_name"] === 'sanitary_card')?.status == 'REJ'}
                prevError={studentInfo?.media?.find((doc: any) => doc["collection_name"] === 'sanitary_card')?.status_reason}
                onRemoveDocument={removeDocument}
                onAddDocument={addDocument}
                fileCount={2}
              />
              {/* <div className="borderUpload"></div> */}

            </div>
        }


        {errorMap?.other && (
          <div className="text-danger font-500 mt-1">{errorMap?.other}</div>
        )}
        {
          Array.isArray(errorMap)
            ? <div className="text-danger font-500 mt-1">{errorMap[0]}</div>
            : null
        }
        <div className="mt-30 buttonsGoToTheRight">
          {/* <Button
            label={useIntl().formatMessage({ id: "button.save&continue" })}
            variant="habacus"
            onClick={onSaveAndContinue}
          /> */}
          <ButtonSave
            disabled={isLoading}
            label1={useIntl().formatMessage({ id: "button.saveAndGoOn1" })}
            label2={useIntl().formatMessage({ id: "button.saveAndGoOn2" })}
            variant="flexButtSave"
            onClick={onSaveAndContinue}
          />

        </div>
      </div>
    </div>
  );
};

export default UploadDocumentsComponent;
