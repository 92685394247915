import * as React from "react";
import "./loader.css";

interface Props {
  margin?: string;
}

// bootstrap loader
const Loader: React.FC<Props> = (props) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="lds-spinner" style={{ margin: props.margin || "23%" }}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default Loader;
