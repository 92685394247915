import Loader from "app/components/common/loader/loader";
import React from "react";

interface Props {
  isLoading: boolean;
}

const ConditionalLoader: React.FC<Props> = ({ isLoading }: Props) => {
  return isLoading ? (
    <div className="z-2 absolute h-full w-full center-x-y pointer-events-none">
      <Loader />
    </div>
  ) : null;
};

export default ConditionalLoader;
