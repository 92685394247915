import clsx from "clsx";
import React, { CSSProperties } from "react";

interface Props {
  type?: string;
  placeholder?: string;
  className?: string;
  label?: string;
  value?: string;
  invalid?: boolean;
  disabled?: boolean;
  invalidMessage?: string;
  onChange(value: string): void;
  maxLength?: number;
  pattern?: string;
  style?: CSSProperties;
}

const TextInput: React.FC<Props> = (props: Props) => {
  const {
    type = "text",
    placeholder,
    className,
    label,
    value,
    invalid,
    disabled,
    invalidMessage,
    maxLength,
    pattern,
    style,
  } = props;

  const onChange = (e: any) => {
    props.onChange(e.target.value);
  };

  return (
    <div className="form-floating">
      <input
        type={type}
        value={value || ""}
        className={clsx(
          "form-control form-control-sm",
          className,
          invalid ? "is-invalid" : ""
        )}
        placeholder={placeholder || label || "Enter..."}
        disabled={disabled === undefined ? false : disabled}
        onChange={onChange}
        maxLength={maxLength}
        pattern={pattern}
        tabIndex={0}
        style={style}
      />
      {label && (
        <label className="truncate" htmlFor="floatingInput">
          {label}
        </label>
      )}
      <div
        style={{ marginTop: ".25rem", fontSize: ".875em" }}
        className={invalid ? "invalid-feedback" : "invisible"}
      >
        {invalidMessage || "placeholder-text-when-invalidMessage-not-available"}
      </div>
    </div>
  );
};

export default TextInput;
