import next from 'app/icons/next.png';
import React from "react";

interface Props {
  size?: "sm" | "lg";
  outlined?: boolean;
  disabled?: boolean;
  label: React.ReactNode;
  variant: "primary" | "secondary" | "success" | "danger" | "info" | "habacus" | "yesTest" | "noTest" | "flexButtSave" | "flexButtContinue";
  style?: object;
  onClick(): void;
}

const ButtonContinue: React.FC<Props> = (props: Props) => {
  const { variant, label, disabled, outlined, size } = props;

  return (
    <button
      disabled={disabled}
      type="button"
      className={`btn 
        btn-${outlined ? "outline-" : ""}${variant} ${size ? `btn-${size}` : ""
        } `}
      onClick={props.onClick}
    >

      <img src={next} alt="" />
      <div>
        <div>{label}</div>
      </div>
    </button>
  );
};

export default ButtonContinue;
