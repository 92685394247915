import { IdLabel } from "app/types/common";
import React from "react";

interface Props {
  name: string;
  vertical?: boolean;
  value?: string | number;
  options: IdLabel[];
  onChange(selectedOption: string | number): void;
}

const RadioInput: React.FC<Props> = (props: Props) => {
  const { value, options, vertical } = props;

  const onChange = (e: any) => props.onChange(e.target.value);

  return (
    <div className={vertical ? "" : "center-y"}>
      {options?.map((option: IdLabel) => {
        return (
          <div
            key={`${props.name}~${String(option.id)}`}
            className={`cp form-check ${vertical ? "" : "mr-20"}`}
          >
            <input
              type="radio"
              tabIndex={0}
              id={`${props.name}~${String(option.id)}`}
              className="form-check-input"
              name={props.name}
              value={option.id}
              checked={option.id === value}
              onChange={onChange}
            />
            <label
              className="cp form-check-label boldy"
              htmlFor={`${props.name}~${String(option.id)}`}
            >
              {option.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default RadioInput;
