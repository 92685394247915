import { addPrefix } from "app/utils/utils";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  placeholder?: string;
  label?: string;
  value?: string;
  invalid?: boolean;
  invalidMessage?: string;
  onChange(value: string): void;
}

const DateInput: React.FC<Props> = (props: Props) => {
  const { value, label, invalid, invalidMessage } = props;

  const onDateChange = (date: any) => {
    if (!date) props.onChange("");
    else {
      props.onChange(
        `${date?.getFullYear()}-${addPrefix(date?.getMonth() + 1)}-${addPrefix(
          date?.getDate()
        )}`
      );
    }
  };

  return (
    <div className="form-floating">
      {/* {isMobile ? <h1>MOBILE</h1> : <h1>DESKTOP</h1>} */}
      <DatePicker
        withPortal
        // isClearable
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={30}
        className="date-picker form-control"
        tabIndex={0}
        // maxDate={new Date()}
        // maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 1, -192)}
        wrapperClassName={`form-control ${invalid ? "is-invalid" : ""}`}
        dateFormat="dd-MM-yyyy"
        placeholderText={props.placeholder}
        selected={value ? new Date(value?.split(" ")[0]) : undefined}
        onYearChange={onDateChange}
        onMonthChange={onDateChange}
        onChange={onDateChange}

        openToDate={moment(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDay()), 'DD-MM-YYYY').toDate()}
        maxDate={moment(new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDay()), 'DD-MM-YYYY').toDate()}
        minDate={moment(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDay()), 'DD-MM-YYYY').toDate()}
        calendarStartDay={1}
      />
      {!value && label && (
        <label className="truncate" htmlFor="floatingSelect">
          {label}
        </label>
      )}
      <div
        style={{ marginTop: ".25rem", fontSize: ".875em" }}
        className={invalid ? "invalid-feedback" : "invisible"}
      >
        {invalidMessage || "placeholder-text-when-invalidMessage-not-available"}
      </div>
    </div>
  );
};

export default DateInput;
