import { IdLabel } from "app/types/common";
import React from "react";
import { useIntl } from "react-intl";
import Select, { ActionMeta } from 'react-select';



interface Props {
  value?: Array<string>;
  groupsLabel?: Map<string, string>;
  groups?: Map<string, Array<IdLabel>>;
  options?: IdLabel[];
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  onChange(selectedOptions: Array<string>): void;
  isDisabled?: boolean;
}
interface GroupedOption {
  readonly label: string,
  readonly options: Option[],
}

interface Option {
  readonly value: string,
  readonly label: string,
}


const AdvancedMultiSelect: React.FC<Props> = (props: Props) => {
  const { label, options, groups, groupsLabel } = props;

  const locale = useIntl().locale === 'IT' ? 'it' : 'en';

  // const onChange = (option: any | null, actionMeta: ActionMeta<any>) => props.onChange(option?.value);
  const onChange = (options: any | null, actionMeta: ActionMeta<any>) => props.onChange(Array.isArray(options) ? options.map((x) => x.value) : []);

  const getValue = (values: Array<string> | undefined): Array<Option> | null => {

    const returnValue = [];
    for (let i = 0; i < (options ?? []).length; i++) {

      const option = options![i];

      for (let j = 0; j < (values ?? []).length; j++) {
        const val = values![j];
        if ((option.id + "") === (val + "")) {
          returnValue.push({ value: option.id + '', label: option.label['name_' + locale] });
        }

      }
    }
    return returnValue;


  }

  const styles = {

    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#003da6' : (state.isFocused ? '#ddebff' : '#fff'),
    }),
  }




  const getOptions = (): GroupedOption[] => {
    if (groups && groupsLabel) {
      const returnValue: GroupedOption[] = [];

      Object.keys(groups).forEach((groupId: string, index: number) => {


        const group: GroupedOption = {
          label: groupId,
          options: []

        };
        groups[groupId].forEach((option: IdLabel) => {

          group.options.push({ value: option.id + '', label: option.label['name_' + locale] });
        });
        returnValue.push(group);
      });


      return returnValue;

    } else {

      const returnValue: GroupedOption[] = [];
      const group: GroupedOption = {
        label: '',
        options: []

      };

      options?.forEach((option) => {
        group.options.push({ value: option.id + '', label: option.label['name_' + locale] });
      });
      returnValue.push(group);

      return returnValue;


    }
  };


  return (
    <div className="form-floating selectCompStyle">
      <Select
        tabIndex={0}
        options={getOptions()}
        value={getValue(props.value)}
        className={`cp form-floating ${props.errorMessage ? "is-invalid borderRed" : ""}`}
        id="floatingSelect"
        placeholder={label}
        onChange={onChange}
        isMulti
        isClearable
        styles={styles}
        isDisabled={props.isDisabled}
      />

      <div
        style={{ marginTop: ".25rem", fontSize: ".875em", borderColor: "red" }}
        className={props.errorMessage ? "pl-5 text-danger" : "invisible"}
      >
        {props.errorMessage || '&nbsp;'}
      </div>
    </div>
  );
};

export default AdvancedMultiSelect;
