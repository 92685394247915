import { Step, StepConnector, StepLabel, Stepper, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';


const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: "#003da6"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#003da6"
    }
  },
  line: {
    borderColor: "#EAEAF0",
    borderTopWidth: 2,
    borderRadius: 1
  }
})(StepConnector);


const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#EAEAF0",
    display: "flex",
    height: 12,
    width: 12,
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#EAEAF0"

  },
  active: {
    color: "#003da6",
    backgroundColor: "#003da6",
    width: 12,
    height: 12,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
  },
  completed: {
    color: "#003da6",
    backgroundColor: "#FFFFFF",
    zIndex: 1,
    fontSize: 22,
    borderRadius: "50%",
  }
});
function QontoStepIcon(props: { active: any; completed: any; }) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}




const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

interface Props {
  stepNumber: number;
  currentStepNumber: number;
}

const StepperHeader: React.FC<Props> = (props: Props) => {

  const classes = useStyles();
  // const theme = useTheme();

  const { stepNumber, currentStepNumber } = props;

  const showSteps = () => {
    const returnValue = [];
    for (let i = 0; i < stepNumber; i++) {
      returnValue.push(
        <Step key={i}>
          <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
        </Step>

      );

    }
    return returnValue;
  }

  return (
    stepNumber === 0 ? null :


      <Stepper
        connector={<QontoConnector />}
        className={classes.root}
        activeStep={currentStepNumber}
      >
        {
          showSteps()
        }
      </Stepper>
  );
}

export default StepperHeader;


