import React from "react";

interface Props {
  label?: string;
  checked?: boolean;
  onChange(checked: boolean): void;
}

const CheckBox: React.FC<Props> = (props: Props) => {
  const { checked, label } = props;

  const onChange = (e: any) => props.onChange(e.target.checked);

  return (
    <div className="cp form-check">
      <input
        id="check-box-input"
        type="checkbox"
        checked={checked}
        className="form-check-input"
        onChange={onChange}
      />
      {label && (
        <label className="cp form-check-label boldy" htmlFor="check-box-input">
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
