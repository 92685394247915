import { IdLabel } from "app/types/common";
import React from "react";

export interface StudentInfoContextType {
  isCreationMode?: boolean;
  allStudents?: any[];
  student?: any;
  studentApplication?: any;
  accessRequirement?: any;
  // schoolDegrees?: IdLabel[];
  // degreeClass?: IdLabel[];
  courseTypes?: IdLabel[];
  // schoolCourse?: IdLabel[];
  // schools?: IdLabel[];
  citizenships?: IdLabel[];
  nations?: IdLabel[];
  phoneCodeNations?: IdLabel[];
  provinces?: IdLabel[];
  // downloadSchools(): void;
  ambassador?: any;
  getAmbassadorFromCode(code: string): Promise<boolean>;

}

interface ContextFunctions {
  setMetadata(metadata: StudentInfoContextType): void;
}

const StudentInfoContext = React.createContext(
  {} as StudentInfoContextType & ContextFunctions
);
export default StudentInfoContext;
