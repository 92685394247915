import { ORIGIN_TOKEN_PARAM_NAME } from "app/context/studentInfoProvider";
import EnglishFlag from "app/icons/en.png";
import ItalianFlag from "app/icons/it.png";
import { getToken } from "app/service/apiService";
import { getQueryParam } from "app/utils/utils";
import clsx from "clsx";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ButtonContinue from "../common/buttonContinue";

//http://localhost:3001/user-information?token_or=BPI&referral=BPI&referral_code=946901ea-d3a8-4e90-af60-07f141b49b3f&token=0b0d3485-a277-4164-8ff4-b62907a7f58c&token_p=6af34d67-2782-408f-8216-b8e6e2018147
//testbpi
interface Props {
  onSubmit(taxCode: string): void;
  error?: string;
  clearError(): void;
  onChangelanguage(lang: string): void;
}

const StudentWelfareDisclaimer: React.FC<Props> = (props: Props) => {
  const [taxCode, setTaxCode] = React.useState<string>("");
  const onSubmit = () => {
    props.onSubmit(taxCode);
  };

  useEffect(() => {
    getToken();
  }, [])

  const onTaxCodeChange = (e: any) => {
    const value = e.target.value;
    setTaxCode(value);
    props.error && props.clearError();
  };

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const intl = useIntl();
  const getLanguageSelectedClass = (lang: string) => {
    if (lang === intl.locale) {
      return "language languageSelected";
    }
    return "language";
  }

  const invalid = Boolean(props.error);

  const tokenPfromUrl = getQueryParam(ORIGIN_TOKEN_PARAM_NAME);

  // const renderStudentTaxCodeInputTitle = () => {
  //   return <div>
  //     <div className="text-5xl font-600 mb-30" style={{ textAlign: 'center' }}>
  //       <FormattedMessage id="studentTaxCodeInput.poste.title" />
  //     </div>
  //     <div className="text-3xl font-600 mb-15" style={{ textAlign: 'center' }}>
  //       <FormattedMessage id="studentTaxCodeInput.poste.subtitle" />
  //     </div>

  //   </div>;

  // }

  const renderStudentTaxCodeInputText = () => {
    // return <div>
    //   <div className="text-xl mb-30 disclaimer">
    //     <FormattedMessage id="studentTaxCodeInput.poste.text1" />
    //   </div>dasd
    //   <div className="text-xl mb-30 disclaimer">
    //     <FormattedMessage id="studentTaxCodeInput.poste.text2" />
    //   </div>asd
    //   <div className="text-xl mb-30 disclaimer">
    //     <b><FormattedMessage id="studentTaxCodeInput.poste.text3" /></b>
    //   </div>

    // </div>;
    return <div>
      <div className="text-5xl font-600 mb-30" style={{ textAlign: 'center' }}>
        <FormattedMessage id="studentDisclaimer.poste.title" />
      </div>
      <div className="text-3xl font-600 mb-30" style={{ textAlign: 'center' }}>
        <FormattedMessage id="studentDisclaimer.poste.subtitle" />
      </div>
      <div className="text-2xl  mb-15 " style={{ textAlign: 'center' }}>
        <span className={getLanguageSelectedClass('IT')} onClick={() => props.onChangelanguage('IT')}>
          <img alt="flag"
            height={35}
            src={ItalianFlag}
          />
        </span>
        <span> &nbsp;&nbsp;&nbsp; </span>
        <span className={getLanguageSelectedClass('EN')} onClick={() => props.onChangelanguage('EN')}>
          <img alt="flag"
            height={35}
            src={EnglishFlag}
          />
        </span>
      </div>
      <br />
      <div className="text-xl  disclaimer">
        <FormattedMessage id="studentDisclaimer.poste.text1" />
      </div>
      <div className="text-xl disclaimer">
        <FormattedMessage id="studentDisclaimer.poste.text2" />
      </div>
      <div className="text-xl disclaimer">
        <FormattedMessage id="studentDisclaimer.poste.text3" />
      </div>
      <br /><br />

    </div>;
  }

  return (
    <div>

      {/* {renderStudentTaxCodeInputTitle()}
      <div className="text-2xl  mb-15 " style={{ textAlign: 'center' }}>
        <span className={getLanguageSelectedClass('IT')} onClick={() => props.onChangelanguage('IT')}>
          <img alt="flag"
            height={35}
            src={ItalianFlag}
          />
        </span>
        <span> &nbsp;&nbsp;&nbsp; </span>
        <span className={getLanguageSelectedClass('EN')} onClick={() => props.onChangelanguage('EN')}>
          <img alt="flag"
            height={35}
            src={EnglishFlag}
          />
        </span>
      </div>

      <br /> */}
      {renderStudentTaxCodeInputText()}

      <div className="form-floating">
        <input
          type={'password'}
          onKeyUp={handleKeyUp}
          value={taxCode || ""}
          className={clsx(
            "form-control form-control-sm",
            invalid ? "is-invalid" : ""
          )}
          placeholder={useIntl().formatMessage({ id: "studentTaxCodeInput.lable" })}
          onChange={onTaxCodeChange}
          maxLength={20}
          tabIndex={0}
        />
        <label className="truncate" htmlFor="floatingInput">
          {useIntl().formatMessage({ id: "studentTaxCodeInput.lable" })}
        </label>
        <div
          style={{ marginTop: ".25rem", fontSize: ".875em" }}
          className={invalid ? "invalid-feedback" : "invisible"}
        >
          {props.error || "placeholder-text-when-invalidMessage-not-available"}
        </div>
      </div>
      <br />
      {/* <div className="text-xl disclaimer">
        <b><FormattedMessage id="studentDisclaimer.poste.text4" /></b>
      </div> */}
      <div className="text-xl  disclaimer">
        <FormattedMessage id="studentDisclaimer.poste.text5" /> <a href="mailto: promostuditiprestitobp@habacus.com"><FormattedMessage id="studentDisclaimer.poste.email" /></a>.
      </div>

      <div className="mt-30 flex justify-end">
        <ButtonContinue variant="flexButtContinue" onClick={onSubmit} label={useIntl().formatMessage({ id: "button.goOn" })} />
      </div>
    </div>
  );
};

export default StudentWelfareDisclaimer;
