import { IdLabel } from "app/types/common";
import React from "react";
// import Select, { ActionMeta } from 'react-select'
import CreatableSelect from 'react-select/creatable';


interface Props {
  value?: string;
  groupsLabel?: Map<string, string>;
  groups?: Map<string, Array<IdLabel>>;
  options?: IdLabel[];
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  onChange(selectedOption: string): void;
  // ??????onInputChage()
}
interface GroupedOption {
  readonly label: string,
  readonly options: Option[],
}

interface Option {
  readonly value: string,
  readonly label: string,
}


const SingleSelectCreatable: React.FC<Props> = (props: Props) => {
  const { label, options, groups, groupsLabel } = props;

  // const selectOption = useIntl().formatMessage({ id: "input.select.placeholder" });

  // const onChange = (newValue: any, actionMeta: any) => {
  // };

  // const [input, setInput] = React.useState<string>();
  // const onInputChange = (inputValue: string, actionMeta: any) => {
  //   setInput(inputValue);

  // };

  const onChange = (option: any | null) => props.onChange(option?.value);

  // const renderOptions = () => {
  //   if (groups && groupsLabel) {
  //     return Object.keys(groups).map((groupId: string, index: number) => {
  //       return (
  //         <>
  //           {index === 0 && !props.value ? (
  //             <option key={selectOption} disabled value="section-option">
  //               {placeholder || selectOption}
  //             </option>
  //           ) : null}
  //           <optgroup key={groupId} label={groupsLabel[groupId]}>
  //             {groups[groupId].map((option: IdLabel) => (
  //               <option key={option.id} value={option.id}>
  //                 {option.label}
  //               </option>
  //             ))}
  //           </optgroup>
  //         </>
  //       );
  //     });
  //   } else {
  //     return (
  //       <>
  //         {!props.value && (
  //           <option key={selectOption} disabled value="section-option">
  //             {placeholder || selectOption}
  //           </option>
  //         )}
  //         {options?.map((option) => (
  //           <option key={option.id} value={option.id}>
  //             {option.label}
  //           </option>
  //         ))}
  //       </>
  //     );
  //   }
  // };

  const styles = {



    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#003da6' : (state.isFocused ? '#ddebff' : '#fff'),
    }),
  }


  const getValue = (val: string | undefined | null): Option | null => {

    if (val === undefined || val === null || val.trim() === '') {
      return null;
    }

    for (let i = 0; i < (options ?? []).length; i++) {

      const option = options![i];
      if ((option.id + "") === (val + "")) {
        return { value: option.id + '', label: option.label };
      }
    }



    return new Option(val, val);


  }




  const getOptions = (): GroupedOption[] => {
    if (groups && groupsLabel) {
      const returnValue: GroupedOption[] = [];

      Object.keys(groups).forEach((groupId: string, index: number) => {


        const group: GroupedOption = {
          label: groupId,
          options: []

        };
        groups[groupId].forEach((option: IdLabel) => {

          group.options.push({ value: option.id + '', label: option.label });
        });
        returnValue.push(group);
      });


      return returnValue;

      // return (
      //   <>
      //     {index === 0 && !props.value ? (
      //       <option key={selectOption} disabled value="section-option">
      //         {placeholder || selectOption}
      //       </option>
      //     ) : null}
      //     <optgroup key={groupId} label={groupsLabel[groupId]}>
      //       {groups[groupId].map((option: IdLabel) => (
      //         <option key={option.id} value={option.id}>
      //           {option.label}
      //         </option>
      //       ))}
      //     </optgroup>
      //   </>
      // );
    } else {

      const returnValue: GroupedOption[] = [];
      const group: GroupedOption = {
        label: '',
        options: []

      };

      options?.forEach((option) => {
        group.options.push({ value: option.id + '', label: option.label });
      });
      returnValue.push(group);

      return returnValue;



      // return (
      //   <>
      //     {!props.value && (
      //       <option key={selectOption} disabled value="section-option">
      //         {placeholder || selectOption}
      //       </option>
      //     )}
      //     {options?.map((option) => (
      //       <option key={option.id} value={option.id}>
      //         {option.label}
      //       </option>
      //     ))}
      //   </>
      // );
    }
  };


  return (
    <div className="form-floating selectCompStyle">
      <CreatableSelect
        tabIndex={0}
        options={getOptions()}
        // defaultValue={props.value || "section-option"}
        value={getValue(props.value)}
        className={`cp form-floating ${props.errorMessage ? "is-invalid borderRed" : ""}`}
        id="floatingSelect"
        placeholder={label}
        onChange={onChange}
        // onInputChange={onInputChange}
        isClearable
        styles={styles}

      />
      {/* {label && (
        <label className="truncate" htmlFor="floatingSelect">
          {label}
        </label>
      )} */}
      <div
        style={{ marginTop: ".25rem", fontSize: ".875em" }}
        className={props.errorMessage ? "pl-5 text-danger" : "invisible"}
      >
        {props.errorMessage || '&nbsp;'}
      </div>
    </div>
  );
};

export default SingleSelectCreatable;
