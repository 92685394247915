import floppy from 'app/icons/icons8-salva-100.png';
import React from "react";

interface Props {
  size?: "sm" | "lg";
  outlined?: boolean;
  disabled?: boolean;
  label1: React.ReactNode;
  label2: React.ReactNode;
  variant: "primary" | "secondary" | "success" | "danger" | "info" | "habacus" | "yesTest" | "noTest" | "flexButtSave";
  style?: object;
  onClick(): void;
}

const ButtonSave: React.FC<Props> = (props: Props) => {
  const { variant, label1, label2, disabled, outlined, size } = props;

  return (
    <button
      disabled={disabled}
      type="button"
      className={`btn 
        btn-${outlined ? "outline-" : ""}${variant} ${size ? `btn-${size}` : ""
        } `}
      onClick={props.onClick}
    >

      <img src={floppy} alt="" />
      <div>
        <div>{label1}</div>
        <div>{label2}</div>
      </div>
    </button>
  );
};

export default ButtonSave;
