import React from "react";
//import FileUpload from "app/components/common/fileUploadGroup";
//import FileUpload from "app/components/common/uploadSingle";
//import FileUpload from "app/components/common/uploadDouble";
import UploadCard from "app/components/common/uploadCard";
import { fileToImageURL, generatePdfFromImages, MyFile } from "app/utils/imagesToPdf";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
    name: string,
    type: string,
    fileCount: number,
    statusRej: boolean,
    prevError: string | null | undefined,

    onRemoveDocument(type: string): void;
    onAddDocument(file: MyFile, type: string): void;
}

enum UploadMethod {
    Single,
    Multiple,
}

interface FileDictionary {
    [position: number]: MyFile;
}

const GroupFileUpload: React.FC<Props> = (props: Props) => {

    const [uploadMethod, setUploadMethod] = React.useState<UploadMethod>(UploadMethod.Single);
    const [files, setFiles] = React.useState<FileDictionary>({});

    const onChangeTab = (event: React.ChangeEvent<HTMLInputElement>, uploadMethod: UploadMethod) => {
        setUploadMethod(uploadMethod);
        setFiles([]);
    }

    const onClickTab = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>, uploadMethod: UploadMethod) => {
        props.onRemoveDocument(props.type);
        setUploadMethod(uploadMethod);
        setFiles([]);
    }

    const removeFileInPosition = (position: number) => {
        const { [position]: deleted, ...objectWithoutDeletedProp } = files;
        setFiles(objectWithoutDeletedProp);

        props.onRemoveDocument(props.type);
    }

    const addFileInPosition = async (myFile: MyFile | undefined | null, position: number) => {
        if (myFile !== undefined && myFile !== null) {

            props.onRemoveDocument(props.type);

            const myFiles = Object.assign({ [position]: myFile }, files);
            setFiles(myFiles);

            if (uploadMethod === UploadMethod.Single) {
                props.onAddDocument(myFile, props.type);
            } else {
                for (let i = 0; i < fileCount; i++) {
                    if (!myFiles[i]) {
                        return;
                    }
                }

                const images = [];
                for (let i = 0; i < fileCount; i++) {
                    images.push(await fileToImageURL(myFiles[i].file));
                }

                try {
                    const mergeFile = generatePdfFromImages(images, props.type);
                    props.onAddDocument({ file: mergeFile, dataURL: '' /*await fileToDataURL(mergeFile)*/ }, props.type);
                }
                catch (e) {
                    alert('Errore nella conversione');
                }
            }
        }
    }

    // const dataURItoFile = (dataURI: string, fileName: string): File => {
    //     // convert base64/URLEncoded data component to raw binary data held in a string
    //     var byteString;
    //     if (dataURI.split(',')[0].indexOf('base64') >= 0)
    //         byteString = atob(dataURI.split(',')[1]);
    //     else
    //         byteString = unescape(dataURI.split(',')[1]);

    //     // separate out the mime component
    //     var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    //     // write the bytes of the string to a typed array
    //     var ia = new Uint8Array(byteString.length);
    //     for (var i = 0; i < byteString.length; i++) {
    //         ia[i] = byteString.charCodeAt(i);
    //     }

    //     const blob: any = new Blob([ia], { type: mimeString });

    //     blob.lastModifiedDate = new Date();
    //     blob.name = fileName;

    //     return blobToFile(blob, fileName)
    // }

    // const blobToFile = (blob: Blob, fileName: string): File => {
    //     var b: any = blob;
    //     //A Blob() is almost a File() - it's just missing the two properties below which we will add
    //     b.lastModifiedDate = new Date();
    //     b.name = fileName + ".pdf";

    //     //Cast to a File() type
    //     return blob as File;
    // }

    const { name, fileCount, } = props;

    const filesToUpload = {
        'identity_document':
        {
            "0": [useIntl().formatMessage({ id: "uploadDocuments.singleFileFrontBack" })],
            "1": [useIntl().formatMessage({ id: "uploadDocuments.front" }), useIntl().formatMessage({ id: "uploadDocuments.back" })],
        },
        "sanitary_card": {
            "0": [useIntl().formatMessage({ id: "uploadDocuments.singleFileFrontBack" })],
            "1": [useIntl().formatMessage({ id: "uploadDocuments.front" }), useIntl().formatMessage({ id: "uploadDocuments.back" })],
        },
        "proxy": {
            "0": [useIntl().formatMessage({ id: "uploadDocuments.singleFileAllPages" })],
            "1": ["Pag. 1", "Pag. 2", "Pag. 3", "Pag. 4", "Pag. 5", "Pag. 6", "Pag. 7", "Pag. 8", "Pag. 9", "Pag. 10"],
        },
        'concern_payments_ok_student':
        {
            "0": [useIntl().formatMessage({ id: "uploadDocuments.singleFileFrontBack" })],
            "1": [useIntl().formatMessage({ id: "uploadDocuments.front" }), useIntl().formatMessage({ id: "uploadDocuments.back" })],
        },
        "learning_agreement_student": {
            "0": [useIntl().formatMessage({ id: "uploadDocuments.singleFileFrontBack" })],
            "1": [useIntl().formatMessage({ id: "uploadDocuments.front" }), useIntl().formatMessage({ id: "uploadDocuments.back" })],
        },
        'final_grade_student':
        {
            "0": [useIntl().formatMessage({ id: "uploadDocuments.singleFileFrontBack" })],
            "1": [useIntl().formatMessage({ id: "uploadDocuments.front" }), useIntl().formatMessage({ id: "uploadDocuments.back" })],
        },
        "attended_frequency_student": {
            "0": [useIntl().formatMessage({ id: "uploadDocuments.singleFileFrontBack" })],
            "1": [useIntl().formatMessage({ id: "uploadDocuments.front" }), useIntl().formatMessage({ id: "uploadDocuments.back" })],
        },
        'at_least_n_credits_student':
        {
            "0": [useIntl().formatMessage({ id: "uploadDocuments.singleFileFrontBack" })],
            "1": [useIntl().formatMessage({ id: "uploadDocuments.front" }), useIntl().formatMessage({ id: "uploadDocuments.back" })],
        },
        "studies_plan_student": {
            "0": [useIntl().formatMessage({ id: "uploadDocuments.singleFileFrontBack" })],
            "1": [useIntl().formatMessage({ id: "uploadDocuments.front" }), useIntl().formatMessage({ id: "uploadDocuments.back" })],
        },

    };

    const showCorrectTab = () => {
        const returnValue = [];

        for (let i = 0; i < (uploadMethod === UploadMethod.Single ? 1 : fileCount); i++) {
            returnValue.push(
                <UploadCard
                    key={uploadMethod + '_' + i}
                    accept={uploadMethod === UploadMethod.Single ? ['.pdf', '.gif', '.jpg', '.jpeg', '.png'] : ['.gif', '.jpg', '.jpeg', '.png']}
                    maxFileSize={5000000000}
                    file={files[i]}
                    name={filesToUpload[props.type][uploadMethod + ''][i]}
                    onChange={addFileInPosition}
                    onRemoveFile={removeFileInPosition}
                    position={i} />
            );
        }

        return returnValue;
    }
    let errorComp = null;

    if (props.statusRej) {
        if (props.prevError) {
            errorComp =
                <div style={{ color: "red", marginBottom: "10px" }}>
                    <h3 className="text-xl font-500"><FormattedMessage id="uploadDocuments.errorMex" />:</h3>
                    {props.prevError}
                </div>;
        } else {
            errorComp =
                <div style={{ color: "red", marginBottom: "10px" }}>
                    <h3 className="text-xl font-500"><FormattedMessage id="uploadDocuments.errorMexGeneric" /></h3>
                </div>;

        }
    }
    return (
        <>
            <div> <h3 className="text-2xl font-600">{name}</h3> </div>
            {errorComp}
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">

                <input
                    type="radio"
                    className="btn-check"
                    onChange={(e) => onChangeTab(e, UploadMethod.Single)}
                    checked={uploadMethod === UploadMethod.Single}
                    value={UploadMethod.Single}
                    autoComplete="off" />
                <label className="btn btn-outline-primary firstLabel"
                    onClick={(e) => onClickTab(e, UploadMethod.Single)} >{useIntl().formatMessage({ id: "uploadDocuments.singleFile" })}</label>

                <input
                    type="radio"
                    className="btn-check"
                    onChange={(e) => onChangeTab(e, UploadMethod.Multiple)}
                    checked={uploadMethod === UploadMethod.Multiple}
                    value={UploadMethod.Multiple}
                    autoComplete="off" />
                <label className="btn btn-outline-primary secondLabel"
                    onClick={(e) => onClickTab(e, UploadMethod.Multiple)} >{useIntl().formatMessage({ id: "uploadDocuments.multipleFiles" })}</label>

            </div>

            <div className="uploadCardGroup">


                {showCorrectTab()}
            </div>
        </>
    );
};

export default GroupFileUpload;
