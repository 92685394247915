import { ORIGIN_TOKEN_PARAM_NAME } from "app/context/studentInfoProvider";
import EnglishFlag from "app/icons/en.png";
import ItalianFlag from "app/icons/it.png";
import { getToken } from "app/service/apiService";
import { getQueryParam } from "app/utils/utils";
import clsx from "clsx";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ButtonContinue from "../common/buttonContinue";


interface Props {
  onSubmit(taxCode: string): void;
  error?: string;
  clearError(): void;
  onChangelanguage(lang: string): void;
}

const StudentTaxCodeInput: React.FC<Props> = (props: Props) => {
  const [taxCode, setTaxCode] = React.useState<string>("");
  const onSubmit = () => {
    props.onSubmit(taxCode);
  };

  useEffect(() => {
    getToken();
  }, [])

  const onTaxCodeChange = (e: any) => {
    const value = e.target.value;
    setTaxCode(value);
    props.error && props.clearError();
  };

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const intl = useIntl();
  const getLanguageSelectedClass = (lang: string) => {
    if (lang === intl.locale) {
      return "language languageSelected";
    }
    return "language";
  }

  const invalid = Boolean(props.error);

  const tokenPfromUrl = getQueryParam(ORIGIN_TOKEN_PARAM_NAME);

  const renderStudentTaxCodeInputTitle = () => {
    if (tokenPfromUrl == 'BPI') {
      return <div>
        <div className="text-5xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentTaxCodeInput.poste.title" />
        </div>
        <div className="text-3xl font-600 mb-15" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentTaxCodeInput.poste.subtitle" />
        </div>

      </div>;
    } else if (tokenPfromUrl == 'ambassador') {
      return <div>
        <div className="text-5xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentTaxCodeInput.ambassador.title" />
        </div>
        <div className="text-3xl font-600 mb-15" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentTaxCodeInput.ambassador.subtitle" />
        </div>

      </div>;
    } else {
      return <div>
        <div className="text-5xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentTaxCodeInput.title" />
        </div>
        <div className="text-3xl font-600 mb-15" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentTaxCodeInput.subtitle" />
        </div>
      </div>;
    }
  }

  const renderStudentTaxCodeInputText = () => {
    if (tokenPfromUrl == 'BPI') {
      return <div>
        <div className="text-xl mb-30 disclaimer">
          <FormattedMessage id="studentTaxCodeInput.poste.text1" />
        </div>
        <div className="text-xl mb-30 disclaimer">
          <FormattedMessage id="studentTaxCodeInput.poste.text2" />
        </div>
        <div className="text-xl mb-30 disclaimer">
          <b><FormattedMessage id="studentTaxCodeInput.poste.text3" /></b>
        </div>

        <br />

      </div>;
    } else if (tokenPfromUrl == 'ambassador') {
      return <div>
        <div className="text-xl mb-30 disclaimer">
          <FormattedMessage id="studentTaxCodeInput.ambassador.text1" />
        </div>
        <div className="text-xl mb-30 disclaimer">
          <FormattedMessage id="studentTaxCodeInput.ambassador.text2" />
        </div>
        <div className="text-xl  mb-30 disclaimer">
          <b><FormattedMessage id="studentTaxCodeInput.ambassador.text3" /></b>
        </div>

      </div>;
    } else {
      return <div>
        <div className="text-xl mb-30 disclaimer">
          <FormattedMessage id="studentTaxCodeInput.text1" />
        </div>
        <div className="text-xl mb-30 disclaimer">
          <FormattedMessage id="studentTaxCodeInput.text2" />
        </div>
        <div className="text-xl mb-30 disclaimer">
          <b><FormattedMessage id="studentTaxCodeInput.text3" /></b>
        </div>
      </div>;
    }
  }


  return (
    <div>

      {renderStudentTaxCodeInputTitle()}
      <div className="text-2xl  mb-15 " style={{ textAlign: 'center' }}>
        <span className={getLanguageSelectedClass('IT')} onClick={() => props.onChangelanguage('IT')}>
          <img alt="flag"
            height={35}
            src={ItalianFlag}
          />
        </span>
        <span> &nbsp;&nbsp;&nbsp; </span>
        <span className={getLanguageSelectedClass('EN')} onClick={() => props.onChangelanguage('EN')}>
          <img alt="flag"
            height={35}
            src={EnglishFlag}
          />
        </span>
      </div>

      <br />
      {renderStudentTaxCodeInputText()}

      <div className="form-floating">
        <input
          type={'password'}
          onKeyUp={handleKeyUp}
          value={taxCode || ""}
          className={clsx(
            "form-control form-control-sm",
            invalid ? "is-invalid" : ""
          )}
          placeholder={useIntl().formatMessage({ id: "studentTaxCodeInput.lable" })}
          onChange={onTaxCodeChange}
          maxLength={20}
          tabIndex={0}
        />
        <label className="truncate" htmlFor="floatingInput">
          {useIntl().formatMessage({ id: "studentTaxCodeInput.lable" })}
        </label>
        <div
          style={{ marginTop: ".25rem", fontSize: ".875em" }}
          className={invalid ? "invalid-feedback" : "invisible"}
        >
          {props.error || "placeholder-text-when-invalidMessage-not-available"}
        </div>
      </div>
      <div className="mt-30 flex justify-end">
        <ButtonContinue variant="flexButtContinue" onClick={onSubmit} label={useIntl().formatMessage({ id: "button.continue" })} />

      </div>
    </div>
  );
};

export default StudentTaxCodeInput;
