import AddIcon from '@material-ui/icons/Add';
import HabacusIcon from "app/icons/PDFplaceholderMart.png";
import { MyFile } from 'app/utils/imagesToPdf';
import React from 'react';


import Resizer from "react-image-file-resizer";

interface Props {
    name?: string;
    text?: string;

    accept: string[];
    maxFileSize: number;

    file: MyFile | undefined | null;
    position: number;

    onChange: (file: MyFile | undefined | null, position: number) => void;
    onRemoveFile: (position: number) => void;
}

const UploadCard: React.FC<Props> = (props: Props) => {


    const { name, text, position, accept } = props;

    const hasExtension = (fileName: string): boolean => {
        var pattern = '(' + props.accept.join('|').replace(/\./g, '\\.') + ')$';
        return new RegExp(pattern, 'i').test(fileName);
    }

    const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        // event = null;
    }

    const onDrop = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0];
        if (!file) {
            return;
        }

        // Check for file extension
        if (!hasExtension(file.name)) {
            alert("Extension not supported");
            return;
        }
        // Check for file size
        if (file.size > props.maxFileSize) {
            alert("File too large");
            return;
        }
        console.log('originalSize ', file.size);

        const newFile = file.type === 'application/pdf'
            ? file
            : await resizeFile(file);

        console.log('NeworiginalSize ', newFile.size);


        const fileReaded = await readFile(newFile);

        props.onChange(fileReaded, props.position);
    }

    const resizeFile = (file: File) => new Promise<File>(resolve => {
        Resizer.imageFileResizer(file, 900, 900, 'JPEG', 70, 0,
            uri => {
                // fileCreationFromURL(uri, file.name);
                resolve(uri as File);
            },
            'file'
        );
    });


    const readFile = (file: File): Promise<MyFile> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = (e: ProgressEvent<FileReader>) => {
                var dataURL = e.target?.result;
                if (typeof dataURL === 'string') {
                    dataURL = dataURL?.replace(";base64", ';name=' + file.name + ';base64');
                }
                resolve({ file: file, dataURL: dataURL as string });
            };
            reader.readAsDataURL(file);
        });
    }

    const removeImage = () => {
        props.onRemoveFile(position);
    }

    const renderPreview = () => {
        if (!props.file) {
            return null;
        }
        return (
            <div className="deleteAndImg">
                <button className="btn btn-danger" onClick={removeImage}> Delete</button>
                {
                    props.file.file.name.endsWith('pdf')
                        ?
                        // null
                        <img
                            alt="Habacus Icon"
                            width={200} height={200}
                            // width={auto}
                            src={HabacusIcon}
                            className="pdfImageClass"
                        />

                        :
                        <img src={props.file.dataURL as string}
                            className="img-thumbnail float-right"
                            alt="preview"
                            width={200} height={200} />
                }

            </div>
        );
    }

    return (
        <div key={name} className="uploadCard">
            {name != null ? <h4 className="text-xl font-600  lead text-muted ">{name}</h4> : null}
            {text != null ? <p>{text}</p> : null}
            {!props.file?.file
                ?
                <div className="uploadWrapper">



                    <div className="file-upload-w">

                        <input
                            type="file"
                            name={name ?? position + ''}
                            accept={accept.join(',')}
                            onChange={onDrop}
                            onClick={onClick}
                            className="inputUploadFile" // btn btn-secondary   -  custom-file-input btn btn-secondary
                        />


                        <AddIcon className="addIconClass" style={{ fontSize: 50 }} />
                        {/* <img
                            alt="Habacus Icon"
                            height={50}
                            // width={auto}
                            src={HabacusIconArrowUp}
                            className="mr-5 uploadImage"
                        /> */}
                    </div>


                </div>
                : renderPreview()}
        </div>
    );
};


export default UploadCard;