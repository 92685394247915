import Button from "app/components/common/button";
import CheckBox from "app/components/common/checkbox";
import DateInput from "app/components/common/dateInput";
import ConditionalLoader from "app/components/common/loader/conditionalLoader";
import TextInput from "app/components/common/textInput";
import StudentInfoContext from "app/context/studentContext";
import {
  AMBASSADOR_REFERRAL, CARIPLO_REFERRAL, CONVERSION_CRM_CAMPAIGN_SOURCE_TOKEN_PARAM_NAME,
  CONVERSION_CRM_LEAD_ID_TOKEN_PARAM_NAME,
  CONVERSION_CRM_LEAD_SOURCE_TOKEN_PARAM_NAME,
  CONVERSION_UTM_CAMPAIGN_TOKEN_PARAM_NAME,
  CONVERSION_UTM_CONTENT_TOKEN_PARAM_NAME,
  CONVERSION_UTM_MEDIUM_TOKEN_PARAM_NAME,
  CONVERSION_UTM_SOURCE_TOKEN_PARAM_NAME,
  CONVERSION_UTM_TERM_TOKEN_PARAM_NAME,
  POSTE_REFERRAL,
  REFERRAL,
  REFERRAL_CODE,
  STUDENT_TOKEN_PARAM_NAME,
  ZOPPAS_REFERRAL
} from "app/context/studentInfoProvider";
import { upsertStudentInfo } from "app/service/apiService";
import { IdLabel } from "app/types/common";
import {
  ALLOWED_ERRORS_FIELDS,
  DUMMY_LOADER_TIME,
  getDataSubmitStateClass,
  getQueryParam
} from "app/utils/utils";
import React, { CSSProperties } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import AdvancedMultiSelect from "app/components/common/advancedMultiSelect";
import AdvancedSingleSelect from "app/components/common/advancedSingleSelect";
import AlertAlreadyRegisteredModal from "app/components/common/alertAlreadyRegisteredModal";
import AlertModal from "app/components/common/alertModal";
import ButtonSave from "app/components/common/buttonSave";
import PrivacyModal from "app/components/common/privacyModal";
import SingleSelectCreatable from "app/components/common/singleSelectCreatable";
import { setCookie, STUDENT_UUID } from "app/utils/cookies";
import clsx from "clsx";
import CodiceFiscale from 'codice-fiscale-js';
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import AdvancedCcSingleSelect from "app/components/common/advancedCcSingleSelect";
import AdvancedCcSingleSelect from "app/components/common/advancedCcSingleSelect";
interface Props {
  studentInfo: any;
  onStudentInfoChange(data: object): void;
  onStudentApplicationInfoChange(data: object): void;

  onSaveAndContinue(): void;
}

// const GENDER_OPTIONS = [
//   { id: "M", label: "Uomo/Male" },
//   { id: "F", label: "Donna/Female" },
//   { id: "X", label: "Preferisco non dichiararlo" },
// ];

const DEPENDENT_FIELD = {
  citizen_country: "domicile_country",
  citizen_province: "domicile_province",
  citizen_city: "domicile_city",
  citizen_zip: "domicile_zip",
  citizen_address: "domicile_address",
  citizen_address_number: "domicile_address_number",
};

const REQUIRED_FIELDS = [
  "name",
  "surname",
  "gender",
  "born_at",
  "born_city",
  "born_province",
  "born_country",
  "citizenships",
  "tax_code",
  "italian_residence",
  "citizen_country",
  "citizen_province",
  "citizen_city",
  "citizen_zip",
  "citizen_address",
  "citizen_address_number",
  "phone_country_code",
  "phone",
  "email",
];

const PersonalDataPage: React.FC<Props> = (props: Props) => {

  const { onStudentInfoChange, studentInfo } = props;
  let { ambassador, getAmbassadorFromCode } = React.useContext(StudentInfoContext);


  const crmLeadIdToken = getQueryParam(CONVERSION_CRM_LEAD_ID_TOKEN_PARAM_NAME) || null;
  const crmLeadSourceToken = getQueryParam(CONVERSION_CRM_LEAD_SOURCE_TOKEN_PARAM_NAME) || null;
  const crmCampaignSourceToken = getQueryParam(CONVERSION_CRM_CAMPAIGN_SOURCE_TOKEN_PARAM_NAME) || null;
  const UTMSourceToken = getQueryParam(CONVERSION_UTM_SOURCE_TOKEN_PARAM_NAME) || null;
  const UTMCampaignToken = getQueryParam(CONVERSION_UTM_CAMPAIGN_TOKEN_PARAM_NAME) || null;
  const UTMTermToken = getQueryParam(CONVERSION_UTM_TERM_TOKEN_PARAM_NAME) || null;
  const UTMContentToken = getQueryParam(CONVERSION_UTM_CONTENT_TOKEN_PARAM_NAME) || null;
  const UTMMediumToken = getQueryParam(CONVERSION_UTM_MEDIUM_TOKEN_PARAM_NAME) || null;

  const OPTIONS = [
    {
      id: "UE",
      label: useIntl().formatMessage({ id: "personalDataPage.option.ue" })
    },
    {
      id: "XUE",
      label: useIntl().formatMessage({ id: "personalDataPage.option.xue" })
    },
  ];

  const OPTIONS2 = [
    {
      id: 0,
      label: useIntl().formatMessage({ id: "personalDataPage.option.no" })
    },
    {
      id: 1,
      label: useIntl().formatMessage({ id: "personalDataPage.option.yes" })
    },
  ];

  const GENDER_OPTIONS = [
    {
      id: "M",
      label: useIntl().formatMessage({ id: "personalDataPage.option.male" })
    },
    {
      id: "F",
      label: useIntl().formatMessage({ id: "personalDataPage.option.female" })
    },
    {
      id: "X",
      label: useIntl().formatMessage({ id: "personalDataPage.option.other" })
    },
  ];

  const [ambassadorCodeField, setAmbassadorCodeField] = React.useState<string | undefined>();
  const [ambassadorReceived, setAmbassadorReceived] = React.useState<boolean>(false);

  const [invalidFields, setInvalidFields] = React.useState<any>({});

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = React.useState<boolean>(false);

  const [isAlertModalOpen, setIsAlertModalOpen] = React.useState<boolean>(false);

  const [isAlertAlreadyRegisteredModalOpen, setIsAlertAlreadyRegisteredModalOpen] = React.useState<boolean>(false);


  const [isAgeCorrect, setIsAgeCorrect] = React.useState<boolean>(true);
  const [isTaxCodeCorrect, setIsTaxCodeCorrect] = React.useState<boolean>(true);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const hideConfirmEmailField = Boolean(getQueryParam(STUDENT_TOKEN_PARAM_NAME) || (studentInfo?.id));

  const showDomicile = !Boolean(Number(studentInfo["is_domicile_eq_citizenship"] ?? true));

  const metadata = React.useContext(StudentInfoContext);

  console.log(metadata?.phoneCodeNations);


  // Object.defineProperty(String.prototype, 'capitalize', {
  //   value: function () {
  //     return this.charAt(0).toUpperCase() + this.slice(1);
  //   },
  //   enumerable: false,
  //   configurable: true
  // });
  const { studentApplication } = React.useContext(StudentInfoContext);

  const isDisabled = () => {
    return (studentApplication?.origin === 'INTESA_STUDIOSI' || studentApplication?.origin === 'INTESA_PERMERITO');
  }

  type Gender = "F" | "M";
  const getTaxCodeCorrect = (): string => {
    let genderCF: Gender = 'M';
    if (studentInfo['gender'] === 'M') {
      genderCF = 'M'
    } else if (studentInfo['gender'] === 'F') {
      genderCF = 'F'

    } else {
      console.log('gender not declared.');
    }

    // var genderCF = 'M';

    const dateCF = new Date(studentInfo['born_at']);


    const monthCF = dateCF.getMonth() + 1;
    const dayCF = dateCF.getDate();
    const yearCF = dateCF.getFullYear();
    console.log('month' + monthCF + 'day' + dayCF + 'year' + yearCF);

    let cf: CodiceFiscale;
    try {


      cf = new CodiceFiscale({
        name: studentInfo['name'],
        surname: studentInfo['surname'],
        gender: genderCF as any,
        day: dayCF,
        month: monthCF,
        year: yearCF,
        birthplace: studentInfo['born_city'],
        birthplaceProvincia: studentInfo['born_province'] // Optional
      });
      console.log(cf);
    } catch (e) {
      console.log(e);
      return '';
    }

    return cf.cf;

  }

  // const capitalizeFirstLetter = (str: string | any[]) => {
  //   return str[0].toUpperCase() + str.slice(1);
  // };

  const checkForm = () => {

    setIsLoading(true);
    try {
      let allOk = true;
      const errors = {};
      REQUIRED_FIELDS.forEach((field: string) => {
        if (studentInfo[field] === null || studentInfo[field] === undefined || studentInfo[field] === '') {
          errors[field] = <FormattedMessage id="personalDataPage.error_required" />;
          allOk = false;
        }
      });

      if (showDomicile) {
        Object.values(DEPENDENT_FIELD).forEach((field: string) => {
          if (studentInfo[field] === null || studentInfo[field] === undefined || studentInfo[field] === '') {
            allOk = false;
            errors[field] = <FormattedMessage id="personalDataPage.error_required" />;
          }
        });
      }

      if (!allOk) {
        setInvalidFields(errors);
        setIsLoading(false);

        return;
      }


      if (
        !hideConfirmEmailField &&
        studentInfo["email"] &&
        studentInfo["email"] !== studentInfo["confirm_email"]
      ) {
        const errors = {};
        errors["confirm_email"] = "La tua email è diversa"; // Your email is different
        setInvalidFields(errors);
        setIsLoading(false);

        return;
      }
      checkForAgeAndTaxCode();
    } catch (error) {
      setIsLoading(false);
    }

  };

  const checkForAgeAndTaxCode = () => {

    const student_age = moment().diff(studentInfo['born_at'], 'years', false);
    const tax_code_correct = getTaxCodeCorrect();

    const taxCode = studentInfo['tax_code'] as String;
    if (student_age < 16 || tax_code_correct !== taxCode?.toUpperCase()) {

      setIsAgeCorrect(student_age >= 16);
      setIsTaxCodeCorrect(tax_code_correct === studentInfo['tax_code']);

      setIsAlertModalOpen(true);
    } else {

      //privacy
      checkForPrivacyAuthorizations();
    }
  };


  const checkForPrivacyAuthorizations = () => {

    //if user already has privacynotification then call on SAVEAND CONTINUE  else show modal

    //if (studentInfo['italian_residence'] === 1) {

    if ((studentInfo['privacy_notifications'] + '') === '1') {


      // studentInfo['privacy_notifications'] === 1;


      onSaveAndContinue();

    } else {

      setIsPrivacyModalOpen(true);
    }
  };


  const onSaveAndContinue = async () => {


    // todo: validate fiscal code with library

    //ok
    // MODALI

    try {


      let studentConversion;
      if (!studentInfo.id) {
        studentConversion = {};


        if (crmLeadIdToken !== null && crmLeadIdToken !== undefined && crmLeadIdToken.trim() !== '') {
          studentConversion['crm_lead_id'] = crmLeadIdToken;
        }
        if (crmLeadSourceToken !== null && crmLeadSourceToken !== undefined && crmLeadSourceToken.trim() !== '') {
          studentConversion['crm_lead_source'] = crmLeadSourceToken;
        }
        if (crmCampaignSourceToken !== null && crmCampaignSourceToken !== undefined && crmCampaignSourceToken.trim() !== '') {
          studentConversion['crm_campaign_source'] = crmCampaignSourceToken;
        }
        if (UTMSourceToken !== null && UTMSourceToken !== undefined && UTMSourceToken.trim() !== '') {
          studentConversion['utm_source'] = UTMSourceToken;
        }
        if (UTMCampaignToken !== null && UTMCampaignToken !== undefined && UTMCampaignToken.trim() !== '') {
          studentConversion['utm_campaign'] = UTMCampaignToken;
        }
        if (UTMTermToken !== null && UTMTermToken !== undefined && UTMTermToken.trim() !== '') {
          studentConversion['utm_term'] = UTMTermToken;
        }
        if (UTMContentToken !== null && UTMContentToken !== undefined && UTMContentToken.trim() !== '') {
          studentConversion['utm_content'] = UTMContentToken;
        }
        if (UTMMediumToken !== null && UTMMediumToken !== undefined && UTMMediumToken.trim() !== '') {
          studentConversion['utm_medium'] = UTMMediumToken;
        }

        if (Object.keys(studentConversion).length === 0) {
          studentConversion = undefined;
        }

      }

      let referral = undefined;
      if (referralFromUrl === AMBASSADOR_REFERRAL && ambassador) {
        referral = {
          person: ambassador,
          type: AMBASSADOR_REFERRAL,
          code: ambassador?.ambassador_code,
        }
      } else if (referralFromUrl === POSTE_REFERRAL) {
        referral = {
          type: POSTE_REFERRAL,
          code: referralCodeFromUrl,
        }
      } else if (referralFromUrl === ZOPPAS_REFERRAL) {
        referral = {
          type: ZOPPAS_REFERRAL,
          code: referralCodeFromUrl,
        }
      } else if (referralFromUrl === CARIPLO_REFERRAL) {
        referral = {
          type: CARIPLO_REFERRAL,
          code: referralCodeFromUrl,
        }
      }

      const res = await upsertStudentInfo(studentInfo, studentConversion, referral);

      console.log(res);
      if (res?.status === "OK") {
        setCookie(STUDENT_UUID, res.student.uuid, 6);
        props.onStudentInfoChange((res.student) || studentInfo)
        if (res.student_application) {
          props.onStudentApplicationInfoChange(res.student_application);

        }
        if (res.messageCode === 'noDataChange') {
          metadata.isCreationMode = false;
          setIsAlertAlreadyRegisteredModalOpen(true);
        } else {
          setTimeout(() => {
            setIsLoading(false);
            props.onSaveAndContinue();
          }, DUMMY_LOADER_TIME);
        }
      } else {
        if (res?.error?.length) {
          setInvalidFields({ error: res?.error?.[0] });
          setErrorMessage(null);
        } else if (res.messages) {
          const errorMessages = {};
          Object.keys(res.messages).forEach((key: string) => {
            const split = key.split(".");
            const resolvedKey = split[1] || split[0];

            if (ALLOWED_ERRORS_FIELDS.includes(resolvedKey)) return;

            const error = res.messages[key];
            if (Array.isArray(error)) {
              errorMessages[resolvedKey] = error[0];
            } else if (error) {
              errorMessages[resolvedKey] = error;
            }
          });
          setInvalidFields(errorMessages);
          setErrorMessage(null);

        } else if (res.messageCode) {

          setErrorMessage(res.messageCode);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, DUMMY_LOADER_TIME);
      }
    } catch (error) {
      setIsLoading(false);
    }

  };

  const handleClose = (result: boolean) => {

    setIsAlertModalOpen(false);

    if (result === true) {
      checkForPrivacyAuthorizations();
    } else {
      setIsLoading(false);
    }
  };

  const handleAlreadyRegisteredClose = (result: boolean) => {

    setIsAlertAlreadyRegisteredModalOpen(false);

    setTimeout(() => {
      setIsLoading(false);
      if (result === true) {

        props.onSaveAndContinue();
      }
    }, DUMMY_LOADER_TIME);
  };



  const updateData = (key: string, value: any) => {
    let dependentValue;
    if (!showDomicile && DEPENDENT_FIELD[key]) {
      dependentValue = { [DEPENDENT_FIELD[key]]: value };
    }

    props.onStudentInfoChange({
      ...studentInfo,
      ...dependentValue,
      [key]: value,
    });

    if (value && invalidFields?.[key]) {
      const errors = { ...invalidFields };
      delete errors[key];
      setInvalidFields(errors);
    }
  };

  const onShowDomicileUpdate = (checked: boolean) => {
    if (checked) {
      const clone = { ...studentInfo, is_domicile_eq_citizenship: 0 };
      Object.values(DEPENDENT_FIELD).map((key) => (clone[key] = ""));
      props.onStudentInfoChange(clone);
    } else {
      const clone = { ...studentInfo, is_domicile_eq_citizenship: 1 };
      Object.keys(DEPENDENT_FIELD).forEach(
        (key) => (clone[DEPENDENT_FIELD[key]] = clone[key])
      );
      props.onStudentInfoChange(clone);
    }
  };

  const onFieldValueChange = (key: string) => (value: string) =>
    updateData(key, value);

  const onFieldValueChangeMulti = (key: string) => (value: Array<string>) =>
    updateData(key, value);

  const renderBlock = (component: React.ReactNode, isFirst?: boolean) => (
    <div
      className={`lg:w-1/2 md:w-full sm:w-full ${isFirst ? "" : "lg:pl-16"}`}
    >
      {component}
    </div>
  );

  const renderTextField = (key: string, label?: string, maxLength?: number, pattern?: string, style?: CSSProperties, disabled?: boolean) => (
    <TextInput
      invalid={Boolean(invalidFields[key])}
      invalidMessage={invalidFields[key]}
      label={label}
      value={studentInfo[key]}
      onChange={onFieldValueChange(key)}
      maxLength={maxLength}
      pattern={pattern}
      style={style}
      disabled={disabled}
    />
  );


  const renderAdvancedSingleSelector = (
    key: string,
    options: IdLabel[],
    label?: string,
  ) => (
    <AdvancedSingleSelect
      value={studentInfo[key]}
      label={label}
      options={options}
      errorMessage={invalidFields[key]}
      onChange={onFieldValueChange(key)}
    />
  );
  const renderCCAdvancedSingleSelector = (
    key: string,
    options: IdLabel[],
    label?: string,
  ) => (
    <AdvancedCcSingleSelect
      value={studentInfo[key]}
      label={label}
      options={options}
      errorMessage={invalidFields[key]}
      onChange={onFieldValueChange(key)}
    />
  );

  const renderAdvancedMultiSelector = (
    key: string,
    options: IdLabel[],
    label?: string,
  ) => (
    <AdvancedMultiSelect
      value={studentInfo[key]}
      label={label}
      options={options}
      errorMessage={invalidFields[key]}
      onChange={onFieldValueChangeMulti(key)}
    />
  );

  const renderSingleCreatableSelector = (
    key: string,
    options: IdLabel[],
    label?: string
  ) => (
    <SingleSelectCreatable
      value={studentInfo[key]}
      label={label}
      options={options}
      errorMessage={invalidFields[key]}
      onChange={onFieldValueChange(key)}
    />
  );



  const COMMON_ROW_CLASS = "lg:flex mb-1 lg:flex-wrap grey";
  const confirmMail = useIntl().formatMessage({ id: "personalDataPage.block2.confirm_email" });
  const domicileCountry = useIntl().formatMessage({ id: "personalDataPage.block3.domicile_country" });
  const domicileProvince = useIntl().formatMessage({ id: "personalDataPage.block3.domicile_province" });
  const domicileCity = useIntl().formatMessage({ id: "personalDataPage.block3.domicile_city" });
  const domicileZip = useIntl().formatMessage({ id: "personalDataPage.block3.domicile_zip" });
  const domicileAddress = useIntl().formatMessage({ id: "personalDataPage.block3.domicile_address" });
  const domicileAddressNumber = useIntl().formatMessage({ id: "personalDataPage.block3.domicile_address_number" });



  // const doNothing = (data: object): void => { };

  // FUNZIONA a metà 
  const test = document.querySelector('.react-datepicker-wrapper');
  const test2 = document.querySelector('.date-picker');

  const existClass = test2?.classList.contains('react-datepicker-ignore-onclickoutside');

  if (existClass) {
    test?.classList.add("borderBlu");
  } else {
    test?.classList.remove("borderBlu");
  }


  const referralFromUrl = getQueryParam(REFERRAL);
  const referralCodeFromUrl = getQueryParam(REFERRAL_CODE);
  const isStudentUpdate = getQueryParam(STUDENT_TOKEN_PARAM_NAME);


  const openToastAmbassadorError = () => {
    toast.error(<FormattedMessage id="personalDataPage.ambassadorNotFound" />, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const checkAmbassadorCode = async () => {
    const res = await getAmbassadorFromCode(ambassadorCodeField!);

    if (res) {
      setAmbassadorReceived(true);
    } else {
      openToastAmbassadorError();
    }
  }


  let labelAmbassador = useIntl().formatMessage({ id: "step3.up1.inputLable" });
  let labelVerify = useIntl().formatMessage({ id: "button.verifyAmbassador" });

  const ambassadorFound = (referralFromUrl && referralCodeFromUrl) ? <FormattedMessage id="personalDataPage.ambassadorNotFound" /> : <FormattedMessage id="personalDataPage.ambassadorDisclaimer" />;
  const renderHelloMessage = () => {
    if (isStudentUpdate) {
      return null;
    }

    if (referralFromUrl === POSTE_REFERRAL) {
      return null;
    }

    if (referralFromUrl === ZOPPAS_REFERRAL) {
      return null;
    }
    if (referralFromUrl === CARIPLO_REFERRAL) {
      return null;
    }

    if (ambassador && ambassador.name) {
      return <div className="text-2xl font-600 my-2" style={{ marginBottom: "2rem" }}>
        <FormattedMessage id="personalDataPage.ambassador1" /> {ambassador.name} {ambassador.surname}, <br /><FormattedMessage id="personalDataPage.ambassador2" />
      </div>

    } else {
      return <div
        className={clsx(
          isStudentUpdate ? "disable-actions" : "",
          "flex flex-col items-center"
        )}
      >
        <h5 className="mb-3">
          {ambassadorFound}
        </h5>

        <div>

          <TextInput
            label={labelAmbassador}
            value={ambassadorCodeField}
            invalid={Boolean(
              invalidFields?.["invited_by_student_ambassador_code"]
            )}
            invalidMessage={
              invalidFields?.["invited_by_student_ambassador_code"]
            }
            onChange={setAmbassadorCodeField}
          />

          <Button
            disabled={ambassadorReceived}
            label={labelVerify}
            variant="habacus"
            onClick={checkAmbassadorCode}
          />
        </div>
      </div>;
    }


  };


  const renderErrorMessage = () => {
    if (errorMessage) {

      if (errorMessage === 'emailTaxcodeInDB1') {
        return <div>
          <p style={{ color: 'red', fontSize: '1.2rem' }}><FormattedMessage id="personalDataPage.error_message.1" /></p>
          <a href="mailto:support@habacus.com"><FormattedMessage id="personalDataPage.error_message.2" /></a>
        </div>;
      } else if (errorMessage === 'emailTaxcodeInDB2') {
        return <div>
          <p style={{ color: 'red', fontSize: '1.2rem' }}><FormattedMessage id="personalDataPage.error_message.1" /></p>
          <a href="mailto:support@habacus.com"><FormattedMessage id="personalDataPage.error_message.2" /></a>
        </div>;

      } else if (errorMessage === 'applicationInPipeline') {
        return <div>
          <p style={{ color: 'red', fontSize: '1.2rem' }}><FormattedMessage id="personalDataPage.error_message.4" /></p>
          <a href="mailto:support@habacus.com"><FormattedMessage id="personalDataPage.error_message.2" /></a>
        </div>;

      }
    }
    return null;
  }


  const renderTitle = () => {
    if (isStudentUpdate) {
      return <>
        <div className="text-4xl font-600 my-2">
          <FormattedMessage id="personalDataPage.title.update" />
        </div>
        <div className="text-lg">
          <FormattedMessage id="personalDataPage.subtitle2.update" />
        </div>

      </>;

    } else {
      return <>


        <div className="text-4xl font-600 my-2">
          <FormattedMessage id="personalDataPage.title.create" />
        </div>
        <div className="text-lg">
          <FormattedMessage id="personalDataPage.subtitle2.create" />
        </div>
      </>;

    }

  }

  return (

    <div className="relative">
      <AlertModal
        isAlertOpen={isAlertModalOpen}
        handleClose={handleClose}
        isAgeCorrect={isAgeCorrect}
        isTaxCodeCorrect={isTaxCodeCorrect}
      ></AlertModal>


      <PrivacyModal
        isOpen={isPrivacyModalOpen}
        onSaveAndContinue={() => {

          setIsPrivacyModalOpen(false);
          onSaveAndContinue();

        }}

        studentInfo={studentInfo}
        onStudentInfoChange={onStudentInfoChange}

      ></PrivacyModal>

      <AlertAlreadyRegisteredModal
        isOpen={isAlertAlreadyRegisteredModalOpen}
        handleClose={handleAlreadyRegisteredClose}

      ></AlertAlreadyRegisteredModal>

      <ConditionalLoader isLoading={isLoading} />

      <div style={{ textAlign: "center" }}>
        <br />
        {renderHelloMessage()}
        <br />
      </div>


      <div className={getDataSubmitStateClass(isLoading)}>

        <div style={{ marginBottom: '1rem' }}>
          {renderTitle()}
        </div>



        <div className="text-3xl mb-8">
          <FormattedMessage id="personalDataPage.block1.title" />
        </div>
        <div className={COMMON_ROW_CLASS}>
          {renderBlock(renderTextField("name", useIntl().formatMessage({ id: "personalDataPage.block1.name" }), 100, "[a-zA-Z]+", { textTransform: 'capitalize' }, isDisabled()), true)}
          {renderBlock(renderTextField("surname", useIntl().formatMessage({ id: "personalDataPage.block1.surname" }), 100, undefined, { textTransform: 'capitalize' }, isDisabled()))}
        </div>
        <div className={COMMON_ROW_CLASS}>
          {renderBlock(
            renderAdvancedSingleSelector("gender", GENDER_OPTIONS, useIntl().formatMessage({ id: "personalDataPage.block1.gender" })),
            true
          )}
          {renderBlock(renderTextField("tax_code", useIntl().formatMessage({ id: "personalDataPage.block1.tax_code" }), 16, undefined, { textTransform: 'uppercase' }, isDisabled()))}
        </div>

        <div className={COMMON_ROW_CLASS}>
          {renderBlock((

            <DateInput
              label={useIntl().formatMessage({ id: "personalDataPage.block1.date" })}
              value={studentInfo["born_at"]}
              invalid={Boolean(invalidFields["born_at"])}
              invalidMessage={invalidFields["born_at"]}
              onChange={onFieldValueChange("born_at")}
            />

          ), true)}


          {renderBlock(
            renderAdvancedSingleSelector("born_country",
              metadata?.nations as IdLabel[],
              useIntl().formatMessage({ id: "personalDataPage.block1.born_country" }))
          )}
        </div>


        <div className={COMMON_ROW_CLASS}>
          {renderBlock(renderSingleCreatableSelector("born_province",
            metadata?.provinces as IdLabel[],
            useIntl().formatMessage({ id: "personalDataPage.block1.born_province" })), true)
          }

          {renderBlock(renderTextField("born_city", useIntl().formatMessage({ id: "personalDataPage.block1.born_city" }), 100, undefined, { textTransform: 'capitalize' }))}
        </div>

        <div className={COMMON_ROW_CLASS}>
          {renderBlock(
            renderAdvancedMultiSelector("citizenships",
              metadata?.citizenships as IdLabel[],
              useIntl().formatMessage({ id: "personalDataPage.block1.citizenships" })),
            true
          )}
          {renderBlock(
            renderAdvancedSingleSelector("italian_residence", OPTIONS2, useIntl().formatMessage({ id: "personalDataPage.block1.italian_residence" }))
          )}
        </div>

        <div className={COMMON_ROW_CLASS}>
          {renderBlock(renderTextField("email", useIntl().formatMessage({ id: "personalDataPage.block2.email" }), 100, undefined, {}, isDisabled()), true)}
          {hideConfirmEmailField
            ? null
            : renderBlock(renderTextField("confirm_email", confirmMail))}
        </div>

        <div className={COMMON_ROW_CLASS}>
          {renderBlock(

            renderCCAdvancedSingleSelector("phone_country_code",
              metadata?.phoneCodeNations as IdLabel[],
              useIntl().formatMessage({ id: "personalDataPage.block2.phone_country_code" }))
            , true)}
          {renderBlock(renderTextField("phone", useIntl().formatMessage({ id: "personalDataPage.block2.phone" })))}
        </div>

        {/* <div className={COMMON_ROW_CLASS}>
          {renderBlock(
            renderTextField("phone_country_code", useIntl().formatMessage({ id: "personalDataPage.block2.phone_country_code" }))
            , true)}
          {renderBlock(renderTextField("phone", useIntl().formatMessage({ id: "personalDataPage.block2.phone" })))}
        </div> */}

        <div className="mb-10">
          <div className="text-3xl">
            <FormattedMessage id="personalDataPage.block2.title" />
          </div>
        </div>
        <div className={COMMON_ROW_CLASS}>
          {renderBlock(
            renderAdvancedSingleSelector("citizen_country",
              metadata?.nations as IdLabel[],
              useIntl().formatMessage({ id: "personalDataPage.block2.citizen_country" })),
            true
          )}
          {renderBlock(renderSingleCreatableSelector("citizen_province",
            metadata?.provinces as IdLabel[],
            useIntl().formatMessage({ id: "personalDataPage.block2.citizen_province" })))
          }
        </div>

        <div className={COMMON_ROW_CLASS}>
          {renderBlock(renderTextField("citizen_city", useIntl().formatMessage({ id: "personalDataPage.block2.citizen_city" }), 100, undefined, { textTransform: 'capitalize' }), true)}
          {renderBlock(renderTextField("citizen_zip", useIntl().formatMessage({ id: "personalDataPage.block2.citizen_zip" })))}
        </div>
        <div className={COMMON_ROW_CLASS}>
          {renderBlock(
            renderTextField("citizen_address", useIntl().formatMessage({ id: "personalDataPage.block2.citizen_address" })),
            true
          )}
          {renderBlock(
            renderTextField("citizen_address_number", useIntl().formatMessage({ id: "personalDataPage.block2.citizen_address_number" }))
          )}


        </div>


        <CheckBox
          checked={showDomicile}
          label={useIntl().formatMessage({ id: "personalDataPage.block2.checkboxDomicile" })}
          onChange={onShowDomicileUpdate}
        />
        {showDomicile && (
          <div className="mt-1">
            <div className={COMMON_ROW_CLASS}>
              {renderBlock(
                renderAdvancedSingleSelector("domicile_country",
                  metadata?.nations as IdLabel[],
                  domicileCountry),
                true
              )}


              {renderBlock(renderSingleCreatableSelector("domicile_province",
                metadata?.provinces as IdLabel[],
                domicileProvince))
              }
            </div>

            <div className={COMMON_ROW_CLASS}>
              {renderBlock(renderTextField("domicile_city", domicileCity, 100, undefined, { textTransform: 'capitalize' }), true)}
              {renderBlock(renderTextField("domicile_zip", domicileZip))}
            </div>
            <div className={COMMON_ROW_CLASS}>
              {renderBlock(
                renderTextField("domicile_address", domicileAddress),
                true
              )}
              {renderBlock(
                renderTextField("domicile_address_number", domicileAddressNumber)
              )}
            </div>
          </div>
        )}
        {invalidFields?.error ? (
          <div className="text-danger">{invalidFields?.error}</div>
        ) : null}


        <div className="mt-30">

          {renderErrorMessage()}

        </div>


        <div className="mt-30 buttonsGoToTheRight">

          {/* <Button
            disabled={isLoading}
            label={useIntl().formatMessage({ id: "button.save&continue" })}
            variant="habacus"
            onClick={checkForm}
          /> */}
          <ButtonSave
            disabled={isLoading}
            label1={useIntl().formatMessage({ id: "button.saveAndGoOn1" })}
            label2={useIntl().formatMessage({ id: "button.saveAndGoOn2" })}
            variant="flexButtSave"
            onClick={checkForm}
          />
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  );
};

export default PersonalDataPage;
