// import { useIntl } from "react-intl";


// const documentsName = {
//   // identity_document: useIntl().formatMessage({ id: "studentTaxCodeInput.lable" }),
//   identity_document: "Cartà d'Identità",
//   sanitary_card: "Tessera Sanitaria",
//   proxy: "Delega",
//   passport: "Passaporto",
//   residence_certificate: "Certificato residenza",
//   health_card: "Tessera sanitaria",
//   other_proxy: "Delega",
//   concern_payments_ok_student: "Ricevuta di Pagamento Iscrizione",
//   learning_agreement_student: "Contratto Erasmus",
//   final_grade_student: "Certificato di Diploma/Laurea con Voto",
//   attended_frequency_student: "Certificato di Presenza",
//   at_least_n_credits_student: "Libretto Universitario",
//   studies_plan_student: "Piano di Studi",

// };




// export const DOCUMENTS_OPTIONS_LABEL = documentsName;

export const DOCUMENTS_OPTIONS_LABEL = {
  identity_document: "Cartà d'Identità",
  sanitary_card: "Tessera Sanitaria",
  proxy: "Delega",
  contract: "Contratto",
  passport: "Passaporto",
  residence_certificate: "Certificato residenza",
  health_card: "Tessera sanitaria",
  other_proxy: "Delega",
  concern_payments_ok_student: "Ricevuta di Pagamento Iscrizione",
  learning_agreement_student: "Contratto Erasmus",
  final_grade_student: "Certificato di Diploma/Laurea con Voto",
  attended_frequency_student: "Certificato di Presenza",
  at_least_n_credits_student: "Libretto Universitario",
  studies_plan_student: "Piano di Studi",

};
