import { StepObject, SubStepObject } from "app/types/stepper";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";


interface Props {

  studentInfo: object;
  studentApplicationInfo: object;
  accessRequirementInfo: object;

  step: StepObject;

  isCreationMode?: boolean;
  onUpdateStep(): void;
  onStudentInfoChange(data: object): void;
  onStudentApplicationInfoChange(data: object): void;
  onAccessRequirementInfoChange(data: object): void;
}

const StepComponent: React.FC<Props> = (props: Props) => {
  const { studentInfo, studentApplicationInfo, accessRequirementInfo, onStudentInfoChange, onStudentApplicationInfoChange, onAccessRequirementInfoChange, onUpdateStep, step, isCreationMode } = props;
  const { id, subtitle, children } = step;
  const [activeSubStep, setActiveSubStep] = React.useState<number>(0);

  const SUB_STEPS = () => {

    const returnValue: SubStepObject[] = [];

    let cont = 0;
    for (let i = 0; i < children.length; i++) {
      const subStep = children[i];
      if (step.isToShow(studentInfo, studentApplicationInfo, accessRequirementInfo, isCreationMode) && subStep.isToShow(studentInfo, studentApplicationInfo, accessRequirementInfo, isCreationMode)) {
        returnValue.push({
          ...subStep,
          id: cont,
        });
        cont++;
      }
    }

    return returnValue;
  };

  useEffect(() => {
    setActiveSubStep(0);
  }, [id]);



  const renderStepComponent = () => {
    const step = SUB_STEPS()[activeSubStep];
    if (step) {

      return <step.component
        studentInfo={studentInfo}
        studentApplicationInfo={studentApplicationInfo}
        accessRequirementInfo={accessRequirementInfo}
        onStudentInfoChange={onStudentInfoChange}
        onStudentApplicationInfoChange={onStudentApplicationInfoChange}
        onAccessRequirementInfoChange={onAccessRequirementInfoChange}
        onSaveAndContinue={() => {
          const isLast = activeSubStep >= (SUB_STEPS().length - 1);

          if (!isLast) {
            setActiveSubStep(activeSubStep + 1);
          } else {
            onUpdateStep();
          }
        }}
      />
    }
    return null;
  };

  const subStep = SUB_STEPS();
  return (
    <div>
      <div className="center-y mb-20">
        <div
          className={`${(subStep.length <= 1 || activeSubStep === 0) ? "invisible" : ""
            } cp text-md-start text-secondary whitespace-pre mr-10`}
          onClick={() => setActiveSubStep(activeSubStep - 1)}
        >
          &lt; <span className="ml-8">
            <FormattedMessage id="indietro" />
          </span>
        </div>
        {
          subStep.length <= 1 || !subtitle ? null :
            (<div className="flex-1 text-center font-700 habacusColorFont">
              <FormattedMessage id={subtitle} /> {activeSubStep + 1} / {subStep.length}
            </div>)
        }

        <div className="serviceEmptyDiv"></div>
      </div>
      {renderStepComponent()}
    </div>
  );
};

export default StepComponent;
