import { ORIGIN_TOKEN_PARAM_NAME } from "app/context/studentInfoProvider";
import { getQueryParam } from "app/utils/utils";
import React from "react";
import { FormattedMessage } from "react-intl";


interface Props {
    onSetStepperVisible(isVisible: boolean): void;
}


const ThankYou: React.FC<Props> = (props: Props) => {

    props.onSetStepperVisible(true);
    const tokenPfromUrl = getQueryParam(ORIGIN_TOKEN_PARAM_NAME);

    const renderMexGeneral = () => {
        if (tokenPfromUrl == 'BPI') {
            return <div className="mb-30 mt-30 thankYou">
                <div className="text-4xl font-600 mb-30 mt-30">
                    <FormattedMessage id="thankYou.1" />
                </div>
                <div className="text-2xl center">
                    <FormattedMessage id="thankYou.2" /><br /><br />

                    <FormattedMessage id="thankYou.3" />
                </div>
                <div className="text-2xl center">
                    <FormattedMessage id="thankYou.5" />
                </div>
                <div className="text-2xl colorblue center">
                    <FormattedMessage id="thankYou.6" /><br /><br />
                    <FormattedMessage id="thankYou.7" />
                    <a target="_blank" rel="noreferrer" className="underline" href="https://habacus.com/it/faq">FAQ</a>
                    <FormattedMessage id="thankYou.8" />
                    <a href="mailto:promostudiprestitobp@habacus.com" className="underline"> <FormattedMessage id="studentDisclaimer.poste.email" /> </a>

                </div>
            </div>

        } else {
            return <div className="mb-30 mt-30 thankYou">
                <div className="text-4xl font-600 mb-30 mt-30">
                    <FormattedMessage id="thankYou.1" />
                </div>
                <div className="text-2xl center">
                    <FormattedMessage id="thankYou.2" /><br /><br />

                    <FormattedMessage id="thankYou.3" />
                </div>
                <div className="text-2xl center">
                    <FormattedMessage id="thankYou.4" />
                </div>
                <div className="text-2xl center">
                    <FormattedMessage id="thankYou.5" />
                </div>
                <div className="text-2xl colorblue center">
                    <FormattedMessage id="thankYou.6" /><br /><br />
                    <FormattedMessage id="thankYou.7" />
                    <a target="_blank" rel="noreferrer" className="underline" href="https://habacus.com/it/faq">FAQ</a>
                    <FormattedMessage id="thankYou.8" />
                    <a href="mailto:info@habacus.com" className="underline"> <FormattedMessage id="studentDisclaimer.email" /> </a>

                </div>
            </div>

        }
    }

    return (
        <div className="Thanks">

            {renderMexGeneral()}
        </div>
    );
};

export default ThankYou;
