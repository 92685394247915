import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from "app/components/common/button";
import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";


interface Props {
    isConfirmNoTestVisible: boolean;
    handleCloseNoTestModalAndContinue(result: boolean): void;
    handleCloseNoTestModal(result: boolean): void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '3px inset #036ED9',
            borderRadius: '5px',
            // backgroundImage: 'linear-gradient(135deg, #036ED9, #00FFC6)',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    })
);


const ConfirmNoTestModal: React.FC<Props> = (props: Props) => {


    const classes = useStyles();
    const closeModalNoTestAndContinue = () => {
        props.handleCloseNoTestModalAndContinue(true);
    }

    const goBackToChoice = () => {
        props.handleCloseNoTestModal(true);
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.isConfirmNoTestVisible}
            // onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className={classes.paper}>

                <h1 className="pulseAttentionModal"><FormattedMessage id="orientation.bpi.confirmNoTestModal.title" /></h1>

                {/* {
                    props.isTaxCodeCorrect ? null : (<h2><FormattedMessage id="alertModal.2a" /><b><FormattedMessage id="alertModal.2b" /></b><FormattedMessage id="alertModal.2c" />?</h2>)
                }
                <br />
                {
                    props.isAgeCorrect ? null : (<h2><FormattedMessage id="alertModal.3a" /><FormattedMessage id="alertModal.3a2" /><b><FormattedMessage id="alertModal.3b" /></b><FormattedMessage id="alertModal.3c" />?</h2>)
                }m */}

                {/* <h2><FormattedMessage id="orientation.bpi.confirmNoTestModal.text" />.</h2>
                <br /> */}
                <br />
                <div className="alertButtonsContainer">

                    <Button
                        label={useIntl().formatMessage({ id: "button.cancel" })}
                        variant="noTest"
                        onClick={goBackToChoice}
                    />

                    <Button
                        label={useIntl().formatMessage({ id: "button.continue" })}
                        variant="yesTest"
                        onClick={closeModalNoTestAndContinue}
                    />


                </div>

            </div>
        </Modal >
    );

}


export default ConfirmNoTestModal;
