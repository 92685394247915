import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon/StepIcon";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { StepObject } from "app/types/stepper";
import clsx from "clsx";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import useOnScreen from "./visibilityObserver";

interface Props {
  onStepClick?(number: number): void;
  activeStep?: string | number;
  steps: StepObject[];
  onSetStepperVisible(isVisible: boolean): void;
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: (window.screen.width > 1024) ? 19 : 16,
  },
  active: {
    "& $line": {
      background:
        "linear-gradient(90deg, rgba(59,52,159,1) 0%, rgba(76,163,235,1) 47%, rgba(0,212,255,1) 100%)",
    },
  },
  completed: {
    "& $line": {
      background:
        "linear-gradient(90deg, rgba(59,52,159,1) 0%, rgba(76,163,235,1) 47%, rgba(0,212,255,1) 100%)",
    },
  },
  line: {
    height: (window.screen.width > 1024) ? 3 : 2,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: (window.screen.width > 1024) ? 42 : 32,
    height: (window.screen.width > 1024) ? 42 : 32,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: (window.screen.width > 1024) ? "15px" : "12px",

  },
  active: {
    color: "white",
    fontSize: (window.screen.width > 1024) ? "20px" : "14px",
    backgroundColor: "#003da6",
    border: "2px solid #003da6",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#fff",
    border: "2px solid #003da6",
  },
});

const ColorlibStepIcon: React.FC<StepIconProps & { iconSrc: any, label: any }> = (
  props: StepIconProps & { iconSrc: any, label: any }
) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed, label } = props;

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.active]: active,
          [classes.completed]: completed,
        },
        "cp"
      )}
    >
      {props.iconSrc ? (
        <img height="90%" width="90%" src={props.iconSrc} alt="" />
      ) : label}
    </div>
  );
};

const StepperComponent: React.FC<Props> = (props) => {
  const { steps, activeStep, onSetStepperVisible } = props;

  const ref = React.createRef();

  const isVisible = useOnScreen(ref);

  useEffect(() => {
    onSetStepperVisible(isVisible);
  }, [onSetStepperVisible, isVisible]);

  let adaptedActiveStep = 0;
  steps.forEach((step, index) => {
    if (step.id === activeStep) {
      adaptedActiveStep = index;
    }
  });

  return (
    <Stepper
      ref={ref}
      className={"lg:w-3/4"}
      alternativeLabel
      activeStep={adaptedActiveStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((step) => (
        <Step
          key={step.id}
          // onClick={() => ((step.icon != null) ? (props.onStepClick?.(step.id as number)) : null)}
          // onClick={() => props.onStepClick?.(step.id as number)}

          onClick={() => {

            //onclick su quelli avanti se allowForward == true
            // onclick su quelli indietro se allowBackward == true

            if (activeStep! > step.id) {
              if (step.allowBackward) {
                props.onStepClick?.(step.id as number)
              }
            } else if (activeStep! < step.id) {
              if (step.allowForward) {
                props.onStepClick?.(step.id as number)
              }
            }

          }}
        >
          <StepLabel
            StepIconComponent={(props) => (
              <ColorlibStepIcon {...props} iconSrc={step.icon} label={step.id as number + 1} />
            )}
          >
            <FormattedMessage id={step.label} />
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperComponent;
