import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from "app/components/common/button";
import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";


interface Props {
    isAlertAccessRequirementOpen: boolean;
    handleClose(result: boolean): void;

}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '3px inset #036ED9',
            borderRadius: '5px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',

        },



    }),
);




const AlertAccessRequirementModal: React.FC<Props> = (props: Props) => {


    const classes = useStyles();

    return (
        <Modal

            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.isAlertAccessRequirementOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}

        >
            <div className={classes.paper}>
                <h1 className="pulseAttentionModal">
                    <FormattedMessage id="accessReq.modal.title" />
                </h1>


                <h2>
                    <FormattedMessage id="accessReq.modal.text" />
                </h2>
                <br />

                <div className="alertButtonsContainer">

                    <Button
                        label={useIntl().formatMessage({ id: "button.continue" })}
                        variant="primary"
                        onClick={() => props.handleClose(true)}
                    />

                </div>

            </div>
        </Modal >
    );

}


export default AlertAccessRequirementModal;
