import { IdLabel } from "app/types/common";

export const getQueryParam = (name: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const resolveValue = (value: string, options?: IdLabel[]) =>
  // eslint-disable-next-line eqeqeq
  options?.filter((o) => o.id == value)[0]?.label;

export const getStringValue = (value?: string) => value || "";

export const getDataSubmitStateClass = (isLoading: boolean) =>
  isLoading ? "pointer-events-none opacity-50" : "";

export const DUMMY_LOADER_TIME = 300; // in ms

export const ALLOWED_ERRORS_FIELDS = ["tax_code"];

// Date Util
export const addPrefix = (n: number) => (n < 10 ? "0" + n : "" + n);
