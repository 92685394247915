import Button from "app/components/common/button";
import ConditionalLoader from "app/components/common/loader/conditionalLoader";
import StudentInfoContext from "app/context/studentContext";
import { getDegreeClass, getSchool, getSchoolCourse } from "app/service/apiService";
import { DOCUMENTS_OPTIONS_LABEL } from "app/utils/documents";
import {
  addPrefix,
  getDataSubmitStateClass,
  resolveValue
} from "app/utils/utils";
import clsx from "clsx";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";


interface Props {
  studentInfo: any;
  studentApplicationInfo: any;
}

const INFORMATION_FIELD_TO_BE_SHOWN = [
  "name",
  "surname",
  "gender",
  "born_at",
  "born_city",
  "born_province",
  "born_country",
  // "citizenship_ue",
  "citizenships",
  "tax_code",
  "italian_residence",
  "citizen_country",
  "citizen_province",
  "citizen_city",
  "citizen_zip",
  "citizen_address",
  "citizen_address_number",
  "phone_country_code",
  "phone",
  "email",
  "domicile_country",
  "domicile_province",
  "domicile_city",
  "domicile_zip",
  "domicile_address",
  "domicile_address_number",
  // "ambassador_code",
  // "invited_by_student_id",
  "privacy_notifications",
  "privacy1",
  "privacy2",
  // "invited_by_student_ambassador_code",
];

const SCHOOL_FIELDS_TO_BE_SHOWN = [
  "course_type",
  "school_id",
  "degree_class_id",
  "school_course_id",
  "number",
];
//<FormattedMessage id="step3.up2.text3" />
const RecapComponent: React.FC<Props> = (props: Props) => {

  const query = useLocation().search;

  const FIELDS_LABEL_MAP = {
    // name: <FormattedMessage id="recap.name" />,
    name: useIntl().formatMessage({ id: "recap.name" }),

    surname: useIntl().formatMessage({ id: "recap.surname" }),
    gender: useIntl().formatMessage({ id: "recap.gender" }),
    born_at: useIntl().formatMessage({ id: "recap.born_at" }),
    born_city: useIntl().formatMessage({ id: "recap.born_city" }),
    born_province: useIntl().formatMessage({ id: "recap.born_province" }),
    born_country: useIntl().formatMessage({ id: "recap.born_country" }),
    citizenships: useIntl().formatMessage({ id: "recap.citizenships" }),
    // citizenship_ue: useIntl().formatMessage({ id: "recap.citizenship_ue" }),
    tax_code: useIntl().formatMessage({ id: "recap.tax_code" }),
    italian_residence: useIntl().formatMessage({ id: "recap.italian_residence" }),
    citizen_country: useIntl().formatMessage({ id: "recap.citizen_country" }),
    citizen_province: useIntl().formatMessage({ id: "recap.citizen_province" }),
    citizen_city: useIntl().formatMessage({ id: "recap.citizen_city" }),
    citizen_zip: useIntl().formatMessage({ id: "recap.citizen_zip" }),
    citizen_address: useIntl().formatMessage({ id: "recap.citizen_address" }),
    citizen_address_number: useIntl().formatMessage({ id: "recap.citizen_address_number" }),
    phone_country_code: useIntl().formatMessage({ id: "recap.phone_country_code" }),
    phone: useIntl().formatMessage({ id: "recap.phone" }),
    email: useIntl().formatMessage({ id: "recap.email" }),
    domicile_country: useIntl().formatMessage({ id: "recap.domicile_country" }),
    domicile_province: useIntl().formatMessage({ id: "recap.domicile_province" }),
    domicile_city: useIntl().formatMessage({ id: "recap.domicile_city" }),
    domicile_zip: useIntl().formatMessage({ id: "recap.domicile_zip" }),
    domicile_address: useIntl().formatMessage({ id: "recap.domicile_address" }),
    domicile_address_number: useIntl().formatMessage({ id: "recap.domicile_address_number" }),

    // School
    course_type: useIntl().formatMessage({ id: "recap.course_type" }),
    school_id: useIntl().formatMessage({ id: "recap.school_id" }),
    degree_class_id: useIntl().formatMessage({ id: "recap.degree_class_id" }),
    school_course_id: useIntl().formatMessage({ id: "recap.school_course_id" }),
    number: useIntl().formatMessage({ id: "recap.number" }),

    // ambassador_code: "Codice ambassador",
    // invited_by_student_id: useIntl().formatMessage({ id: "recap.invited_by_student_id" }),
    privacy_notifications: useIntl().formatMessage({ id: "recap.privacy_notifications" }),
    privacy1: "privacy 1",
    privacy2: "privacy 2",
    ambassador_code: useIntl().formatMessage({ id: "recap.invited_by_student_ambassador_code" }),
  };



  const { studentInfo = {}, studentApplicationInfo = {} } = props;
  const {/* schoolDegrees,  schools,*/courseTypes, citizenships, nations, phoneCodeNations, provinces, /*setMetadata*/ } = React.useContext(
    StudentInfoContext
  );

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [responseMessage, setResponseMessage] = React.useState<{
    type: "error" | "success";
    message: string;
  }>();


  const [schoolName, setSchoolName] = React.useState<string>();
  const [degreeClassName, setDegreeClassName] = React.useState<string>();
  const [schoolCourseName, setSchoolCourseName] = React.useState<string>();
  const studentSchool = studentApplicationInfo?.student_school;
  const documents = studentInfo?.media;
  const proxy = studentApplicationInfo?.media;


  useEffect(() => {
    // const self = this;

    setIsLoading(true);

    if (studentSchool?.["school_id"]) {
      getSchool(studentSchool?.["school_id"])
        .then((res) => {
          if (res?.school) {
            setSchoolName(res?.school.name);
          }
        })

    }

    if (studentSchool?.["degree_class_id"]) {
      getDegreeClass(studentSchool?.["degree_class_id"])
        .then((res) => {
          if (res?.degree_class) {
            setDegreeClassName(res?.degree_class.label);
          }
        })
    }

    if (studentSchool?.["school_course_id"]) {
      getSchoolCourse(studentSchool?.["school_course_id"])
        .then((res) => {
          if (res?.school_course) {
            setSchoolCourseName(res?.school_course.course);
          }
        })
    }

    setIsLoading(false);

  }, [studentSchool]);




  const resolveSchoolFieldValue = (field: string, value: string) => {

    let resolvedValue;
    switch (field) {
      case "degree_class_id":
        resolvedValue = degreeClassName;
        break;
      case "school_course_id":
        resolvedValue = schoolCourseName;
        break;

      case "course_type":
        resolvedValue = resolveValue(
          studentSchool?.["course_type"],
          courseTypes
        );
        if (resolvedValue == null) {
          resolvedValue = studentSchool?.[field];
        }
        break;
      case "school_id":
        resolvedValue = schoolName;
        break;
      case "number":
        resolvedValue = value;
        break;
      default:
        resolvedValue = "-";
        break;

    }
    return resolvedValue || "-";
  };



  const resolveInformationFieldValue = (field: string, value: any) => {

    if (field === "born_at") {
      const date = value ? new Date(value.split(" ")[0]) : "";
      value = date
        ? `${addPrefix(date.getDate())}-${addPrefix(date.getMonth() + 1)}-${date.getFullYear()}`
        : "-";
    } else if (field === "italian_residence") {
      value = (value === 1 || value === '1') ? 'Yes' : 'No';
    } else if (field === "born_country" || field === "citizen_country" || field === "domicile_country") {
      value = resolveValue(
        studentInfo?.[field],
        nations
      );
    } else if (field === "born_province" || field === "citizen_province" || field === "domicile_province") {
      value = resolveValue(
        studentInfo?.[field],
        provinces
      );

      if (value == null) {
        value = studentInfo?.[field];
      }
    } else if (field === "invited_by_student_id") {
      if (value == null) {
        value = <FormattedMessage id="recap.notInvited" />

      } else {
        value = studentInfo?.[field]
      }

    } else if (field === "privacy_notifications") {
      value = (value === 1 || value === '1') ? <FormattedMessage id="personalDataPage.option.yes" /> : <FormattedMessage id="personalDataPage.option.no" />;
    } else if (field === "privacy1") {
      value = (value === 1 || value === '1') ? <FormattedMessage id="personalDataPage.option.yes" /> : <FormattedMessage id="personalDataPage.option.no" />;
    } else if (field === "privacy2") {
      value = (value === 1 || value === '1') ? <FormattedMessage id="personalDataPage.option.yes" /> : <FormattedMessage id="personalDataPage.option.no" />;
    } else if (field === "gender") {
      if (value === 'M') {
        value = <FormattedMessage id="personalDataPage.option.male" />;
      } else if (value === 'F') {
        value = <FormattedMessage id="personalDataPage.option.female" />;
      } else if (value === 'X') {
        value = <FormattedMessage id="personalDataPage.option.other" />;
      }
    }
    return value;
  };

  // const successfullySaved = useIntl().formatMessage({ id: "step4.success" });
  // const onSaveAndContinue = () => {
  //   setIsLoading(true);
  //   upsertStudentInfo(studentInfo).then((res) => {
  //     if (res?.status === "OK") {
  //       setMetadata({ student: res.student });
  //       setResponseMessage({
  //         type: "success",
  //         message: successfullySaved,
  //       });
  //     } else {
  //       const resolvedMessages = Array.isArray(res?.messages)
  //         ? res?.messages
  //         : Object.values(res?.messages || {})
  //           .map((v: any) => v?.[0])
  //           .join(",   ");
  //       setResponseMessage({
  //         type: "error",
  //         message: resolvedMessages,
  //       });
  //     }
  //     setIsLoading(false);
  //   });
  // };

  const onThankYou = () => {
    console.log('registrazione completata');



    navigate({
      pathname: "/thank-you",
      search: query
    });
  };



  const COMMON_ROW_CLASS = "lg:flex md:flex lg:flex-wrap md:flex-wrap pl-4";

  const renderSection = (label: string, value?: string) => {
    return (
      <div key={label} className="lg:w-1/3 md:w-1/2 pr-5 mb-15">
        <div className="text-lg font-500">{label}</div>
        <div>{value || "-"}</div>
      </div>
    );
  };

  return (
    <div className="relative">
      <ConditionalLoader isLoading={isLoading} />
      <div className={getDataSubmitStateClass(isLoading)}>

        <div className="text-4xl font-600 mb-15 mt-20">
          <FormattedMessage id="recap.title" />
        </div>

        <div className="text-2xl">
          <FormattedMessage id="recap.text1" />
        </div><br />
        <div className="text-2xl">
          <FormattedMessage id="recap.text2" />
        </div>
        <div className="text-2xl">
          <FormattedMessage id="recap.text3" />
        </div><br />
        <div className="text-2xl">
          <FormattedMessage id="recap.text4" />
        </div><br />



        <div className="my-10">
          <div className="p-2 text-2xl bg-default habacusColorFont font-600 mb-2">
            <FormattedMessage id="step4.title1" />
          </div>
          <div className={COMMON_ROW_CLASS}>
            {INFORMATION_FIELD_TO_BE_SHOWN.map((field) =>
              renderSection(
                FIELDS_LABEL_MAP[field],
                resolveInformationFieldValue(field, studentInfo[field])
              )
            )}
          </div>
        </div>
        <div className="my-10">
          <div className="p-2 text-2xl bg-default habacusColorFont font-600 mb-2">
            <FormattedMessage id="step4.title2" />
          </div>
          <div className={COMMON_ROW_CLASS}>
            {SCHOOL_FIELDS_TO_BE_SHOWN.map((field) =>
              renderSection(
                FIELDS_LABEL_MAP[field],
                resolveSchoolFieldValue(field, studentSchool?.[field])
              )
            )}
          </div>
        </div>
        <div>
          <div className="p-2 text-2xl bg-default habacusColorFont font-600 mb-2">
            <FormattedMessage id="step4.title3" />
          </div>
          <div className={COMMON_ROW_CLASS}>
            {documents?.map((doc: any) =>
              renderSection(
                DOCUMENTS_OPTIONS_LABEL[doc["collection_name"]],
                doc["file_name"]
              )
            )}
            {proxy?.map((doc: any) =>
              renderSection(
                DOCUMENTS_OPTIONS_LABEL[doc["collection_name"]],
                doc["file_name"]
              )
            )}

          </div>
        </div>
        {responseMessage?.message ? (
          <div
            className={clsx(
              "whitespace-pre text-center text-wrap mt-10 font-600",
              responseMessage?.type === "success"
                ? "text-success"
                : "text-danger"
            )}
          >
            {responseMessage?.message}
          </div>
        ) : null}

        <div className=" mt-30 buttonsGoToTheRight">
          <Button
            label={useIntl().formatMessage({ id: "button.finish" })}
            variant="habacus"
            onClick={() => {
              // onSaveAndContinue();
              setResponseMessage({
                type: "success",
                message: '',
              });

              onThankYou();
            }}
          />
        </div>

      </div>
    </div>
  );
};

export default RecapComponent;
