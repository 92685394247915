import React from "react";

interface Props {
  size?: "sm" | "lg";
  outlined?: boolean;
  disabled?: boolean;
  label: React.ReactNode;
  variant: "primary" | "secondary" | "success" | "danger" | "info" | "habacus" | "yesTest" | "noTest";
  style?: object;
  onClick(): void;
}

const Button: React.FC<Props> = (props: Props) => {
  const { variant, label, disabled, outlined, size, style } = props;

  return (
    <button
      disabled={disabled}
      type="button"
      className={`btn 
        btn-${outlined ? "outline-" : ""}${variant} ${size ? `btn-${size}` : ""
        } `}
      style={{ ...style, borderRadius: "20px" }}
      onClick={props.onClick}
    >
      {label}
    </button>
  );
};

export default Button;
