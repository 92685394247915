import { AMBASSADOR_REFERRAL, CARIPLO_REFERRAL, ORIGIN_TOKEN_PARAM_NAME, POSTE_REFERRAL, REFERRAL, ZOPPAS_REFERRAL } from "app/context/studentInfoProvider";
import EnglishFlag from "app/icons/en.png";
import ItalianFlag from "app/icons/it.png";
import { getToken } from "app/service/apiService";
import { getQueryParam } from "app/utils/utils";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ButtonContinue from "../common/buttonContinue";

interface Props {
  onSubmit(verifyDisclaimer: boolean): void;
  onChangelanguage(lang: string): void;
}

const StudentDisclaimer: React.FC<Props> = (props: Props) => {
  const onSubmit = () => {
    props.onSubmit(true);
  };

  useEffect(() => {
    getToken();
  }, [])

  const intl = useIntl();
  const getLanguageSelectedClass = (lang: string) => {
    if (lang === intl.locale) {
      return "language languageSelected";
    }
    return "language";
  }

  const referralFromUrl = getQueryParam(REFERRAL);
  const tokenOriginfromUrl = getQueryParam(ORIGIN_TOKEN_PARAM_NAME);

  const chooseYesTest = () => {
    console.log('chooseYesTest');
  }
  const chooseNoTest = () => {
    console.log('chooseNoTest');
  }
  // if (referralFromUrl === 'BPI') {

  const renderDisclaimerGeneral = () => {
    if (tokenOriginfromUrl === POSTE_REFERRAL) {
      return <div>
        <div className="text-5xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentDisclaimer.poste.title" />
        </div>
        <div className="text-3xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentDisclaimer.poste.subtitle" />
        </div>
        <div className="text-2xl  mb-15 " style={{ textAlign: 'center' }}>
          <span className={getLanguageSelectedClass('IT')} onClick={() => props.onChangelanguage('IT')}>
            <img alt="flag"
              height={35}
              src={ItalianFlag}
            />
          </span>
          <span> &nbsp;&nbsp;&nbsp; </span>
          <span className={getLanguageSelectedClass('EN')} onClick={() => props.onChangelanguage('EN')}>
            <img alt="flag"
              height={35}
              src={EnglishFlag}
            />
          </span>
        </div>
        <br />
        <div className="text-xl  disclaimer">
          <FormattedMessage id="studentDisclaimer.poste.text1" />
        </div><br />
        <div className="text-xl disclaimer">
          <FormattedMessage id="studentDisclaimer.poste.text2" />
        </div>
        <div className="text-xl disclaimer">
          <FormattedMessage id="studentDisclaimer.poste.text3" />
        </div>
        <br />
        <div className="text-xl disclaimer">
          <b><FormattedMessage id="studentDisclaimer.poste.text4" /></b>
        </div>
        <div className="text-xl  disclaimer">
          <FormattedMessage id="studentDisclaimer.poste.text5" /> <a href="mailto: info@habacus.com"><FormattedMessage id="studentDisclaimer.poste.email" /></a>.
        </div>

      </div>
    } else if (tokenOriginfromUrl === AMBASSADOR_REFERRAL) {
      return <div>
        <div className="text-5xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentDisclaimer.ambassador.title" />
        </div>
        <div className="text-3xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentDisclaimer.ambassador.subtitle" />
        </div>
        <div className="text-2xl  mb-15 " style={{ textAlign: 'center' }}>
          <span className={getLanguageSelectedClass('IT')} onClick={() => props.onChangelanguage('IT')}>
            <img alt="flag"
              height={35}
              src={ItalianFlag}
            />
          </span>
          <span> &nbsp;&nbsp;&nbsp; </span>
          <span className={getLanguageSelectedClass('EN')} onClick={() => props.onChangelanguage('EN')}>
            <img alt="flag"
              height={35}
              src={EnglishFlag}
            />
          </span>
        </div>
        <br />
        <div className="text-xl  disclaimer">
          <FormattedMessage id="studentDisclaimer.ambassador.text1" />
        </div><br />
        <div className="text-xl disclaimer">
          <FormattedMessage id="studentDisclaimer.ambassador.text2" />
        </div>
        <div className="text-xl disclaimer">
          <FormattedMessage id="studentDisclaimer.ambassador.text3" />
        </div><br />
        <div className="text-xl disclaimer">
          <b><FormattedMessage id="studentDisclaimer.ambassador.text4" /></b>
        </div>
        <div className="text-xl  disclaimer">
          <FormattedMessage id="studentDisclaimer.ambassador.text5" /> <a href="mailto: promostudiprestitobp@habacus.com"><FormattedMessage id="studentDisclaimer.ambassador.email" /></a>.
        </div>

      </div>

    } else if (tokenOriginfromUrl === ZOPPAS_REFERRAL) {
      return <div>
        <div className="text-5xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentDisclaimer.zoppas.title" />
        </div>
        <div className="text-3xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentDisclaimer.zoppas.subtitle" />
        </div>
        <div className="text-2xl  mb-15 " style={{ textAlign: 'center' }}>
          <span className={getLanguageSelectedClass('IT')} onClick={() => props.onChangelanguage('IT')}>
            <img alt="flag"
              height={35}
              src={ItalianFlag}
            />
          </span>
          <span> &nbsp;&nbsp;&nbsp; </span>
          <span className={getLanguageSelectedClass('EN')} onClick={() => props.onChangelanguage('EN')}>
            <img alt="flag"
              height={35}
              src={EnglishFlag}
            />
          </span>
        </div>
        <br />
        <div className="text-xl  disclaimer">
          <FormattedMessage id="studentDisclaimer.zoppas.text1" />
        </div><br />
        <div className="text-xl disclaimer">
          <FormattedMessage id="studentDisclaimer.zoppas.text2" />
        </div>
        <div className="text-xl disclaimer">
          <FormattedMessage id="studentDisclaimer.zoppas.text3" />
        </div><br />
        <div className="text-xl disclaimer">
          <b><FormattedMessage id="studentDisclaimer.zoppas.text4" /></b>
        </div>
        <div className="text-xl  disclaimer">
          <FormattedMessage id="studentDisclaimer.zoppas.text5" /> <a href="mailto: info@habacus.com"><FormattedMessage id="studentDisclaimer.zoppas.email" /></a>.
        </div>

      </div>
    } else if (tokenOriginfromUrl === CARIPLO_REFERRAL) {
      return <div>
        <div className="text-5xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentDisclaimer.cariplo.title" />
        </div>
        <div className="text-3xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentDisclaimer.cariplo.subtitle" />
        </div>
        <div className="text-2xl  mb-15 " style={{ textAlign: 'center' }}>
          <span className={getLanguageSelectedClass('IT')} onClick={() => props.onChangelanguage('IT')}>
            <img alt="flag"
              height={35}
              src={ItalianFlag}
            />
          </span>
          <span> &nbsp;&nbsp;&nbsp; </span>
          <span className={getLanguageSelectedClass('EN')} onClick={() => props.onChangelanguage('EN')}>
            <img alt="flag"
              height={35}
              src={EnglishFlag}
            />
          </span>
        </div>
        <br />
        <div className="text-xl  disclaimer">
          <FormattedMessage id="studentDisclaimer.cariplo.text1" />
        </div>
        <div className="text-xl disclaimer">
          <FormattedMessage id="studentDisclaimer.cariplo.text2" />
        </div>
        <div className="text-xl disclaimer">
          <FormattedMessage id="studentDisclaimer.cariplo.text3" />
        </div><br />
        <div className="text-xl disclaimer">
          <b><FormattedMessage id="studentDisclaimer.cariplo.text4" /></b>
        </div>
        <div className="text-xl  disclaimer">
          <FormattedMessage id="studentDisclaimer.cariplo.text5" /> <a href="mailto: progetti@fondazionehabacus.com"><FormattedMessage id="studentDisclaimer.cariplo.email" /></a>
        </div>

      </div>


    }
    else {
      return <div>
        <div className="text-5xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentDisclaimer.title" />
        </div>
        <div className="text-3xl font-600 mb-30" style={{ textAlign: 'center' }}>
          <FormattedMessage id="studentDisclaimer.subtitle" />
        </div>
        <div className="text-2xl  mb-15 " style={{ textAlign: 'center' }}>
          <span className={getLanguageSelectedClass('IT')} onClick={() => props.onChangelanguage('IT')}>
            <img alt="flag"
              height={35}
              src={ItalianFlag}
            />
          </span>
          <span> &nbsp;&nbsp;&nbsp; </span>
          <span className={getLanguageSelectedClass('EN')} onClick={() => props.onChangelanguage('EN')}>
            <img alt="flag"
              height={35}
              src={EnglishFlag}
            />
          </span>
        </div>
        <br />
        <div className="text-xl  disclaimer">
          <FormattedMessage id="studentDisclaimer.text1" />
        </div><br />
        <div className="text-xl disclaimer">
          <FormattedMessage id="studentDisclaimer.text2" />
        </div>
        <div className="text-xl disclaimer">
          <FormattedMessage id="studentDisclaimer.text3" />
        </div><br />
        <div className="text-xl disclaimer">
          <b><FormattedMessage id="studentDisclaimer.text4" /></b>
        </div>
        <div className="text-xl  disclaimer">
          <FormattedMessage id="studentDisclaimer.text5" /> <a href="mailto: info@habacus.com"><FormattedMessage id="studentDisclaimer.email" /></a>.
        </div>


      </div>

    }
  }

  return (<div>
    {renderDisclaimerGeneral()}
    <div className="mt-30 flex justify-end">
      {/* <Button label={useIntl().formatMessage({ id: "button.continue" })} variant="habacus" onClick={onSubmit} /> */}
      {/* <ButtonIcon variant="flexButtSave" onClick={onSubmit} label1={useIntl().formatMessage({ id: "button.saveAndGoOn1" })} label2={useIntl().formatMessage({ id: "button.saveAndGoOn2" })} /> */}
      <ButtonContinue variant="flexButtContinue" onClick={onSubmit} label={useIntl().formatMessage({ id: "button.goOn" })} />
    </div>

  </div>

  );


};

export default StudentDisclaimer;
