import Button from "app/components/common/button";
import ButtonContinue from "app/components/common/buttonContinue";
import ConfirmNoTestModal from "app/components/common/confirmNoTestModal";
import ConfirmTestDoneModal from "app/components/common/ConfirmTestDoneModal";
import ConfirmYesTestModal from "app/components/common/confirmYesTestModal";
import UploadPdfResultModal from "app/components/common/uploadPdfResultModal";
import { ORIGIN_TOKEN_PARAM_NAME } from "app/context/studentInfoProvider";
import { getChoiceCourse, getEndCourse, getStartCourse } from "app/service/apiService";
import { getQueryParam } from "app/utils/utils";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
    studentInfo: any;
    studentApplicationInfo: any;
    onStudentInfoChange(data: object): void;
    onStudentApplicationInfoChange(data: object): void;
    onAccessRequirementInfoChange(data: object): void;
    onSaveAndContinue(): void;
}

// http://localhost:3000/user-information?token_or=BPI&referral=BPI&token=5e2d05d1-5f80-415e-8113-f613feb0c56d&token_p=2b7555ad-cdfa-49ca-b22d-9ce3cfb67791
// TEST221012

const OrientationPage: React.FC<Props> = (props: Props) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isTestVisible, setIsTestVisible] = React.useState<boolean>(false);

    const ciao = false;
    // se lo studente non ha iniziato ne finito
    console.log(props.studentInfo);
    console.log(props.studentInfo.events.filter((el: any) => {
        return el.category === 'POSTE_ITALIANE' && el.action === 'CHOICE' && el.label === 'PEARSON_COURSE';
    }));


    useEffect(() => {
        let choice = props.studentInfo?.events.filter((el: any) => {
            return el.category === 'POSTE_ITALIANE' && el.action === 'CHOICE' && el.label === 'PEARSON_COURSE';
        });
        if (choice.length > 0) {
            if (choice[0].value === 'POSTPONED') {
                props.onSaveAndContinue();
            } else if (choice[0].value === 'NOW' && props.studentInfo?.events.filter((el: any) => {
                return el.category === 'POSTE_ITALIANE' && el.action === 'END' && el.label === 'PEARSON_COURSE';
            }).length > 0) {
                props.onSaveAndContinue();
            }
            setIsTestVisible(true);
        }
    }, [props.studentInfo]);

    //

    const [isTestChoiceVisible, setIsTestChoiceVisible] = React.useState<boolean>(props.studentInfo.events.filter((el: any) => {
        return el.category === 'POSTE_ITALIANE' && el.action === 'CHOICE' && el.label === 'PEARSON_COURSE';
    }).length === 0);
    const [isConfirmYesTestModalVisible, setIsConfirmYesTestModalVisible] = React.useState<boolean>(false);
    const [isConfirmNoTestModalVisible, setIsConfirmNoTestModalVisible] = React.useState<boolean>(false);
    const [isConfirmTestDoneModalVisible, setIsConfirmTestDoneModalVisible] = React.useState<boolean>(false);
    const [isSaveAndContinueVisible, setIsSaveAndContinueVisible] = React.useState<boolean>(false);
    const [isUploadPdfResultModalOpen, setIsUploadPdfResultModalOpen] = React.useState<boolean>(false);

    const [isIHaveChoosenNoTestVisible, setIsIHaveChoosenNoTestVisible] = React.useState<boolean>(false);

    const tokenOriginfromUrl = getQueryParam(ORIGIN_TOKEN_PARAM_NAME);

    const chooseYesTest = () => {
        // <a href="https://www.google.com" target={'_blank'} rel="noreferrer">vai a test motiva</a>
        // window.open('https://www.google.com', '_blank');

        setIsConfirmYesTestModalVisible(true);




        // setIsTestVisible(true);
        console.log('chooseYesTest');
    }
    const chooseNoTest = () => {
        // setIsTestVisible(false);
        setIsConfirmNoTestModalVisible(true);
        // setIsSaveAndContinueVisible(true);
        console.log('chooseNoTest');
    }

    const confirmContinue = () => {
        setIsConfirmTestDoneModalVisible(true);
    }

    const renderOrientationTestChoice = () => {
        // style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
        return <div className="mt-5 text-center" >
            <div>
                <div className="text-2xl mb-30">
                    <FormattedMessage id="orientation.bpi.text1a" />
                </div>
                <div className="text-2xl mb-30">
                    <FormattedMessage id="orientation.bpi.text1b" />
                </div>
                {/* <div className="text-2xl mb-30">
                    <FormattedMessage id="orientation.bpi.text1c" />
                </div> */}
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button label={<FormattedMessage id="button.noTest" />} variant={"noTest"} onClick={chooseNoTest} style={{ width: "25%", height: '40px' }}></Button>
                &nbsp;
                <Button label={<FormattedMessage id="button.yesTest" />} variant={"yesTest"} onClick={chooseYesTest} style={{ width: "25%", height: '40px' }}></Button>
            </div>
        </div>
    }

    const openTestMotiva = async () => {

        const response = await getStartCourse(props.studentInfo.uuid, 'POSTE_ITALIANE', '_blank');

        const html = response.result.html;
        document.getElementById("hiddenForm")?.remove();
        const newDiv = document.createElement("div");
        newDiv.setAttribute("id", "hiddenForm");
        newDiv.innerHTML = html;
        document.getElementById("ltiDiv")?.appendChild(newDiv);

        const element: HTMLElement = newDiv.querySelector("input[type=submit]") as HTMLElement;

        element?.click();

    }


    const renderContentWhileTestPageIsOpen = () => {
        return <div className="text-center">
            <div className="text-2xl mb-30 mt-30">
                <FormattedMessage id="orientation.bpi.testVisible.text1" />
                <br /> <br />
                <FormattedMessage id="orientation.bpi.testVisible.text2" />
            </div>
            <div className="text-2xl" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <h2 onClick={openTestMotiva} className="testVisibleLink"> <FormattedMessage id="orientation.bpi.testVisible.link" /></h2>
                <ButtonContinue variant="flexButtContinue" onClick={confirmContinue} label={<FormattedMessage id="button.goOn" />} />
            </div>
            {/* <Button
                disabled={isLoading}
                label={uploadTestPDF}
                variant="habacus"
                onClick={uploadPDFtestFile}
            /> */}
            {/* <Button
                disabled={isLoading}
                label={saveAndContinue}
                variant="habacus"
                onClick={onSaveAndContinueClick}
            /> */}
            {/* <Button
                disabled={isLoading}
                label={saveAndContinue}
                variant="habacus"
                onClick={confirmContinue}
            /> */}


        </div>
    }

    const renderIHaveChoosenNoTestVisible = () => {
        return <div>
            hai scelto di non fare il test. prosegui con l'onboarding ti arriverà una mail
        </div>
    }

    const onSaveAndContinueClick = async () => {
        console.log('test');

        setIsLoading(true);
        props.onSaveAndContinue();
    };

    const uploadPDFtestFile = async () => {
        setIsUploadPdfResultModalOpen(true);

    }

    const saveAndContinue = useIntl().formatMessage({ id: "button.save&continue" });
    const uploadTestPDF = 'Carica il file PDF di esito quando finisci il test';

    const handleClose = () => {
        onSaveAndContinueClick();

    };
    const handleCloseGoBackToTest = () => {

        setIsUploadPdfResultModalOpen(false);
    };


    const handleCloseNoTestModalAndContinue = async () => {


        await getChoiceCourse(props.studentInfo.uuid, 'POSTE_ITALIANE', 'POSTPONED');



        setIsConfirmNoTestModalVisible(false);
        setIsTestChoiceVisible(false);
        setIsIHaveChoosenNoTestVisible(true);
        onSaveAndContinueClick();
    };
    const handleCloseNoTestModal = () => {
        setIsConfirmNoTestModalVisible(false);

    };



    const handleCloseDoneTestModalAndContinue = async () => {


        await getEndCourse(props.studentInfo.uuid, 'POSTE_ITALIANE');

        setIsConfirmTestDoneModalVisible(false);
        // setIsTestChoiceVisible(false);
        // setIsIHaveChoosenNoTestVisible(true);
        onSaveAndContinueClick();
    };
    const handleCloseDoneTestModal = () => {
        setIsConfirmTestDoneModalVisible(false);

    };

    const handleCloseYesTestModalAndContinue = async () => {


        await getChoiceCourse(props.studentInfo.uuid, 'POSTE_ITALIANE', 'NOW');


        console.log('handleCloseYesTestModalAndContinue');
        setIsConfirmYesTestModalVisible(false);
        // faccio apparire un testo + link per recuperare il test + bottone per caricare


        // setIsTestRunning(true);
        setIsTestVisible(true);
        setIsTestChoiceVisible(false);
    };
    const handleCloseYesTestModal = () => {
        console.log('handleCloseYesTestModal');
        setIsConfirmYesTestModalVisible(false);
    };

    return (

        <div className="relative">

            <ConfirmYesTestModal
                isConfirmYesTestVisible={isConfirmYesTestModalVisible}
                handleCloseYesTestModalAndContinue={handleCloseYesTestModalAndContinue}
                handleCloseYesTestModal={handleCloseYesTestModal}
            ></ConfirmYesTestModal>

            <ConfirmNoTestModal
                isConfirmNoTestVisible={isConfirmNoTestModalVisible}
                handleCloseNoTestModalAndContinue={handleCloseNoTestModalAndContinue}
                handleCloseNoTestModal={handleCloseNoTestModal}
            ></ConfirmNoTestModal>

            <ConfirmTestDoneModal
                isConfirmTestDoneVisible={isConfirmTestDoneModalVisible}
                handleCloseDoneTestModalAndContinue={handleCloseDoneTestModalAndContinue}
                handleCloseDoneTestModal={handleCloseDoneTestModal}
            ></ConfirmTestDoneModal>

            <UploadPdfResultModal
                isUploadPdfResultOpen={isUploadPdfResultModalOpen}
                handleClose={handleClose}
                handleCloseGoBackToTest={handleCloseGoBackToTest}
            ></UploadPdfResultModal>


            <div className=" text-center">
                <h1><FormattedMessage id="orientation.bpi.title" /></h1>

                {/* settando true istestVisible vuol dire che ho scelto di fare il test motiva, che mi si apre in un'altra scheda e mi appare il bottone per aprire il modale di upload test*/}
                {/* al momento disattivato perchè l'ho messo nel testo in basso */}
                {/* {isTestVisible &&
                    <div className="">
                        <Button
                            disabled={isLoading}
                            label={uploadTestPDF}
                            variant="habacus"
                            onClick={uploadPDFtestFile}
                        />
                    </div>
                } */}
            </div>


            {/* isTestVisible è in false (  di default ) quindi mostro un testo generico che mi spiega la scelta di fare o non fare il test subito e i due bottoni per scegliere cosa fare */}
            {isTestChoiceVisible && renderOrientationTestChoice()}

            {/* isTestVisible è in true quindi mostro un testo  che mi spiega, mentre ho in unaltra pagina il test, che quando ho finito devo caricare il pdf per proseguire */}
            {isTestVisible && renderContentWhileTestPageIsOpen()}

            {/* {
                isSaveAndContinueVisible
                &&
                <div className="mt-30 buttonsGoToTheRight">
                    <Button
                        disabled={isLoading}
                        label={saveAndContinue}
                        variant="habacus"
                        onClick={onSaveAndContinueClick}
                    />
                </div>
            } */}


            {isIHaveChoosenNoTestVisible && renderIHaveChoosenNoTestVisible()}

            <div className="form" id="ltiDiv" style={{ display: 'none' }}></div>
        </div >
    );
};

export default OrientationPage;
